import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Helper } from 'src/app/classes/utility';
import { Language } from 'src/app/classes/utility/language';
import { LanguageService } from 'src/app/services/language.service';
import { ProductComponent } from 'src/app/pages/product/product.component';
import { OfflineCCFExport, CopyTo } from 'src/app/pages/product/offline-ccf-export';

@Component({
  selector: 'app-export-ccf-options',
  templateUrl: './export-ccf-options.component.html',
  styleUrls: ['./export-ccf-options.component.css']
})
export class ExportCCFOptionsComponent implements OnInit {
  currentLanguage = '';
  copyToOptions = [CopyTo.COPY_TO_USER,CopyTo.COPY_TO_FACTORY,CopyTo.COPY_TO_USER_AND_FACTORY];
  selectedCopyToOption = CopyTo.COPY_TO_USER;
  secureUser = Helper.secureModeEnabled;
  productComponent: ProductComponent = null;
  @ViewChild('inputCustomerName') inputCustomerName:ElementRef;
  @ViewChild('inputConfigName') inputConfigName:ElementRef;

  constructor(private languageService: LanguageService, private translate: TranslateService) { }

  ngOnInit(): void {
    window.exportCCFOptionsComponent = this;
  }

  show(productComponent: ProductComponent) {
    const element: HTMLElement = document.getElementById('exportCCFOptionsButton');
    element.click();
    this.productComponent =  productComponent;
  }

  confirm(): void {
    let customerName = this.inputCustomerName.nativeElement.value;
    let configName = this.inputConfigName.nativeElement.value;
    let sCCF: string = new OfflineCCFExport(this.productComponent).getCCF(customerName,configName,this.selectedCopyToOption);
    this.productComponent.data.currentConfigFile = sCCF;
    this.productComponent.lastGeneratedCCFName = configName;
    const closeElement: HTMLElement = document.getElementById('closeExportCCFOptionsButton');
    closeElement.click();
  }

  selectOptionValue(option: CopyTo) {
    this.selectedCopyToOption = option;
  }
}
