<section id="toolbar" class="row">
  <div class="col-12 col-xl-5 text-left">
    <div class="row">
      <div class="col-auto">
        <img
          src="assets/images/fake/Component16.png"
          class="img-fluid float-xl-left d-xl-inline mr-3"
        />
      </div>
      <div class="col-7">
        <h2 class="d-inline">Gryphon-BT4100</h2>
        <h4 class="d-inline ml-3 mr-2">Release</h4>
        <div class="d-inline-block">
          <select class="form-control">
            <option>610018804</option>
            <option>610018956</option>
          </select>
        </div>
        <h4>Connected device [COM1 ]</h4>
        <a target="_blank" href="#">Go to product Website</a>
      </div>
    </div>
  </div>
  <div class="col-12 col-xl-7 float-right text-right my-3 my-xl-0">
    <div class="row">
      <div class="col">
        <a role="button" class="btn btn-outline-warning btn-sm"
          >Barcode <img src="assets/images/barcode.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm"
          >Datamatrix <img src="assets/images/data-matrix.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm"
          >Generate Doc <img src="assets/images/gen-doc.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm" id="btnToolbarSaveConf"
          >Save Configuration
          <img src="assets/images/save-config.svg" class="ml-2"
        /></a>
        <div class="w-100 d-none d-xl-block"></div>
        <a role="button" class="btn btn-outline-warning btn-sm" id="btnToolbarReadConf"
          >Read Configuration
          <img src="assets/images/read-config.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm"
          >Disconnect Device
          <img src="assets/images/disconnect.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm" id="btnToolbarViewImage"
          >View Image <img src="assets/images/view-image.svg" class="ml-2"
        /></a>
        <a role="button" class="btn btn-outline-warning btn-sm"
          >Service Mode <img src="assets/images/service-mode.svg" class="ml-2"
        /></a>
      </div>
      <div class="col-auto">
        <div class="card">
          <div class="card-body text-center">
            <div class="card-title">Custom Manual</div>
            <a role="button" class="btn btn-warning btn-sm">Enable</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
