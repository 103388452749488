import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  static setItem(name: string, obj: any): void {
    localStorage.setItem(name, JSON.stringify(obj));
  }

  static getItem(name: string): any {
    return JSON.parse(localStorage.getItem(name));
  }

  static removeItem(name: string): void {
    localStorage.removeItem(name);
  }
}
