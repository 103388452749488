<div id="detectedDevices" class="card border-0 h-100 sidebar-container">
  <div class="card-header blue">
    {{ (this.sidebarType !== "AzureMode")? ('SIDEBAR.DETECTED-DEVICES' | translate)  : ('SIDEBAR.MY-DEVICES' | translate)}}

    <!-- DETECT SETTINGS -->
    <div class="detect_setting_icon">
      <a role="button" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.DETECTION-SETTINGS' | translate" data-toggle="tooltip" data-placement="top">
        <img class="rotate_icon" src="assets/images/detect-setting.svg"/>
      </a>

      <fieldset class="dropdown-content position-dropdown-content detect_setting_container" id="">
        <div class="detect_setting_header d-flex justify-content-end">{{"SIDEBAR.DETECTION-SETTINGS" | translate}}</div>
        <div class="detect_setting_content">
          <!-- SETTING 1 -->
          <div class="detect_setting_item">
            <div class="setting_item_title">{{"SIDEBAR.DETECTION-MODE" | translate}}</div>
            <div class="setting_item_content">
              <form>
                <label class="mode_label d-flex" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.DETECTION-MODE-ALL-TOOLTIP' | translate"> 
                  <input type="radio" name="radio" value="all" (change)="onDetectionModeChange($event.target.value)" [checked]="detectionMode == 'all'"/>
                  <span>{{"SIDEBAR.DETECTION-MODE-ALL" | translate}}</span>
                </label>
                <label class="mode_label d-flex" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.DETECTION-MODE-DEFAULT-TOOLTIP' | translate"> 
                  <input type="radio" name="radio" value="default" (change)="onDetectionModeChange($event.target.value)" [checked]="detectionMode == 'default'"/>
                  <span>{{"SIDEBAR.DETECTION-MODE-DEFAULT" | translate}}</span>
                </label>
              </form>
            </div>
          </div>

        </div>
      </fieldset>
    </div>

    <!-- START/STOP -->
    <div class="card-header-icons">
      <a role="button" data-toggle="tooltip" data-placement="top" [title]="((this.sidebarType !== 'AzureMode')? 'SIDEBAR.SEARCH-TOOLTIP': 'SIDEBAR.LOAD-TOOLTIP') | translate" [hidden]="!checkStatus('Closed')">
        <img class="max-size-icon" src="assets/images/play-detect-device.png" (click)="onStart()"  (mouseover)="getRemoteListPort()"/>
      </a>
      <a role="button" [title]="'SIDEBAR.STOP-TOOLTIP' | translate" [hidden]="checkStatus('Closed')"
        data-toggle="tooltip" data-placement="top"
      >
      <!-- <a role="button" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.STOP-TOOLTIP' | translate" [hidden]="(this.stateDectectDevice == 'Closed' && sidebarType!=='AzureMode') || (stateLoadListDeviceInAzure=='Closed' && sidebarType =='AzureMode')"> -->
        <img class="max-size-icon" src="assets/images/stop-detect-device.png" (click)="onStop()"/>
      </a>
      <fieldset class="dropdown-content position-dropdown-content" id="selectSearchDeviceOptions" [hidden]="this.sidebarType == 'AzureMode'">
        <a role="button" class="content-item" *ngFor="let item of this.formList.controls;let idx = index;" [ngClass]="{'disabled-dropdown-content': stateDectectDevice != 'Closed'}">
            <ng-container [formGroup]="item">
            <input [id]="item.get('value').value" type="checkbox" [value]="item.value" formControlName="check"/>
            <label (click)="selectPorts(item.get('check').value, idx)" [htmlFor]="item.get('value').value" [ngClass]="{'label-disabled': stateDectectDevice != 'Closed'}">
              {{ !(item.get('value').value == "SELECT_ALL_SEARCH_DEVICE_OPTIONS")? item.get('value').value : "SIDEBAR.SELECT-ALL-SEARCH-DEVICE-OPTIONS" | translate}}
            </label>
          </ng-container>
        </a>
      </fieldset>
    </div>
  </div>
  <div class="progress" [hidden]="checkStatus('Closed')">
    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%" [ngClass]="checkStatus('Loading')? 'starting-color' : 'stopping-color'"></div>
  </div>
  <div class="card-body">
    <ul class="list-group list-group-flush">
      <li *ngFor="let listRemoteDevice of getListDevice(); let i = index;" class="list-group-item" role="button" (click)="openRemoteDevice(i)">
        <div class="float-xl-left d-xl-inline mr-3">
          <img class="img-fluid" src="{{listRemoteDevice[2]}}" onerror="this.onerror=null;this.src='assets/images/unknown_device.png';"/>
        </div>
        <h6>{{listRemoteDevice[0]}}</h6>
        <p class="mb-0" *ngIf="this.sidebarType !== 'AzureMode'"><small [innerHTML]="listRemoteDevice[1]"></small></p>
        <p class="mb-0" *ngIf="this.sidebarType === 'AzureMode'"><small [innerHTML]=" 'ID: ' + listRemoteDevice[1]"></small></p>
        <p class="mb-0" *ngIf="this.sidebarType === 'AzureMode' && listRemoteDevice.length > 4"><small [innerHTML]="'Model: ' + listRemoteDevice[4]"></small></p>
        <p class="mb-0" *ngIf="this.sidebarType === 'AzureMode' && listRemoteDevice.length > 5"><small [innerHTML]="'Version: ' + listRemoteDevice[5]"></small></p>
      </li>
    </ul>
  </div>
  <div class="card-footer text-center bg-white">
    <a *ngIf="standaloneMode && this.sidebarType == 'StandaloneMode'" class="btn btn-block btn-warning align-self-center" role="button" id="btnSidebarAutoDetection" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.DEVICE-AUTODETECTION-TOOLTIP' | translate">
      <img src="assets/images/barcode-scanner.svg" class="mr-2" /> {{ 'SIDEBAR.DEVICE-AUTODETECTION' | translate }}</a>
    <a *ngIf="standaloneMode && this.sidebarType == 'StandaloneMode'" class="btn btn-block btn-outline-warning align-self-center" role="button" id="btnSidebarFwUpgrade" data-toggle="tooltip" data-placement="right" [title]="'SIDEBAR.UPDATE-FIRMWARE-TOOLTIP' | translate">
      <img src="assets/images/firmware.svg" class="mr-2" /> {{ 'SIDEBAR.UPDATE-FIRMWARE' | translate }}</a>
  </div>
</div>

<app-alternative-products-modal #loadingAlternativeProductsModal></app-alternative-products-modal>
<app-notification #sidebarNotification [identifier]="'sidebarNotification'"></app-notification> 