<header>
  <nav class="navbar navbar-expand-lg navbar-light" id="nav_container">
    <!-- LOGO -->
    <a class="navbar-brand ml-5" routerLink="/">
      <img
        src="assets/images/logo.png" class="img-fluid"  alt="Datalogic Aladdin" />
    </a>
    <button class="navbar-toggler" type="button"  data-toggle="collapse" data-target="#topNav"
      aria-controls="topNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- NAV -->
    <div class="collapse navbar-collapse row" id="topNav">
      <ul id="topNavCentered" class="navbar-nav flex-lg-row mr-auto ml-auto col-4 col-lg-10 justify-content-center">
        
        <li class="nav-item header_card"  data-toggle="tooltip" data-placement="bottom" [title]="'HEADER.HOME-TOOLTIP' | translate" data-animation="true" 
        data-delay="{'show': 500, 'hide': 100 }">
          <a class="nav-link" routerLink="/"><img class="header_icon" src="assets/images/header/header_home.png" id="home"></a>
          <div class="header_tag_name">{{'HEADER.HOME' | translate}}</div>
        </li>

        <li *ngIf="standaloneMode" class="nav-item header_card" data-toggle="tooltip" data-placement="bottom" id="btnHeaderAutoDetection" [title]="'SIDEBAR.DEVICE-AUTODETECTION-TOOLTIP' | translate">
          <a class="nav-link" routerLink="/"><img class="header_icon" src="assets/images/header/header_detect.png" id="detect"></a>
          <div class="header_tag_name">{{'SIDEBAR.DEVICE-AUTODETECTION' | translate}}</div>
        </li>
        
        <li *ngIf="standaloneMode"  class="nav-item header_card" data-toggle="tooltip" data-placement="bottom" id="btnHeaderUpgradeFirmware" [title]="'SIDEBAR.UPDATE-FIRMWARE-TOOLTIP' | translate">
          <a class="nav-link"><img class="header_icon" src="assets/images/header/header_chip.png" id="upgrade"></a>
          <div class="header_tag_name">{{'HEADER.FIRMWARE-UPGRADE' | translate}}</div>
        </li>
        
        
        <!-- <li class="nav-item" data-toggle="tooltip" data-placement="bottom" [title]="'HEADER.HELP-TOOLTIP' | translate">
          <a class="nav-link" [href]="helpLink" target="_blank">{{'HEADER.HELP' | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="enableCloudService && !loginDisplayB2c" data-placement="bottom">
          <a class="nav-link" target="_blank" (click)="loginB2c()">{{'HEADER.SIGN-IN' | translate}}</a>
        </li> -->
        
        <!-- <li class="nav-item" *ngIf="enableCloudService && loginDisplayB2c" data-placement="bottom">
          <div class="dropdown">
            <a class="dropbtn nav-link" target="_blank">{{nameUserB2c}}</a>
            <div class="dropdown-content">
              <a (click)="editProfileB2c()">{{'HEADER.EDIT-PROFILE' | translate}}</a>
              <a (click)="openCloseCloudMode()">{{(this.azureMode? 'HEADER.DISABLE-DEVICE-CLOUD':'HEADER.ENABLE-DEVICE-CLOUD') | translate}}</a>
              <a (click)="logoutB2c()">{{'HEADER.SIGN-OUT' | translate}}</a>
            </div>
          </div>
        </li> -->

        <!--<li class="nav-item" *ngIf="loginDisplayB2c" data-placement="bottom">
          <a class="nav-link" target="_blank" (click)="editProfileB2c()">{{'HEADER.EDIT-PROFILE' | translate}}</a>
        </li>
        <li class="nav-item" *ngIf="loginDisplayB2c" data-placement="bottom">
          <a class="nav-link" target="_blank" (click)="logoutB2c()">{{'HEADER.SIGN-OUT' | translate}}</a>
        </li>-->
      </ul>
      
      <ul id="topNavRight" class="navbar-nav flex-lg-row justify-content-end col-6 col-lg-2 justify-content-md-end">
        <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/"><img src="assets/images/compare.svg" alt="Compare Configuration"/></a>
        </li> -->
        <li class="nav-item" data-toggle="tooltip" data-placement="top" [title]="'HEADER.OPEN-GENERIC-XML-PACKAGE' | translate">
          <a class="nav-link" (click)="loadFile()"><img src="assets/images/open.svg" alt="Open Configuration File"/></a>
        </li>
        <li class="nav-item" data-toggle="tooltip" data-placement="top" [title]="'HEADER.HELP-TOOLTIP' | translate">
          <a class="nav-link" [href]="helpLink" target="_blank"><img src="assets/images/help_icon.svg" alt="Help"/></a>
        </li>
        <li class="nav-item dropdown show" data-toggle="tooltip" data-placement="top" [title]="'HEADER.TOOLS-SETTINGS' | translate">
          <a class="nav-link dropdown-toggle"  id="SettingsMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src="assets/images/settings.svg" alt="Settings"/>
          </a>
          <div class="dropdown-menu" aria-labelledby="SettingsMenuButton">
            <a class="dropdown-item" *ngIf="!standaloneMode" href="./assets/Aladdin_Setup.zip#">{{'HEADER.DOWNLOAD-STANDALONE' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" id="mniHeaderForceDeviceConnection">{{'HEADER.FORCE-DEVICE' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" style="display: none;">{{'PRODUCT.TERMINAL' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" style="display: none;">{{'HEADER.COMPARE-CONFIG' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" id="mniHeaderMacro">{{'HEADER.MACRO' | translate}}</a>
            <a class="dropdown-item" href="#" (click)="openLanguageSelection()">{{'HEADER.LANG-SELECTION' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" id="mniHeaderSecureUser">{{'HEADER.SECURE-USER' | translate}}</a>
            <a class="dropdown-item" *ngIf="standaloneMode" id="mniHeaderCheckinUpdateNow">{{'HEADER.CHECKING-UPDATE' | translate}}</a>
            <a class="dropdown-item" href="#" id="mniHeaderSendFeedback" (click)="openSendFeedback()">{{'HEADER.SEND-FEEDBACK' | translate}}</a>
            <hr class="separator" *ngIf="standaloneMode" />
            <form>
              <div class="zoom mb-2" *ngIf="standaloneMode">
                <h5 class="menu-title">Zoom</h5>
                <div>
                  <div class="btn btn-outline-warning btn-sm m-0 mr-2" id="zoomLess">-</div>
                  <div id="zoomValue">100%</div>
                  <div class="btn btn-outline-warning btn-sm m-0 ml-2" id="zoomMore">+</div>
                </div>
              </div>
            </form>
            <hr class="separator" *ngIf="standaloneMode" />
            <form>
              <div id="divShowParameterCode" class="header-toggle custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchShowParameterCode" 
                [checked]="getShowParameterCode()" (change)="toggleShowParameterCode()">
                <label class="custom-control-label" for="customSwitchShowParameterCode">{{'HEADER.SHOW-CODES' | translate}}</label>
              </div>
            </form>
            <form>
              <div id="divAutoUpdate" class="header-toggle custom-control custom-switch" *ngIf="standaloneMode">
                <input type="checkbox" class="custom-control-input" id="customSwitchAutoUpdate" checked>
                <label class="custom-control-label" for="customSwitchAutoUpdate">{{'HEADER.AUTOMATIC-UPDATES' | translate}}</label>
              </div>
            </form>
            <form>
              <div id="divAutoDetectDevice" class="header-toggle custom-control custom-switch" *ngIf="standaloneMode">
                <input type="checkbox" class="custom-control-input" id="customSwitchAutoDetectDevice">
                <label class="custom-control-label" for="customSwitchAutoDetectDevice">{{'HEADER.AUTOMATIC-DETECT-DEVICE' | translate}}</label>
              </div>
            </form>
            <hr class="separator" *ngIf="standaloneMode"/>
            <form>
              <div *ngIf="standaloneMode">
                  <div class="text-center">
                      <div>
                          <span class="span-remote-font-14">Remote ID: </span><span id="spanRemoteAppId" class="span-remote-font-14">{{remoteAppId}}</span>
                      </div>
                      <div>
                        <div role="button" class="btn btn-warning btn-sm span-remote-margin" id="btnCopyRemoteId" data-toggle="tooltip" data-placement="bottom"
                          title="Copy Remote ID" (click)="btnCopyRemoteIdListener()">Copy</div>
                        <div role="button" class="btn btn-warning btn-sm span-remote-margin" id="btnAllowRemoteConfiguration" data-toggle="tooltip" data-placement="bottom"
                          title="Allow Remote Configuration" (click)="btnAllowRemoteConfigurationListener()">{{allowRemoteConfigButtonText}}</div>
                      </div>
                  </div>
              </div>
            </form>
            <hr class="separator" *ngIf="!standaloneMode"/>
            <div *ngIf="!standaloneMode">
                <div class="dropdown-item-remote">
                    <div>
                        <span class="span-remote-font-14">Remote ID: <input id="callToUsernameInput" class="span-remote-font-14" type="text" value="" #callToUsernameInputValue/></span>
                        <a role="button" class="btn btn-warning btn-sm span-remote-margin" id="btnStartRemoteConfiguration" data-toggle="tooltip" data-placement="bottom"
                            title="Remote Configuration" (click)="btnStartRemoteConfigurationListener(callToUsernameInputValue.value)">{{startRemoteConfigButtonText}}</a>
                    </div>
                </div>
            </div>
          </div>
        </li>
        <li class="nav-item" data-toggle="tooltip" *ngIf="enableCloudService && !loginDisplayB2c" data-placement="top" [title]="'HEADER.SIGN-IN' | translate">
          <a class="nav-link" target="_blank" (click)="loginB2c()"><img src="assets/images/user.svg" alt="Settings"/></a>
        </li>
        <li class="nav-item dropdown show d-flex align-items-center justify-content-center" *ngIf="enableCloudService && loginDisplayB2c" data-toggle="tooltip" data-placement="top" [title]="nameUserB2c || 'Unknown name'">
          <a class="nav-link dropdown-toggle p-0"  id="UserMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img [src]="nameUserB2c? getAvatarLink() : 'assets/images/user.svg'" alt="Settings"/>
          </a>
          <div class="dropdown-menu" aria-labelledby="UserMenuButton">
            <a class="dropdown-item" (click)="editProfileB2c()">{{'HEADER.EDIT-PROFILE' | translate}}</a>
            <a class="dropdown-item" (click)="openCloseCloudMode()">{{(this.azureMode? 'HEADER.DISABLE-DEVICE-CLOUD':'HEADER.ENABLE-DEVICE-CLOUD') | translate}}</a>
            <a class="dropdown-item" (click)="logoutB2c()">{{'HEADER.SIGN-OUT' | translate}}</a>
          </div>
        </li>
      </ul>
      <span id="version-spam">V 3.3.0.3</span>
    </div>
  </nav>
</header>
<div class="tab-bar">
  <ul id="prod-tabs" class="nav nav-tabs">
    <li class="nav-item" *ngIf="!isInHome">
      <a class="nav-link" id="tab0" routerLink="/" aria-selected="false" (click)="selectHomeTab()">
        {{'HEADER.OPEN-DEVICES' | translate}} <i class="fa fa-angle-right ml-3"></i></a>
    </li>
    <li
      class="nav-item"
      *ngFor="let item of tabs">
        <a class="nav-link" [routerLink]="item.url" routerLinkActive="active" aria-selected="false" (click)="selectTab(item)">
          {{item.name}}{{item.mode}}
        </a>
      <div class="close" (click)="closeTab(item)"></div>
    </li>
  </ul>
</div>
<input #fileInput type="file" onclick="this.value = null" [accept]="acceptedFiles" (change)="loadedFile($event)"
       class="hidden" />
<app-language-selection #languageSelection></app-language-selection>
<app-send-feedback #sendFeedback></app-send-feedback>
<app-notification #headerNotification [identifier]="'headerNotification'"></app-notification>