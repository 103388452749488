export class DevicePropertyModel {
    constructor(
        public id: string ="",
        public displayName: string ="",
        public serialNumber: string = "",
        public topModelNumber: string = "",
        public configurationFileID: string = "",
        public applicationROM: string = ""
    ) {
    }
}