import { Component, Input, ViewChild, OnChanges, ElementRef, NgZone } from '@angular/core';
import { Command, Helper } from 'src/app/classes/utility';
import { PdfContentComponent } from '../pdf-content/pdf-content.component';
import * as jsPDF from 'jspdf';
const navigator = window.navigator as any;

@Component({
  selector: 'app-pdf-preview-modal',
  templateUrl: './pdf-preview-modal.component.html',
  styleUrls: ['./pdf-preview-modal.component.css']
})
export class PdfPreviewModalComponent implements OnChanges {
  @ViewChild('pdfContent') pdfContent: PdfContentComponent;
  @ViewChild('shareButton') shareButton: ElementRef<HTMLButtonElement>;
  @Input() preCommands: Command[] = [];
  @Input() postCommands: Command[] = [];
  @Input() commands: Command[] = [];
  @Input() interfaceCommand: Command = null;
  @Input() productName = '';
  @Input() family = '';
  @Input() currentUle = '';
  @Input() preCmd: string = 'C';
  @Input() enterConfigurationCmd: string = 'P';
  @Input() exitConfigurationCmd: string = 'P';
  step = 1;
  type = '';
  concatenate = true;
  showConfiguration = false;
  showProgrammingModeLabel = false;
  addInterfaceCommandStatus = false;
  creatingPdf = false;
  selectedFileType = true;

  currentMargin = { text: 'Standard', value: 100 };
  marginValues = [{ text: 'Small', value: 50}, { text: 'Standard', value: 100 }, { text: 'Medium', value: 200 }, { text: 'Large', value: 300 }, { text: 'Big', value: 400 }, { text: 'Huge', value: 500 }]

  currentHeight = { text: 'Standard', value: 100 };
  heightValues = [{ text: 'Small', value: 75 }, { text: 'Standard', value: 100 }, { text: 'Medium', value: 125 }, { text: 'Large', value: 150 }, { text: 'Huge', value: 200 }]

  currentWidth = { text: 'Normal', value: 2 };
  widthValues = [{ text: 'Small', value: 1 }, { text: 'Normal', value: 2 }, { text: 'Medium', value: 3 }, { text: 'Large', value: 4 }]

  currentMaxCommand = { text: 'Unconfigured', value: 0 };
  maxCommandValues = [{ text: 'Unconfigured', value: 0 }, { text: '20', value: 20 }, { text: '30', value: 30 }, { text: '40', value: 40 }, { text: '100', value: 100 }, { text: '200', value: 200 },
                      { text: '300', value: 300 }, { text: '400', value: 400 }, { text: '500', value: 500 }, { text: '600', value: 600 }, { text: '700', value: 700 }, { text: '800', value: 800 }]

  constructor(private _zone: NgZone) { }

  ngOnChanges(): void {}

  show() {
    this.step = 1;
    this.addInterfaceCommandStatus = false;
    const element: HTMLElement = document.getElementById('pdfPreviewButton');
    element.click();
  }

  setType(type: string): void {
    this.type = type;
    this.currentMaxCommand = { text: 'Unconfigured', value: 0 };
    if (type === 'code128') {
      this.maxCommandValues = [{ text: 'Unconfigured', value: 0 }, { text: '20', value: 20 }, { text: '30', value: 30 }, { text: '40', value: 40 }]
    } else {
      this.maxCommandValues = [{ text: 'Unconfigured', value: 0 }, { text: '100', value: 100 }, { text: '200', value: 200 }, { text: '300', value: 300 }, { text: '400', value: 400 },
                               { text: '500', value: 500 }, { text: '600', value: 600 }, { text: '700', value: 700 }, { text: '800', value: 800 }]
    }
    this.pdfContent.setType(type);
    
    if (this.family === 'FRS' && this.type === 'datamatrix') {
      this.showProgrammingModeLabel = true;
      this.pdfContent.setProgrammingModeLabel(true);
    }
  }

  toggleConcatenate(): void {
    this.concatenate = !this.concatenate;
    this.pdfContent.setConcatenate(this.concatenate);
  }

  toggleInterfaceCommand(): void {
    this.addInterfaceCommandStatus = !this.addInterfaceCommandStatus;
    this.pdfContent.addInterfaceCommand(this.interfaceCommand, this.addInterfaceCommandStatus);
  }

  toggleConfiguration(): void {
    this.showConfiguration = !this.showConfiguration;
    this.pdfContent.toggleShowConfigutation();
  }

  toggleProgrammingModeLabel(): void {
    this.showProgrammingModeLabel = !this.showProgrammingModeLabel;
    this.pdfContent.toggleProgrammingModeLabel();
  }

  hideProgrammingModeLabelButton(): boolean {
    if (this.family === 'FRS' && this.type === 'datamatrix') {
      return true;
    }
    return false;
  }

  download(): void {
    if(this.selectedFileType) {
      this.pdfContent.downloadRtf();
    } else {
      this.pdfContent.downloadPdf();
    }
  }

  async share(): Promise<void> {
    let file: File = null;
    if(this.selectedFileType) {
      const blob = this.pdfContent.downloadRtf(false);
      file = new File([blob], "test.rtf", {
        type: 'text/html',
      });
    } else {
      const blob = this.pdfContent.downloadPdf(false);
      file = new File([blob], "test.pdf", {
        type: blob.type,
      });
    }

    const response = await fetch('/assets/images/imagecapture1.jpg'); //assets/data/myfile-637599562774270000.pdf assets/images/imagecapture1.jpg
    const blob = await response.blob();
    file = new File([blob], 'lalala.jpg', { type: blob.type });
    await navigator.share({
      url: 'https://www.google.it',
      title: 'Image',
      text: 'Image',
      files: [file],
    });

    // this._zone.runOutsideAngular(() => {
    //   if (navigator.canShare && navigator.canShare({ files: [file] })) {
    //     navigator.share({
    //       //files: [file],
    //       url: 'https://www.google.it',
    //       title: 'Rtf document',
    //       text: 'lalalal123',
    //     })
    //     .then(() => console.log('Share was successful.'))
    //     .catch((error) => console.log('Sharing failed', error));
    //   } else {
    //     console.log(`Your system doesn't support sharing files.`);
    //   }
    // });
  }

  async sharePdf(): Promise<void> {
    let file: File = null;
    if(this.selectedFileType) {
      const blob = this.pdfContent.downloadRtf(false);
      file = new File([blob], "test.rtf", {
        type: 'text/html',
      });
    } else {
      const blob = this.pdfContent.downloadPdf(false);
      file = new File([blob], "test.pdf", {
        type: blob.type,
      });
    }

    const response = await fetch('/assets/data/myfile-637599562774270000.pdf');
    const blob = await response.blob();
    file = new File([blob], 'lalala.jpg', { type: blob.type });
    await navigator.share({
      url: 'https://www.google.it',
      title: 'Image',
      text: 'Image',
      files: [file],
    });
  }

  async shareRtf(): Promise<void> {
    let file: File = null;
    if(this.selectedFileType) {
      const blob = this.pdfContent.downloadRtf(false);
      file = new File([blob], "test.rtf", {
        type: 'text/html',
      });
    } else {
      const blob = this.pdfContent.downloadPdf(false);
      file = new File([blob], "test.pdf", {
        type: blob.type,
      });
    }

    const response = await fetch('/assets/data/myfile-637599591525570000.rtf');
    const blob = await response.blob();
    file = new File([blob], 'lalala.jpg', { type: blob.type });
    await navigator.share({
      url: 'https://www.google.it',
      title: 'Image',
      text: 'Image',
      files: [file],
    });
  }

  downloadPdf(): void {
    this.pdfContent.downloadPdf();
  }

  downloadRtf(): void {
    this.pdfContent.downloadRtf();
  }

  generateDocument(): void {
    const date = new Date();
    const dateString = date.toLocaleString('en-GB');
    const pageSize = 300;
    const pageMin = 20;

    const img = Helper.datalogicLogoBase64;

    ///Margin used on the leftbarcode of A4 exported pdf
    const leftmargin = 15;

    const doc = new jsPDF();
    doc.addImage(img, 'PNG', leftmargin, 20, 80, 10);
    doc.text(leftmargin, 45, this.productName + ' ' + dateString);

    let margin = 60;
    for (const param of this.commands) {
      if ((margin + 7) > pageSize) {
        doc.addPage();
        margin = pageMin;
      }

      // Used protection to store the text value
      doc.text(leftmargin, margin, param.description + ' [' + param.protection + ']');
      margin += 7;
    }

    if ((margin + 10) > pageSize) {
      doc.addPage();
      margin = pageMin;
    }
    doc.addImage(img, 'PNG', leftmargin, margin, 80, 10);

    doc.save('document.pdf');
  }

  printToBarcode() {
    this.step = 2;
    this.setType('code128');
  }

  printToDatamatrix() {
    this.step = 2;
    this.setType('datamatrix');
  }

  back() {
    this.step = 1;
  }

  onMarginValueChanged(option: any) {
    this.currentMargin = option;
  }

  onHeightValueChanged(option: any) {
    this.currentHeight = option;
  }

  onWidthValueChanged(option: any) {
    this.currentWidth = option;
  }

  onMaxCommandValueChanged(option: any) {
    this.currentMaxCommand = option;
  }
}
