<div class="card float-left border-0 mb-3 text-center group-box" [ngClass]="{active: isActive}">
  <a *ngIf="link.valueOf()" routerLink="{{link}}">
    <img class="card-img-top" src="{{image}}" alt="{{title}}" onerror="this.src='assets/images/unknown_device.png'"/>
    <div class="card-body rounded-bottom">
      <h5>{{title}}</h5>
    </div>
  </a>
  <div *ngIf="!link.valueOf()" (click)="viewDetail(id)">
    <img class="card-img-top img-fluid" src="{{image}}" alt="{{title}}" onerror="this.src='assets/images/unknown_device.png'"/>
    <div class="card-body rounded-bottom">
      <h5>{{title}}</h5>
    </div>
  </div>
  <div *ngIf="isUserConfig" class="remove-item-btn" (click)="remove()">
    <div class="remove-item-icon"></div>
  </div>
</div>
