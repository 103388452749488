import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Helper } from '../classes/utility';
import { Language } from '../classes/utility/language';
import { LocalStorageService } from './local-storage.service';

declare var $: any;
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private languages: Language[] = undefined;
  private languageProductStrings: any = {};

  currentLanguage: Language = null;

  currentHelpLink: BehaviorSubject<string> = new BehaviorSubject<string>('assets/mainhelp/index.html');

  constructor(private http: HttpClient, public translate: TranslateService) {

  }

  getLanguages(): Observable<Language[]> {
    return new Observable((observer) => {
      // If languages was already fetched i return it from the private variable
      if (this.languages) {
        observer.next(this.languages);
        observer.complete();
      } else {
        // Fetch values
        this.http.get(Helper.dataDirectory + 'languages.json')
        .subscribe((content: any[]) => {
          // Sending value to anyone who is subscribed to languages
          this.languages = content;
          observer.next(this.languages);
          observer.complete();
        });
      }
    });
  }

  changeLanguage(lang: Language): void {
    $('[data-toggle="tooltip"]').tooltip("dispose"); 
    this.currentLanguage = lang;
    this.translate.use(lang.iso3166_a2).subscribe(
      value => console.log(value),
      ()=>{},
      () => {
        this._getProductStrings(lang);
        this._updateHelpLink();
        
        LocalStorageService.setItem("language", lang);
        setTimeout(() => {
          $('[data-toggle="tooltip"]').tooltip()
        }, 500)
      } 
    );
  }

  getProductString(value: string): string {
    if (!value)
      return value;

    if (this.languageProductStrings[value.trim()]) {
      return this.languageProductStrings[value.trim()];
    }

    return value;
  }

  private _updateHelpLink(): void {
    this.http.get('assets/mainhelp.' + this.currentLanguage.iso3166_a2 + '/index.html', { responseType: 'text' })
    .subscribe((content: string) => {
      this.currentHelpLink.next('assets/mainhelp.' + this.currentLanguage.iso3166_a2 + '/index.html');
    }, (error: any) => {
      this.http.get('assets/mainhelp/index.html', { responseType: 'text' })
      .subscribe((content: string) => {
        this.currentHelpLink.next('assets/mainhelp/index.html');
      });
    });
  }

  private _getProductStrings(lang: Language): void {
    this.http.get(Helper.dataDirectory + 'prd_config_' + lang.iso3166_a2 + '.json')
    .subscribe((content: any[]) => {
      this.languageProductStrings = content;
    }, (error: any) => {
      // File not found
      this.languageProductStrings = {};
    });
  }
}
