import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as ace from 'ace-builds';

import 'ace-builds/src-noconflict/mode-assembly_x86';
import 'ace-builds/src-noconflict/mode-crystal';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

@Component({
  selector: 'app-certificate-editor',
  templateUrl: './certificate-editor.component.html',
  styleUrls: ['./certificate-editor.component.css']
})
export class CertificateEditorComponent implements AfterViewInit, OnChanges {
  @Input() type = '';
  @Input() text;
  @Input() title = '';
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('certificateEditor') certificateEditorElmRef: ElementRef;

  private certificateEditor: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.refreshEditor();
    this.refreshText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
      this.refreshEditor();
    }

    let certificateEditorCode = '';
    if (this.certificateEditor) {
      certificateEditorCode = this.certificateEditor.getSession().getValue();
    }
    if (changes.text && changes.text.currentValue !== certificateEditorCode) {
      this.refreshText();
    }
  }

  private refreshEditor(): void {
    if (!this.certificateEditorElmRef) {
      return;
    }

    const element = this.certificateEditorElmRef.nativeElement;

    const options = {
      minLines: 30,
      maxLines: 30,
      highlightSelectedWord: true,
      showPrintMargin: false
    };

    this.certificateEditor = ace.edit(element, options);
    this.certificateEditor.setTheme('ace/theme/github');

    this.certificateEditor.getSession().setMode('ace/mode/assembly_x86');
    
    this.certificateEditor.setShowFoldWidgets(true);
    this.certificateEditor.getSession().on('change', () => {
      this.valueChanged.emit(this.certificateEditor.getSession().getValue());
    });
  }

  private refreshText(): void {
    if (!this.certificateEditorElmRef) {
      return;
    }

    if (this.certificateEditor) {
      this.certificateEditor.getSession().setValue(this.text, -1);
    } else {
      this.certificateEditorElmRef.nativeElement.innerHTML = this.text;
    }
  }
}
