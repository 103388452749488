import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/classes/utility';
@Component({
  selector: 'app-firmware-upgrade',
  templateUrl: './firmware-upgrade.component.html',
  styleUrls: ['./firmware-upgrade.component.css']
})
export class FirmwareUpgradeComponent implements OnInit {

  constructor() { }
  developerMode = Helper.developerModeEnabled;

  ngOnInit(): void {
    // Creating the public object to be called by JxBrowser

  }
  show() {
    const element: HTMLElement = document.getElementById('firmwareUpgradeButton');
    element.click();
  }
}
