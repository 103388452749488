import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';

import { Helper, RecentlyViewed, HelpItem } from '../classes/utility';
import { LocalStorageService } from '../services/local-storage.service';
import { Product } from '../classes/products/product';
import { XmlService } from './xml.service';
import { LanguageService } from './language.service';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  // Product menu list
  private productsMenu: any[] = undefined;
  private productsList: string[] = undefined;
  private productsListMain: string[] = undefined;
  private productsListUser: string[] = undefined;
  private recentlyViewed: RecentlyViewed[] = undefined;

  constructor(private http: HttpClient, private xmlService: XmlService,
              private languageService: LanguageService) {}

  getProductsMenu(): Observable<any[]> {
    return new Observable((observer) => {
      // If productsMenu was already fetched i return it from the private variable
      if (this.productsMenu) {
        observer.next(this.productsMenu);
        observer.complete();
      } else {
        // Init products menu tree
        this.http.get(Helper.dataDirectory + 'productsMenu.json')
        .subscribe((content: any[]) => {
          // Sending value to anyone who is subscribed to productsMenu
          this.productsMenu = content;
          observer.next(this.productsMenu);
          observer.complete();
        });
      }
    });
  }

  getProducts(): Observable<any> {
    return new Observable((observer) => {
      // If productsMenu was already fetched i return it from the private variable
      if (this.productsList) {
        observer.next(this.productsList);
        observer.complete();
      } else {
        this.http.get(Helper.dataDirectory + 'products.json')
        .subscribe((content: string[]) => {
          // Sending value to anyone who is subscribed to productsMenu
          this.productsListMain = content;
          this.productsList = content;
          observer.next(content);
          observer.complete();
        });
      }
    });
  }

  getProduct(index: number): Observable<Product> {
    return new Observable((observer) => {
      this.getProductsMenu()
      .subscribe(() => {
        // Due to productsUser.json can be changed by user, fetch productsList again for each getProduct() execution
        // Collect the main products list from products.json

        this.http.get(Helper.dataDirectory + 'products.json')
        .subscribe((contentMain: string[]) => {
          // Sending value to anyone who is subscribed to productsMenu
          this.productsListMain = contentMain;
          // Collect the user products list from productsUser.json
          this.http.get(Helper.dataDirectory + 'productsUser.json' + '?' + (new Date()).getTime())
          .subscribe((contentUser: string[]) => {
            // Sending value to anyone who is subscribed to productsMenu
            this.productsListUser = contentUser;
            // Init products list
            if (this.productsListUser != null) {
              this.productsList = this.productsListMain.concat(this.productsListUser);
            } else {
              this.productsList = this.productsListMain;
            }
            observer.next(this.getProductsInfo(index));
            observer.complete();
          });
        });
      });
    });
  }

  getProductHelp(product: Product): Observable<HelpItem[]> {
    return new Observable((observer) => {
      // Fetch help folder
      let helpUrl: string = product.helpUrl;
      this.http.get(helpUrl, { responseType: 'text' })
      .subscribe((content: string) => {
        // Fetch helpset
        console.log(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs');
        this.http.get(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs', { responseType: 'text' })
        .subscribe((helpset: string) => {
          // Find map location
          const findMapLocationRegex = /location=\"(.*?)\"/g;
          const mapResults = findMapLocationRegex.exec(helpset);
          if (mapResults.length > 0) {
            // If i have a map location fetch help map
            this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/')
            .subscribe((helpItems: HelpItem[]) => {
              observer.next(helpItems);
              observer.complete();
            });
          }
        }, (error: any) => {
          this.http.get(Helper.dataDirectory + content + '/help.hs', { responseType: 'text' })
          .subscribe((helpset: string) => {
            // Find map location
            const findMapLocationRegex = /location=\"(.*?)\"/g;
            const mapResults = findMapLocationRegex.exec(helpset);
            if (mapResults.length > 0) {
              // If i have a map location fetch help map
              const mapLocation = Helper.dataDirectory + content + '/' + mapResults[1];
              this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '/')
              .subscribe((helpItems: HelpItem[]) => {
                observer.next(helpItems);
                observer.complete();
              });
            }
          }, (error: any) => {
            //Use Generic device_000000000 helpUrl if cannot get the helpUrl from current product HelpUrl.txt
            product.helpUrl = Helper.baseProductsDirectory + 'Generic device_000000000' + '/HelpUrl.txt';
            helpUrl = product.helpUrl;
            // Fetch help folder
            this.http.get(helpUrl, { responseType: 'text' })
            .subscribe((content: string) => {
              // Fetch helpset
              console.log(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs');
              this.http.get(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs', { responseType: 'text' })
              .subscribe((helpset: string) => {
                // Find map location
                const findMapLocationRegex = /location=\"(.*?)\"/g;
                const mapResults = findMapLocationRegex.exec(helpset);
                if (mapResults.length > 0) {
                  // If i have a map location fetch help map
                  this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/')
                  .subscribe((helpItems: HelpItem[]) => {
                    observer.next(helpItems);
                    observer.complete();
                  });
                }
              }, (error: any) => {
                this.http.get(Helper.dataDirectory + content + '/help.hs', { responseType: 'text' })
                .subscribe((helpset: string) => {
                  // Find map location
                  const findMapLocationRegex = /location=\"(.*?)\"/g;
                  const mapResults = findMapLocationRegex.exec(helpset);
                  if (mapResults.length > 0) {
                    // If i have a map location fetch help map
                    const mapLocation = Helper.dataDirectory + content + '/' + mapResults[1];
                    this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '/')
                    .subscribe((helpItems: HelpItem[]) => {
                      observer.next(helpItems);
                      observer.complete();
                    });
                  }
                });
              });
            });
          });
        });
      }, (error: any) => {
        //Use Generic device_000000000 helpUrl if cannot get the helpUrl from current product HelpUrl.txt
        product.helpUrl = Helper.baseProductsDirectory + 'Generic device_000000000' + '/HelpUrl.txt';
        helpUrl = product.helpUrl;
        // Fetch help folder
        this.http.get(helpUrl, { responseType: 'text' })
        .subscribe((content: string) => {
          // Fetch helpset
          console.log(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs');
          this.http.get(Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/help.hs', { responseType: 'text' })
          .subscribe((helpset: string) => {
            // Find map location
            const findMapLocationRegex = /location=\"(.*?)\"/g;
            const mapResults = findMapLocationRegex.exec(helpset);
            if (mapResults.length > 0) {
              // If i have a map location fetch help map
              this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '.' + this.languageService.currentLanguage.iso3166_a2 + '/')
              .subscribe((helpItems: HelpItem[]) => {
                observer.next(helpItems);
                observer.complete();
              });
            }
          }, (error: any) => {
            this.http.get(Helper.dataDirectory + content + '/help.hs', { responseType: 'text' })
            .subscribe((helpset: string) => {
              // Find map location
              const findMapLocationRegex = /location=\"(.*?)\"/g;
              const mapResults = findMapLocationRegex.exec(helpset);
              if (mapResults.length > 0) {
                // If i have a map location fetch help map
                const mapLocation = Helper.dataDirectory + content + '/' + mapResults[1];
                this.xmlService.getHelpXml(mapResults[1], Helper.dataDirectory + content + '/')
                .subscribe((helpItems: HelpItem[]) => {
                  observer.next(helpItems);
                  observer.complete();
                });
              }
            });
          });
        });
      });
    });
  }

  getHelpContent(basePath: string, name: string): Observable<string> {
    return new Observable((observer) => {
      this.http.get(basePath + name, { responseType: 'text' })
      .subscribe((content: string) => {
        let dom: Document = null;
        if (window.DOMParser) {
          try {
              dom = (new DOMParser()).parseFromString(content, 'text/html');
          }
          catch (e) { dom = null; }
        }

        if (dom) {
          const body = dom.querySelector('body');

          // Add image path
          let replacedContent = body.innerHTML.replace(/..\/Images/g, basePath + '/Images');
          // Take out multiple close nbsp
          replacedContent = replacedContent.replace(/(<p>&nbsp;<\/p>[\r\n]){2,}/g, '');

          observer.next(replacedContent);
        }

        observer.complete();
      });
    });
  }

  getHelpItemContent(item: HelpItem): Observable<string> {
    return new Observable((observer) => {
      if (item.content && item.content !== '') {
        if(item.content.includes("<style type='text/css'>.help_developer{ display:")){
          const index = item.content.indexOf("</style>") + 8;
          item.content = item.content.substring(index);
          item.content = this.helpModeDisplay() + item.content;
        }
        observer.next(item.content);
        observer.complete();
      } else {
        // Fetch help folder
        this.http.get(item.url, { responseType: 'text' })
        .subscribe((content: string) => {
          let dom: Document = null;
          if (window.DOMParser) {
            try {
                dom = (new DOMParser()).parseFromString(content, 'text/html');
            }
            catch (e) { dom = null; }
          }

          if (dom) {
            const body = dom.querySelector('body');

            // Add image path
            let replacedContent = body.innerHTML.replace(/..\/Images/g, item.basePath + '/Images');
            // Take out multiple close nbsp
            replacedContent = replacedContent.replace(/(<p>&nbsp;<\/p>[\r\n]){2,}/g, '');

            item.content = this.helpModeDisplay() + replacedContent;
            observer.next(item.content);
          }

          observer.complete();
        });
      }
    });
  }

  removeItemFromRecentlyViewed(index: number): void {
    this.recentlyViewed.splice(index, 1);
    LocalStorageService.setItem('rw', this.recentlyViewed);
  }

  getRecentlyViewed(): RecentlyViewed[] {
    if (!this.recentlyViewed) {
      this.recentlyViewed = (LocalStorageService.getItem('rw') as RecentlyViewed[]) || [];
    }
    return this.recentlyViewed;
  }

  saveProductToRecentlyViewed(product: Product, connection: string): void {
    if (!this.recentlyViewed) {
      this.getRecentlyViewed();
    }

    // If i have the same item remove it and add to the beginning
    const foundItemIndex  = this.recentlyViewed.findIndex(it => it.id === product.internalName);
    if (foundItemIndex >= 0) {
      // Remove found item
      this.recentlyViewed.splice(foundItemIndex, 1);
    } else {
      // If longer than 4 i take only the first three elements
      if (this.recentlyViewed.length >= 4) {
        this.recentlyViewed = this.recentlyViewed.slice(0, 3);
      }
    }

    // Insert item at the beginning of the array
    this.recentlyViewed.unshift(RecentlyViewed.fromProduct(product, connection));

    LocalStorageService.setItem('rw', this.recentlyViewed);
  }

  findProductsByName(name: string): Observable<Product[]> {
    return new Observable((observer) => {
      forkJoin(
        [
          this.getProductsMenu(),
          this.getProducts()
        ]
      ).subscribe((results) => {
        const productNodes = this.getProductNodes(results[0]);
        const products = productNodes
                          .filter(it => it !== undefined && (
                            it.name.toLowerCase().indexOf(name.toLowerCase()) >= 0 ||
                            (it.alias && it.alias.find(a => a.toLowerCase().indexOf(name.toLowerCase()) >= 0))
                          ))
                          .map(it => this.getProductsInfo(parseInt(it.children[0], 10)));
        observer.next(products);
        observer.complete();
      });
    });
  }

  getProductsInfo(productIndex: number): Product {
    if (productIndex < 0) {
      return new Product('Name', Helper.genericDeviceFolder, '',
        [{index: productIndex, name: Helper.genericDeviceFolder}], productIndex);
    } else if (this.productsListMain != undefined && productIndex >= this.productsListMain.length && productIndex <= this.productsList.length - 1) {
      //Generate product object is defined in productsUser.json
      let product: string = "";
      let releases: string = ""; 
      if (this.productsList[productIndex].lastIndexOf("_") !== -1 && this.productsList[productIndex].lastIndexOf("_") !== this.productsList[productIndex].length -1) {
        product = this.productsList[productIndex].substring(0, this.productsList[productIndex].lastIndexOf("_"));
        releases = this.productsList[productIndex].substring(this.productsList[productIndex].lastIndexOf("_") + 1, this.productsList[productIndex].length);
      } else {
        product = this.productsList[productIndex];
        releases = "";
      }

      return new Product(product, this.productsList[productIndex],"", [{index: releases, name: this.productsList[productIndex]}], productIndex);
    } else {
      const product = this.findProductByIndex(this.productsMenu, productIndex);
      const releases = product.children.map(it => {
        return {index: it, name: this.productsList[it]};
      });

      return new Product(product.name, this.productsList[productIndex], product.link, releases, productIndex);
    }
  }

  private findProductByIndex(items: any[], index: number): any {
    for (const item of items) {
      if (item.children) {
        const found = item.children.find(it => parseInt(it, 10) === index);
        if (found) {
          return item;
        }
        else {
          const isParent = this.findProductByIndex(item.children, index);
          if (isParent) {
            return isParent;
          }
        }
      } else {
        return undefined;
      }
    }
  }

  private getProductNodes(tree: any[]): any[] {
    let result = [];
    for (const item of tree) {
      if (item.children) {
        if (item.children.length > 0) {
          if (item.children[0].name) {
            result = result.concat(this.getProductNodes(item.children));
          } else {
            result = result.concat(item);
          }
        }
      }
    }

    return result;
  }

  getProductIndexByName(internalName: string): Observable<Number> {
    return this.http.get(Helper.dataDirectory + 'products.json').pipe(
      switchMap((value: string[]) => {
        this.productsListMain = value;
        return this.http.get(Helper.dataDirectory + 'productsUser.json' + '?' + (new Date()).getTime())
      }),
      map((contentUser: string[]) => {
        this.productsListUser = contentUser;
        // Init products list
        if (this.productsListUser != null) {
          this.productsList = this.productsListMain.concat(this.productsListUser);
        } else {
          this.productsList = this.productsListMain;
        }

        let index = this.productsList.findIndex((value) => value == internalName);
        return index;
    }));

  }

  getProductUser(): Observable<string[]> {
    return this.http.get(Helper.dataDirectory + 'productsUser.json'+ '?' + (new Date()).getTime()).pipe(
      tap((contentUser: string[]) => {
        this.productsListUser = contentUser;
        this.productsList = this.productsListMain.concat(this.productsListUser);
      })
    );
  }

  getProductMain(): Observable<string[]>  {
    if(this.productsListMain) {
      return of(this.productsListMain);
    } else {
      return this.http.get(Helper.dataDirectory + 'products.json').pipe(
        tap((value: string[]) => {
          this.productsListMain = value;
        })
      )
    }
  }

  helpModeDisplay() {
    let scriptDevelopDisplay = "none";
    let scriptSecureDisplay = "none";
    if(Helper.developerModeEnabled) {
      scriptDevelopDisplay = "block";
    }
    if(Helper.secureModeEnabled) {
      scriptSecureDisplay = "block";
    }
    const script = "<style type='text/css'>.help_developer{ display:"+ scriptDevelopDisplay + "}.help_secure{ display:"+ scriptSecureDisplay +"}</style>";
    return script;
  }
}
