import { Component, Input, OnInit } from '@angular/core';
import { NotificationType } from "src/app/classes/enums/notification-type.enum"
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  @Input() identifier: string  = "";
  notificationType: NotificationType = NotificationType.Error;
  message: string = "";
  constructor() { }
  ngOnInit(): void {
  }

  showAlert(notificationType: NotificationType, message: string) {
    this.notificationType = notificationType;
    this.message = message;
    const button = document.getElementById(this.identifier + "Button");
    button.click();
  }

  getColor() {
    let borderTop = "6px solid ";
    switch (this.notificationType) {
      case NotificationType.Error:
        borderTop += "red";
        break;
      case NotificationType.Success:
        borderTop += "green";
        break;
      case NotificationType.Warning:
        borderTop += "#FFA500";
        break;
      case NotificationType.Confirm:
        borderTop += "black";
        break;  
      default:
        borderTop += "red";
        break;
    }
    return borderTop;
  }
}
