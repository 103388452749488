import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from 'src/app/classes/utility/language';
import { LanguageService } from 'src/app/services/language.service';

declare var $: any;
@Component({
  selector: 'app-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.css']
})
export class LanguageSelectionComponent implements OnInit {
  public static passLangValue: BehaviorSubject<string> = new BehaviorSubject<string>('English');
  
  languages: Language[] = [];
  currentLanguage = '';

  constructor(private languageService: LanguageService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.languageService.getLanguages()
    .subscribe((languages) => {
      this.languages = languages;
    });

    this.translate.onLangChange
    .subscribe((event: LangChangeEvent) => {
      this.currentLanguage = this.translate.currentLang;
    });

    window.languageSelectionComponent = this;
  }

  show() {
    const element: HTMLElement = document.getElementById('langSelectionButton');
    element.click();

    this.currentLanguage = this.translate.currentLang;
  }

  changeLanguage(lang: Language): void {
    this.languageService.changeLanguage(lang);
    LanguageSelectionComponent.passLangValue.next(lang.name);
  }
}
