import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-send-feedback',
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.css']
})
export class SendFeedbackComponent implements OnInit {
  feedbackEmail = "aladdin.support@datalogic.com";
  emailSubject = "Feedback for Aladdin " + document.getElementById("version-spam").innerText;

  constructor(private languageService: LanguageService, private translate: TranslateService) { }

  ngOnInit(): void {
    window.sendFeedbackComponent = this;
  }

  show() {
    const element: HTMLElement = document.getElementById('sendFeedbackButton');
    element.click();
  }
}
