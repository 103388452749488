<button type="button" class="btn btn-info btn-lg d-none" data-toggle="modal" [attr.data-target]="'#' + identifier" [id]="identifier + 'Button'"></button>

<!-- Modal -->
<div [id]="identifier" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header" [style.border-top]="getColor()">
        <h4 class="modal-title">{{notificationType}}</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <p>{{message}}</p>
      </div>
      <div class="modal-footer">
        <div class="col-6 text-right">
          <a role="button" class="btn btn-outline-warning" style="white-space: nowrap;"
          data-dismiss="modal">
            {{'SHARED.OK' | translate}}
          </a>
        </div>
      </div>
    </div>

  </div>
</div>