import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-capture',
  templateUrl: './image-capture.component.html',
  styleUrls: ['./image-capture.component.css']
})
export class ImageCaptureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  show() {
    const element: HTMLElement = document.getElementById('ImageCaptureButton');
    element.click();
  }


  rangeValue(e, id){


    document.getElementById(id + 'View').innerHTML = e.target.value;

  }

}
