<div class="topTerminal">
  <form class="form-inline d-flex">
    <input type="text" class="form-control mb-2 mr-sm-2 flex-grow-1" id="terminalCommand" placeholder="">

    <div id="send" type="submit" class="mb-2 d-flex align-items-center justify-content-center" id="terminalSend"
     (click)="clickOnSendTerminalButtonEvent()" [title]="'SHARED.SEND' | translate">
     <img src="assets/images/send.png" [alt]="'SHARED.SEND' | translate" class="terminal_send_icon">
    </div>
  </form>
</div>
<div class="mailTerminal" #text id="terminalText">
  <div *ngFor="let content of contents">{{content}}</div>
</div>
<div class="bottomTerminal mt-2">
  <div class="form-inline">
    <button type="button" id="terminalSaveText" class="btn btn-outline-warning mb-2 terminal_btn" (click)="clickOnSaveTextButtonEvent()" [title]="'TERMINAL.SAVE-TEXT' | translate">
      <img src="assets/images/terminal-save-text.svg" [alt]="'TERMINAL.SAVE-TEXT' | translate" class="terminal_icon">
    </button>

    <div class="btn btn-outline-warning mb-2 terminal_btn" id="terminalClearText" (click)="clickOnClearTextButtonEvent()" [title]="'TERMINAL.CLEAR-TEXT' | translate">
      <img src="assets/images/terminal-clear-text.svg" [alt]="'TERMINAL.CLEAR-TEXT' | translate" class="terminal_icon">
    </div>

    <div class="btn btn-outline-warning mb-2" id="show_custom_buttons_toggle" (click)="rotateArrow()" type="button" data-toggle="collapse" data-target="#custom_buttons_container" aria-expanded="false" aria-controls="custom_buttons_container" [hidden]="family!='FRS' || isRemoteTab || !isOnline" [title]="'TERMINAL.SHOW-MORE' | translate">
      <img src="assets/images/terminal-show-more.svg" [alt]="'TERMINAL.SHOW-MORE' | translate" class="terminal_icon"  [ngClass]="[showMore]">
    </div>

    <div class="btn btn-outline-warning mb-2 terminal_btn" id="btnExpandTerminal" [title]="'TERMINAL.EXPAND' | translate">
      <img src="assets/images/terminal-expand.svg" [alt]="'TERMINAL.EXPAND' | translate" class="terminal_icon">
    </div>

    <div class="form-check mb-2" [hidden]="isRemoteTab">
      <input class="form-check-input" type="checkbox" id="log">
      <label class="form-check-label" for="log">
        {{'TERMINAL.LOG' | translate}}
      </label>
    </div>
    <div class="form-check mb-2" [hidden]="isRemoteTab">
      <input class="form-check-input" type="checkbox" id="notprintchars">
      <label class="form-check-label" for="notprintchars">
        {{'TERMINAL.SHOW-CHARS' | translate}}
      </label>
    </div>
  </div>

  <div class="w-90 collapse terminal_show_more" id="custom_buttons_container" [hidden]="family!='FRS'">
    <div class=" d-flex flex-wrap">
      <div class="custom_btn terminal_btn" id="collapsibleTerminalButton" *ngFor="let btn of customButtons" 
      [attr.data-command]="btn.command" 
      [attr.data-promptForNBytes]="btn.promptForNBytes">
        {{btn.name}}
      </div>
      <div class="custom_btn terminal_btn" id="btnEditButtons">
        <img src="assets/images/terminal-custom-edit.svg" [alt]="'TERMINAL.EDIT' | translate" class="terminal_icon">
      </div>
    </div>
  </div>
</div>
