import { Component, OnInit, NgZone, ViewChild, AfterContentInit, AfterViewInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Product } from 'src/app/classes/products/product';
import { FormControl } from '@angular/forms';
import { Helper } from 'src/app/classes/utility';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';
import { TabNavigationService } from 'src/app/services/tab-navigation.service';
import { TabItem } from 'src/app/classes/utility';
import { RemoteService } from 'src/app/services/remote.service'
import { SettingsService } from 'src/app/services/settings.service'
import { RecentComponent } from 'src/app/shared/recent/recent.component';
import { isBrowserOnRefresh } from 'src/app/app.component';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { DetectionModeType, DetectionSetting } from 'src/app/classes/detection-setting';
import { retry } from 'rxjs/operators';


declare var $;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterContentInit {
  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild('recent') recent: RecentComponent;
  // Array of categories read from json file
  productsMenu: any[];
  deviceSearchValue = '';
  searchFormControl: FormControl;
  newsVisible = true;
  standaloneMode: boolean;
  isRemoted = false;
  azureMode: boolean = false;
  isBrowserOnRefresh: boolean = false;

  searchedProducts: Product[] = [];

  constructor(private dataService: DataService, private tabNavigationService: TabNavigationService, private zone: NgZone,
              private remoteService: RemoteService, private settingsService: SettingsService) {
    // Creating the public object to be called by JxBrowser
    window.homeComponent = {
      component: this,
      zone: this.zone,
      toggleNewsVisible: (value: boolean) => this.newsVisible = value,
      enableStandaloneMode: () => Helper.enableStandaloneMode(),
      activeStandaloneEnableCloud: () => Helper.activeStandaloneEnableCloud(),
      refreshRecentlyViewed:(jsonStr: string) => this.recent.refreshRecentlyViewedProducts(jsonStr),
      checkOfflineProductPageOpened: (checkingProducIndex: number, comPortName: string) => this.checkOfflineProductPageOpened(checkingProducIndex, comPortName),
      sendCurrentDevicesToRemoteFromJs: (value: string) => this.sendCurrentDevicesToRemoteFromJs(value),
      setSearchDeviceStatus: (value: string) => this.remoteService.setSearchDeviceStatus(value),
      sendCurrentPortsToRemoteFromJs: (value: string) => this.sendCurrentPortsToRemoteFromJs(value),
      getPortList: () => this.remoteService.getPortList(),
      updatePortListFromJava:(ports: string) => this.updatePortListFromJava(ports),
      enableShowParameterCode: () => this.settingsService.enableShowParameterCode(),
      getDetectionSettingFromJava:(value: string) => this.getDetectionSettingFromJava(value)
    };

    // Subscribing to the productsMenu result to have it as soon as possible
    dataService.getProductsMenu()
    .subscribe(menu => {
      // Content ready, setting my variable so the html can be drawn
      this.productsMenu = menu;
    });

    Helper.standaloneMode
    .subscribe(value => {
      this.standaloneMode = value;
    });

    Helper.azureMode
    .subscribe(value => {
      this.azureMode = value;
    });

    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
    });
  }

  ngOnInit(): void {
    this.onTabReload();
  }

  ngAfterContentInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="tooltip"]').hover(function(){
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  ngAfterViewInit(): void{
    this.sidebar.settingModeEvent.subscribe((value) => {
      if (this.standaloneMode) {
        let setting = new DetectionSetting(value);
        try{
          let json = JSON.stringify(setting);
          window.getDetectionSettingFromJs(json);
        }catch(err){
          console.log("send setting err: ",err);
        }
      }
    });
  }

  getDetectionSettingFromJava(settingString: string):void{
    let setting = JSON.parse(settingString);
    if(setting){
      if(setting.detectionMode == DetectionModeType.AllPorts){
        this.sidebar.setDetectionMode(DetectionModeType.AllPorts);
      }else if(setting.detectionMode == DetectionModeType.DefaultPorts){
        this.sidebar.setDetectionMode(DetectionModeType.DefaultPorts);
      }
    }
  }

  refreshSearch(value): void {
    if (value === '') {
      this.searchedProducts = [];
    } else {
      this.dataService.findProductsByName(value)
      .subscribe(result => {
        this.searchedProducts = result;
      });
    }
  }

  checkOfflineProductPageOpened(checkingProducIndex: number, comPortName: string): boolean {
    let checkingProduct: Product = this.dataService.getProductsInfo(checkingProducIndex);
    let result: boolean = this.tabNavigationService.checkOfflineTabOpened(TabItem.fromProduct(checkingProduct, comPortName));
    return result;
  }

  sendCurrentDevicesToRemoteFromJs(value: string) {
    this.remoteService.listRemoteDevicesCurrent.next(this.remoteService.generateDeviceListData(JSON.parse(value)));
    this.remoteService.listRemoteDevices.next(this.remoteService.generateDeviceListData(JSON.parse(value)));
    this.remoteService.sendCurrentDevicesToRemote(value);
  }

  sendCurrentPortsToRemoteFromJs(value: string) {
    let portList = value.split(";");
    this.remoteService.setUpPortList(portList);
    this.remoteService.sendCurrentPortListToRemote(value);
  }

  updatePortListFromJava(ports: string) {
    this.remoteService.updatePortListFromJava(ports? ports.split(';'): []);
    if(this.isRemoted){
      this.remoteService.sendUpdatePortListToRemote(ports);
    }
  }

  onTabReload(): void{
    this.isBrowserOnRefresh = isBrowserOnRefresh;
    if(this.isBrowserOnRefresh){
      let tabs = SessionStorageService.getItem('openingTabs');
      if(tabs){
        tabs.forEach(tab => {
          this.tabNavigationService.addTab(tab);
        });
      }else{
        TabNavigationService.openingTabs = [];
      }
    }
  }
}
