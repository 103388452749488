import { ElementsService } from './services/elements/elements.service';
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from './classes/utility';
import { LanguageService } from './services/language.service';
import { HttpClient } from '@angular/common/http';
import { LocalStorageService } from './services/local-storage.service';
import { Language } from './classes/utility/language';
import { FooterComponent } from './shared/footer/footer.component';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { SessionStorageService } from './services/session-storage.service';

export let isBrowserOnRefresh: boolean  = false;
declare var $;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild(FooterComponent, {read: ElementRef}) public footerElementRef: ElementRef;

  title = 'Datalogic Aladdin';
  subscription: Subscription;

  constructor(public translate: TranslateService, languageService: LanguageService, private http: HttpClient, public elementsService: ElementsService, private router: Router) {
    // detect on refresh event
    this.subscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        isBrowserOnRefresh = !router.navigated;
      }
    });

    // Fetch languages
    languageService.getLanguages()
    .subscribe((languages) => {
      const iso3166_a2codes = languages.map(it => it.iso3166_a2);
      const regex = new RegExp(iso3166_a2codes.join('|'));
      const defaultLang = languages.find(it => it.default);
      // Init translate service
      translate.addLangs(iso3166_a2codes);
      let language = LocalStorageService.getItem("language") as Language;
      if(language) {
        languageService.changeLanguage(language);
      } else if (defaultLang) {
        translate.setDefaultLang(defaultLang.iso3166_a2);

        const browserLang = translate.getBrowserLang();
        if (browserLang.match(regex)) {
          translate.use(browserLang);
          languageService.changeLanguage(languages.find(it => it.iso3166_a2 === browserLang));
        } else {
          translate.use(defaultLang.iso3166_a2);
          languageService.changeLanguage(defaultLang);
        }
      }
    });
  }

  ngOnInit(): void {
    // Creating the public object to be called by JxBrowser
    window.setWebsocketServer = (value: string) => Helper.setWebsocketServer(value);
    window.setTurnServer = (value: string) => Helper.setTurnServer(value);
    window.enableDeveloperMode = () => Helper.enableDeveloperMode();
    window.enableSecureMode = () => Helper.enableSecureMode();
    document.addEventListener("mousedown", ev => {
      setTimeout(() => {
        $('[data-toggle="tooltip"]').tooltip("dispose");
      }, 500)
    });
  }
  ngAfterViewInit(): void {
      this.elementsService.footerElement = this.footerElementRef.nativeElement;
  }
}



