<button type="button" id="cloudSaveConfigWindowButton" class="btn btn-primary" data-toggle="modal"  data-backdrop="static" data-keyboard="false" data-target="#cloudSaveConfigWindow"></button>

<div class="modal fade" id="cloudSaveConfigWindow" tabindex="-1" role="dialog" aria-labelledby="cloudSaveConfigWindowLabel" aria-hidden="true" style="overflow: hidden;">
    <div class="modal-dialog modal-large" role="document">
        <div class="modal-content" style="height: 80%;">
            <div class="modal-header">
                <h5 class="modal-title">{{'SHARED.CLOUD-SERVICE-CONFIGURATION' | translate}}</h5>
                <button id="cloudSaveConfigWindowClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" style="height: 80%;">
                <form class="form-inline"  style="justify-content: space-between;">
                    <div class="form-group">
                        <input class="form-control ml-1" type="text" id="inputCloudSaveConfigName" name="inputCloudSaveConfigName" [placeholder]="('PRODUCT.CONFIGURATION'| translate) + ' ' +('SHARED.NAME' | translate)">
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <button class="btn btn-outline-warning m-0" id="buttonCloudConfigName" type="button" (click)="saveConfigToCloud()">{{'SHARED.SAVE' | translate}}</button>
                    </div>
                    <ng-container *ngIf="webApiRes.length>0;">
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <div class="form-group">
                            <input class="form-control ml-1" type="text" id="inputCloudLoadConfigName" name="inputCloudLoadConfigName" [placeholder]="('PRODUCT.CONFIGURATION'| translate) + ' ID'">
                            <span>&nbsp;&nbsp;&nbsp;</span>
                            <button class="btn btn-outline-warning m-0" id="buttonCloudLoadConfigName" type="button" (click)="loadConfigFromCloud()">{{'SHARED.LOAD' | translate}}</button>
                        </div>
                        <button class="btn btn-outline-warning m-0 float-right" id="buttonCloudLoadConfigName" type="button" (click)="deleteConfigFromCloud()">{{'SHARED.DELETE' | translate}}</button>
                    </ng-container>
                </form>
                <div style="margin-top: 20px;height: 90%;">
                    <div class="d-flex" style="align-items: center; justify-content: center; margin: 20px;" *ngIf="webApiRes.length == 0 || !webApiRes">
                        <app-loading-bar></app-loading-bar>
                    </div>
                    <table style="width:100%;overflow: auto; height: 100%;margin-right: 20px;" class="table d-block" [hidden]="!webApiRes">
                        <tr>
                            <th style="text-align:center">
                                <div class="checkbox">
                                    <label>
                                        Select
                                    </label>
                                </div>
                            </th>
                            <th style="text-align:center">ID</th>
                            <th style="text-align:center">{{'SHARED.NAME' | translate}}</th>
                            <th style="text-align:center">{{'SHARED.PRODUCT' | translate}}</th>
                            <th style="text-align:center">{{'SHARED.RELEASE' | translate}}</th>
                            <th style="text-align:center">{{'SHARED.LAST-UPDATE' | translate}}</th>
                        </tr>
                        <tr [formGroup]="listBlobFiltterFormGroup">
                            <td class="d-flex" style="justify-content: center;align-items: center;border: none;"> 
                                <input type="checkbox" (change)="changeInputSelectAll($event)">        
                            </td>
                            <td><input class="form-control" type="input" value="" formControlName="id" [placeholder]="('SHARED.FILTER-BY'| translate) + ' ID'"></td>
                            <td><input class="form-control" type="input" value="" formControlName="name" [placeholder]="('SHARED.FILTER-BY'| translate) + ' ' +('SHARED.NAME' | translate)"></td>
                            <td><input class="form-control" type="input" value="" formControlName="product" [placeholder]="('SHARED.FILTER-BY'| translate) + ' ' +('SHARED.PRODUCT' | translate)"></td>
                            <td><input class="form-control" type="input" value="" formControlName="releaseSW" [placeholder]="('SHARED.FILTER-BY'| translate) + ' ' +('SHARED.RELEASE' | translate)"></td>
                            <td class="d-flex" style="border: none;">
                                <input class="form-control" style="margin-right: 10px;" type="text" id="datepickerFrom" value="" formControlName="lastUpdateFrom" [placeholder]="'SHARED.FROM' | translate"> 
                                <input class="form-control" type="text" id="datepickerTo" value="" formControlName="lastUpdateTo" [placeholder]="'SHARED.TO' | translate">
                            </td>
                        </tr>
                        <ng-container *ngFor="let blob of listBlobFilter;let indexOfElement=index;">
                            <tr (click)="selectTrConfigElement(blob.id, indexOfElement)" [class.tr-highlight]="selectedIndex == indexOfElement">
                                <td class="text-center"><input type="checkbox" [formControl]="formArrCheckBox.controls[indexOfElement]"></td>
                                <td>{{blob.id}}</td>
                                <td>{{blob.name}}</td>
                                <td style="white-space: nowrap;">{{blob.product}}</td>
                                <td>{{blob.releaseSW}}</td>
                                <td>{{blob.lastUpdate | date:"MM-dd-yyyy HH:mm:ss"}}</td>
                            </tr>
                        </ng-container>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary white-text-footer" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<button type="button" id="confirmDeleteButton" class="d-none" data-toggle="modal" data-target="#confirmDeleteModal"></button>
<div class="modal fade" id="confirmDeleteModal" tabindex="-2" role="document" aria-labelledby="small" aria-hidden="true">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{ ('SHARED.DELETE' | translate).toUpperCase()}}</h4>
                <button type="button" id="closeDeleteModal" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
            {{getValueIsSelected().length > 0? ("Do you want to delete " + getValueIsSelected().length + (getValueIsSelected().length > 1? " items" : " item")): "Please choose at least one item"}}
            </div>
            <div class="modal-footer">
              <div class="row">
                  <div class="col-6 text-right" *ngIf="getValueIsSelected().length > 0">
                      <a role="button" class="btn btn-outline-warning" style="white-space: nowrap;"
                          (click)="clickOk()">
                        {{'SHARED.OK' | translate}}
                      </a>
                  </div>
                  <div class="col-6 text-right">
                      <a role="button" class="btn btn-outline-warning" style="white-space: nowrap;"
                      data-dismiss="modal">
                        {{'SHARED.CANCEL' | translate}}
                      </a>
                  </div>
              </div>
            </div>
        </div>
    </div>
</div>