import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {

  private showParameterCode = LocalStorageService.getItem("showParameterCode");

  private _showParamCodeSubject = new Subject<any>();

  triggerUpdateShowParamCodeEvent() {
    this._showParamCodeSubject.next("showParamCode updated");
  }

  getParamCodeSubjectEvent () {
    return this._showParamCodeSubject;
  }

  enableShowParameterCode(): void {
    this.setShowParameterCode(true);
  }

  disableShowParameterCode(): void {
    this.setShowParameterCode(false);
  }

  setShowParameterCode(value: boolean): void {
    this.showParameterCode = value;
    LocalStorageService.setItem("showParameterCode", this.showParameterCode);
    this.triggerUpdateShowParamCodeEvent();
  }

  getShowParameterCode(): boolean {
    if (this.showParameterCode === null) {
      this.disableShowParameterCode();
    }
    return this.showParameterCode;
  }

}