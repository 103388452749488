import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from '../classes/utility';
import { ParameterRule, ParameterRuleAcceptedValue } from '../classes/parameters';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private standaloneMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(public translate: TranslateService) {}

  getStandaloneMode(): BehaviorSubject<boolean> {
    return this.standaloneMode;
  }

  setStandaloneMode(value: boolean): void {
    this.standaloneMode.next(value);
  }

  getEnableDisableList(): any[] {
    return [
      {'@name': this.translate.instant('SHARED.DISABLE'), '#text': '00'},
      {'@name': this.translate.instant('SHARED.ENABLE'), '#text': '01'}
    ];
  }

  getCodeFieldValue(): any[] {
    return [
      {'@name': this.translate.instant('SHARED.DISABLE'), '#text': '00'},
      {'@name': this.translate.instant('PARAMS.FS'), '#text': '01'},
      {'@name': this.translate.instant('PARAMS.FL'), '#text': '02'},
      {'@name': this.translate.instant('PARAMS.MC'), '#text': '03'},
      {'@name': this.translate.instant('PARAMS.LF'), '#text': '04'}
    ];
  }

  getTransmissionOrderValue(): any[] {
    return [
      {'@name': 'Field1', '#text': '01'},
      {'@name': 'Field2', '#text': '02'},
      {'@name': 'Field3', '#text': '03'},
      {'@name': 'Field4', '#text': '04'},
      {'@name': 'Field5', '#text': '05'},
      {'@name': 'Additional Field 1', '#text': '06'},
      {'@name': 'Additional Field 2', '#text': '07'},
      {'@name': this.translate.instant('PARAMS.TL'), '#text': '08'},
      {'@name': this.translate.instant('PARAMS.EML'), '#text': '09'},
      {'@name': this.translate.instant('PARAMS.EOL'), '#text': '0A'}
    ];
  }

  translateAdvParamToText(param: string): string {
    const lastCodeDigits = param.substring(2).toLowerCase();
    // If contains a number is not Match Code
    const isMatchCodeCondition = !(/\d/.test(lastCodeDigits)) && lastCodeDigits !== 'to' && lastCodeDigits !== 'sf';

    let descr = '';
    let predescr = '';
    if (isMatchCodeCondition) {
      switch (lastCodeDigits) {
        case 'mt':
          descr = 'MCT';
          break;
        case 'ml':
          descr = 'MCL';
          break;
        case 'mc':
          descr = 'MWC';
          break;
        case 'mp':
          descr = 'FCP';
          break;
      }
    } else if (lastCodeDigits === 'to') {
      descr = 'TO';
    } else if (lastCodeDigits === 'sf') {
      descr = 'ASF';
    } else {
      // If last char is number --> Additional fields else --> nth Field Properties
      if (/\d/.test(lastCodeDigits.substring(1))) {
        predescr = lastCodeDigits.substring(1) + ' ';
        descr = 'AF';
      } else {
        switch (lastCodeDigits.substring(1)) {
          case 'c':
            descr = 'DCF';
            break;
          case 'l':
            descr = 'L';
            break;
          case 's':
            descr = 'C';
            break;
          case 't':
            descr = 'I';
            break;
          case 'r':
            descr = 'T';
            break;
        }
      }
    }

    if (descr) {
      return predescr + this.translate.instant('ADVANCED-FORMATTING.' + descr);
    }

    return '';
  }

  translateAdvParamToType(param: string): string {
    const lastCodeDigits = param.substring(2).toLowerCase();
    // If contains a number is not Match Code
    const isMatchCodeCondition = !(/\d/.test(lastCodeDigits)) && lastCodeDigits !== 'to' && lastCodeDigits !== 'sf';

    if (isMatchCodeCondition) {
      switch (lastCodeDigits) {
        case 'mt':
          return 'combo';
        case 'ml':
        case 'mp':
            return 'hexInt';
        case 'mc':
          return 'stringFilled';
      }
    } else if (lastCodeDigits === 'to') {
      return 'stringFilled';
    } else if (lastCodeDigits === 'sf') {
      return 'combo';
    } else {
      // If last char is number --> Additional fields else --> nth Field Properties
      if (/\d/.test(lastCodeDigits.substring(1))) {
        return 'stringFilled';
      } else {
        switch (lastCodeDigits.substring(1)) {
          case 'c':
          case 's':
            return 'combo';
          case 'l':
            return 'hexInt';
          case 't':
            return 'combo';
          case 'r':
            return 'stringFilled';
        }
      }
    }

    return '';
  }

  getAdvParamOptions(param: string, tables: any): any[] {
    const hexTable = [];
    const charList = Helper.hexCharList.slice(1, 255);
    let i = 1;
    for (const char of charList) {
      hexTable.push({ '@name': char, '#text': Helper.intToHex(i).padStart(2, '0')});
      i++;
    }

    const lastCodeDigits = param.substring(2).toLowerCase();
    // If contains a number is not Match Code
    const isMatchCodeCondition = !(/\d/.test(lastCodeDigits)) && lastCodeDigits !== 'to' && lastCodeDigits !== 'sf';

    if (isMatchCodeCondition) {
      switch (lastCodeDigits) {
        case 'mt':
          const table = tables.find(it => it['@name'] === 'CodeTableMuseoCode');
          if (table) {
            return table.element;
          }
          return [];
        case 'ml':
        case 'mp':
            return [];
        case 'mc':
          return hexTable;
      }
    } else if (lastCodeDigits === 'to') {
      return this.getTransmissionOrderValue();
    } else if (lastCodeDigits === 'sf') {
      return this.getEnableDisableList();
    } else {
      // If last char is number --> Additional fields else --> nth Field Properties
      if (/\d/.test(lastCodeDigits.substring(1))) {
        return hexTable;
      } else {
        switch (lastCodeDigits.substring(1)) {
          case 'c':
            return this.getCodeFieldValue();
          case 'l':
            return [];
          case 's':
          case 'r':
            return hexTable;
          case 't':
            return this.getEnableDisableList();
        }
      }
    }

    return [];
  }

  getAdvParamRules(baseName: string, param: string): ParameterRule[] {
    const lastCodeDigits = param.substring(2).toLowerCase();
    // If contains a number is not Match Code
    const isMatchCodeCondition = !(/\d/.test(lastCodeDigits)) && lastCodeDigits !== 'to' && lastCodeDigits !== 'sf';

    const paramName = baseName + '.' + param;
    if (isMatchCodeCondition) {
      if (lastCodeDigits === 'mc') {
        const senderAcceptedValues = [new ParameterRuleAcceptedValue(paramName, '00000000')];
        return [new ParameterRule([paramName], [baseName + '.' + param.substring(0, 2) + 'MP'], 'Disable', 'equalTo', senderAcceptedValues, '')];
      }
    } else {
      // If last char is number --> Additional fields else --> nth Field Properties
      if (!(/\d/.test(lastCodeDigits.substring(1)))) {
        switch (lastCodeDigits.substring(1)) {
          case 'c':
            const advFormatNumber = 'AdvFormat' + param.substring(1, 2);
            const rules = [];
            const allTargets = [
              baseName + '.' + param.substring(0, 3) + 'L',
              baseName + '.' + param.substring(0, 3) + 'S',
              baseName + '.' + param.substring(0, 3) + 'T',
              baseName + '.' + param.substring(0, 3) + 'R',
            ];

            const senderAcceptedValuesL = [new ParameterRuleAcceptedValue(advFormatNumber, '01'), new ParameterRuleAcceptedValue(paramName, '02'), new ParameterRuleAcceptedValue(paramName, '02')];
            rules.push(new ParameterRule([advFormatNumber, paramName], [allTargets[0]], 'Enable',
                                         'multiEqualTo', senderAcceptedValuesL, ''));

            const senderAcceptedValuesS = [new ParameterRuleAcceptedValue(advFormatNumber, '01'), new ParameterRuleAcceptedValue(paramName, '01'), new ParameterRuleAcceptedValue(paramName, '03')];
            rules.push(new ParameterRule([advFormatNumber, paramName], [allTargets[1]], 'Enable',
                                         'multiEqualTo', senderAcceptedValuesS, ''));

            const senderAcceptedValuesT = [new ParameterRuleAcceptedValue(advFormatNumber, '01'), new ParameterRuleAcceptedValue(paramName, '01')];
            rules.push(new ParameterRule([advFormatNumber, paramName], [allTargets[2]], 'Enable',
                                         'multiEqualTo', senderAcceptedValuesT, ''));

            const senderAcceptedValuesR = [new ParameterRuleAcceptedValue(advFormatNumber, '01'), new ParameterRuleAcceptedValue(paramName, '01'), new ParameterRuleAcceptedValue(paramName, '02'), new ParameterRuleAcceptedValue(paramName, '03'), new ParameterRuleAcceptedValue(paramName, '04')];
            rules.push(new ParameterRule([advFormatNumber, paramName], [allTargets[3]], 'Enable',
                                         'multiEqualTo', senderAcceptedValuesR, ''));

            const fieldIndex = Helper.parseIntWithRadix(lastCodeDigits.substring(0, 1));
            if (fieldIndex >= 2) {
              const senderAcceptedValues = [new ParameterRuleAcceptedValue(advFormatNumber, '01'), new ParameterRuleAcceptedValue(paramName, '00')];
              rules.push(new ParameterRule([advFormatNumber, paramName],
                                           [baseName + '.' + param.substring(0, 2) + (fieldIndex + 1) + param.substring(3)],
                                           'Disable', 'multiEqualTo', senderAcceptedValues, ''));
            }

            return rules;
        }
      }
    }

    return [];
  }
}
