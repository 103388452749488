<section id="recent">
  <div class="row">
    <div class="col-12"><h4>{{ 'RECENT.RECENTLY-USED' | translate }}</h4></div>
    <div class="col-12 col-md-6 col-xl-3 recent-item" *ngFor="let product of recentProducts; index as i" (click)="goToProduct(product)">
      <div class="clear-recent-used" (click)="removeRecentUsedItem(i)"></div>
      <div class="recent-item-element">
        <div class="recent-img">
          <img [src]="product.image" class="img-fluid" />
        </div>
        <div class="recent-desc">
          <h6 class="recent-product-name">{{product.description}}</h6>
          <p *ngIf="standaloneMode"><small class="recent-product-connection">{{product.connection}}</small></p>
          <p><small>{{product.date | date:'longDate'}}</small></p>
          <p class="internal-product-name" hidden>{{product.id}}</p>
        </div>
      </div>
    </div>
  </div>
</section>
