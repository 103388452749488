<button type="button" id="AutodetectionButton" class="btn btn-primary" data-toggle="modal"
  data-target="#AutodetectionModal"></button>
<!-- Modal -->
<div class="modal fade" id="AutodetectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">Select Connection to Search Device</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <p class="intro">Scan the Barcode below then please <span>Click to Search</span> device.</p>
        <p class="intro">if you wish to configure a different device, please <span>Select Connection to Search
            Device</span> manually.</p>
        <div class="barcode"><img src="/assets/images/bar.png" /></div>
        <div class="container-fluid">
          <div class="form-group row d-flex align-items-center">
            <div class="col-12 col-form-label text-center">
              <button class="btn btn-outline-warning btn-lg disabled" type="button" id="btnSearchOnServicePort">Click to Search</button>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-12">
              <label>Select Connection to search device</label>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>Connection</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectCOMPort">
                <option value="">COM1</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
              </select>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>baud rate</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectBaudrate">
                <option value="9600">9600</option>
                <option value="5000">5000</option>
                <option value="1000">1000</option>
              </select>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>parity</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectParity">
                <option value="n">N</option>
                <option value="s">S</option>
                <option value="x">X</option>
              </select>
            </div>
          </div>

          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>data bits</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectDatabit">
                <option value="8">8</option>
                <option value="16">16</option>
                <option value="32">32</option>
              </select>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>stop bits</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectStopbit">
                <option value="1">1</option>
                <option value="4">4</option>
              </select>
            </div>
          </div>

          <div class="form-group row d-flex align-items-center">
            <div class="offset-4 col-8">
              <button class="btn btn-outline-warning btn-lg" type="button" id="btnRefreshComPort">Refresh COM Port</button>
              <button type="button" class="btn btn-outline-warning btn-lg " id="btnSearchOnSelectedPort">Search on Connection</button>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-12">
              <label>Select Ethernet Connection to search device</label>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>Host</label>
            </div>
            <div class="col-8">
              <select class="custom-select" required="" id="selectIPHost">
                <option value="1"></option>
                <option value="4"></option>
              </select>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="offset-4 col-8">
              <button type="button" class="btn btn-outline-warning btn-lg " id="btnSearchOnSelectedHost">Search on Connection</button>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-12">
              <label>Select on Ethernet Connection</label>
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="col-4 col-form-label text-right">
              <label>Host</label>
            </div>
            <div class="col-8">
              <input class="form-control" type="text" placeholder="" id="inputIPHost">
            </div>
          </div>
          <div class="form-group row d-flex align-items-center">
            <div class="offset-4 col-8">
              <button type="button" class="btn btn-outline-warning btn-lg disabled" id="btnSearchOnCustomHost">Search on Connection</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
