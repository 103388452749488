<button type="button" id="sendFeedbackButton" class="btn btn-primary" data-toggle="modal" data-target="#sendFeedbackModal"></button>

<div class="modal fade" id="sendFeedbackModal" tabindex="-1" role="dialog" aria-labelledby="sendFeedbackLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="sendFeedbackLabel">{{'SEND-FEEDBACK.TITLE' | translate}}</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="send-feedback-content" [innerHTML]="'SEND-FEEDBACK.CONTENT' | translate:{email:feedbackEmail,subject:emailSubject}">
    </div>
  </div>
</div>
