<button type="button" id="exportCCFOptionsButton" class="btn btn-primary" data-toggle="modal" data-target="#exportCCFOptionsModal"></button>

<div class="modal fade" id="exportCCFOptionsModal" tabindex="-1" role="dialog" aria-labelledby="exportCCFOptionsLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exportCCFOptionsLabel">{{'Export CCF Options'}}</h5>

        <button type="button" id="closeExportCCFOptionsButton" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <div class="container-fluid">		
          <div class="form-group row align-items-center">
          <div class="col-4">
            <label id ="labelCustomerName" for="inputCustomerName">Customer Name:</label>
          </div>
          <div class="col-8">
            <input #inputCustomerName type="text" class="form-control" id="inputCustomerName">
          </div>
          </div>
          <div class="form-group row align-items-center">
          <div class="col-4">
            <label id="labelConfigName" for="inputConfigName">Config Name:</label>
          </div>
          <div class="col-8">
            <input #inputConfigName type="text" class="form-control" id="inputConfigName">
          </div>
          </div>
          <div class="form-group row align-items-center" [hidden]="!secureUser">
            <div class="col-4">
              <label id ="labelCopyTo">Copy to User/Factory:</label>
            </div>
            <div class="col-8">
              <div class="dropdown show">
                <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" 
                aria-haspopup="true" aria-expanded="false">{{selectedCopyToOption}}</a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a (click)="selectOptionValue(option)" class="dropdown-item"(click)="selectOptionValue(option)"
                  *ngFor="let option of copyToOptions">{{option}}</a>
                </div>
              </div>
            </div>
          </div>
        <div class="btn btn-outline-warning btn-sm" (click)="confirm()"> Confirm </div>
      </div>
    </div>
  </div>
</div>
