export class ParamMenuItem {
  public name: string;
  public title: string;
  public englishTitle: string;
  public tocId: string;
  public children: ParamMenuItem[];

  constructor(name: string, title: string, englishTitle: string, tocId: string, children: ParamMenuItem[]) {

    this.name = name;
    this.title = title.trim();
    this.englishTitle = englishTitle;
    this.tocId = tocId;
    this.children = children;
  }

  getChildren(): object[] {
    return this.processChildren(this.children);
  }

  private processChildren(items): object[] {
    const array = [];
    for (const child of items) {
      array.push({
        id: child.name,
        name: child.title,
        children: this.processChildren(child.children)
      });
    }

    return array;
  }
}
