import { Component, OnInit, ViewChild, ElementRef, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter } from '@angular/core';
import * as ace from 'ace-builds';

import 'ace-builds/src-noconflict/mode-assembly_x86';
import 'ace-builds/src-noconflict/mode-crystal';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';

@Component({
  selector: 'app-code-editor',
  templateUrl: './code-editor.component.html',
  styleUrls: ['./code-editor.component.css']
})
export class CodeEditorComponent implements AfterViewInit, OnChanges {
  @Input() type = '';
  @Input() text = '';
  @Input() title = '';
  @Output() valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('codeEditor') codeEditorElmRef: ElementRef;

  private codeEditor: any;

  constructor() { }

  ngAfterViewInit(): void {
    this.refreshEditor();
    this.refreshText();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type && changes.type.currentValue !== changes.type.previousValue) {
      this.refreshEditor();
    }

    let codeEditorCode = '';
    if (this.codeEditor) {
      codeEditorCode = this.codeEditor.getSession().getValue();
    }
    if (changes.text && changes.text.currentValue !== codeEditorCode) {
      this.refreshText();
    }
  }

  private refreshEditor(): void {
    if (!this.codeEditorElmRef) {
      return;
    }

    const element = this.codeEditorElmRef.nativeElement;

    const options = {
      minLines: 30,
      maxLines: 30,
      highlightSelectedWord: true,
      showPrintMargin: false
    };

    this.codeEditor = ace.edit(element, options);
    this.codeEditor.setTheme('ace/theme/github');
    if (this.title === 'User Scripting JS') {
      this.codeEditor.getSession().setMode('ace/mode/javascript');
    } else if (this.type === 'ule') {
      this.codeEditor.getSession().setMode('ace/mode/crystal');
    } else {
      this.codeEditor.getSession().setMode('ace/mode/assembly_x86');
    }
    this.codeEditor.setShowFoldWidgets(true);
    this.codeEditor.getSession().on('change', () => {
      this.valueChanged.emit(this.codeEditor.getSession().getValue());
    });
  }

  private refreshText(): void {
    if (!this.codeEditorElmRef) {
      return;
    }

    if (this.codeEditor) {
      this.codeEditor.getSession().setValue(this.text, -1);
    } else {
      this.codeEditorElmRef.nativeElement.innerHTML = this.text;
    }
  }
}
