/**
 * @module text
 * @overview Text element class
 * @author lilliputten <igor@lilliputten.ru>
 */
var Element = require('./element'),
    imageinfo = require('imageinfo'),
    inherit = require('inherit')
;

var ImageElement = inherit(Element, {

    /** __constructor ** {{{ */
    __constructor : function(base64String, dpiX, dpiY){
        this.__base.call(this, undefined);
        this.base64String=base64String;

        if (!dpiX)
            this.dpiX=300;
        else
            this.dpiX=dpiX;

        if (!dpiY)
            this.dpiY=300;
        else
            this.dpiY=dpiY;
    },/*}}}*/

    /** getRTFCode ** {{{
     * @param {Object} jsRTF
     */
    getRTFCode : function(jsRTF){
        var self = this;

        var binary_string = atob(self.base64String);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        var bufSize = bytes.length;

        //get image information and calculate twips based on DPI
        var info = imageinfo(bytes);
        var twipRatioX = (72/self.dpiX) * 20;
        var twipRatioY = (72/self.dpiY) * 20;
        var twipWidth = Math.round(info.width * twipRatioX),
            twipHeight = Math.round(info.height * twipRatioY);

        //output image information
        var output = '\{\\pict\\picw' + info.width + '\\pich' + info.height +
                     '\\picwgoal' + twipWidth + '\\pichgoal' + twipHeight + '\\pngblip ';

        //output buffer to base16 (hex) and ensure prefixed 0 if single digit
        for(var i = 0; i < bufSize; i++){
            var hex = bytes[i].toString(16);
            output += (hex.length === 2) ? hex : '0' + hex;
        }

        output += '\}';
        return output;
    },/*}}}*/

});

module.exports = ImageElement;
