<button type="button" id="forcedeviceconnectionButton" class="btn btn-primary" data-toggle="modal" data-target="#forcedeviceconnectionModal"></button>
<div class="modal fade" id="forcedeviceconnectionModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Force Device Connection</h5>
           <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <p class="intro">For USB Devices, reconnect the USB reader NOW while pressing the trigger<br/> then wait for the <span>USB-HID</span> connect to appear</p>
              </div>
            </div>
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Delete Configuration</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" value="" id="checkEraseConfig">
                </div>
            </div>    
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Port Connection</label>
                </div>
                <div class="col-8">
                    <select class="custom-select" id="selectCOMPort" required="">
                        <option value="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                   
                </div>
            </div>  
            <div class="form-group row d-flex align-items-center">
                 
                <div class="offset-4 col-8">
                    <button type="button" class="btn btn-outline-warning btn-lg" id="btnConnectToDevice">Connect device</button>
               </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
           <!--<button type="button" class="btn btn-primary">Save changes</button>--> 
          </div>
      </div>
    </div>
  </div>
  