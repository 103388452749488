<div style="font-size: 1.5em; padding-bottom: 0.5em;">{{title}}</div>
 <div>
    <table [attr.data-command]="code" class="statisticPageData table table-striped table-bordered" style="width:100%">
        <thead>
            <tr>
                <th>{{'SMART-BATTERY.PROPERTIES' | translate}}</th>
                <th>{{'SMART-BATTERY.VALUE' | translate}}</th>
            </tr>
        </thead>
        <tbody  *ngFor="let item of values" >
            <tr *ngIf="item[0] === code" >
                <td>{{item[1]}}</td>
                <td>{{item[2]}}</td>
            </tr>
        </tbody>
    </table>
</div>