export class ParameterOption {
  public name: string;
  public value: string;
  public command: string;

  constructor(name: string, value: string, command: string) {
    this.name = name;
    this.value = value;
    this.command = command;
  }
}
