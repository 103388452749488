import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-autodetection',
  templateUrl: './autodetection.component.html',
  styleUrls: ['./autodetection.component.css']
})
export class AutodetectionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  show() {
    const element: HTMLElement = document.getElementById('AutodetectionButton');
    element.click();
  }
}
