import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { protectedResources } from 'src/app/auth-config';

@Injectable({
  providedIn: 'root'
})
export abstract class GeneralCloudService {

  constructor(protected http: HttpClient, protected authService: MsalService) { }

  getToken(): Observable<HttpHeaders>{
    let tokenRequest = {
      scopes: [...protectedResources.apiTodoList.scopes.read],
    };
    return from(this.authService.instance.acquireTokenSilent(tokenRequest)).pipe(
      map((value: any) => {
        let bearer = "Bearer " + value?.accessToken;
        let httpHeaders = new HttpHeaders()
          .set('Authorization', bearer)

        return httpHeaders;
        })
    );
  }
}
