import { Component, ViewChild, ViewContainerRef, NgModule, Compiler, Injector,
         NgModuleRef, OnChanges, SimpleChanges, Input, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-dynamic-html',
  templateUrl: './dynamic-html.component.html',
  styleUrls: ['./dynamic-html.component.css']
})
export class DynamicHtmlComponent implements OnChanges {
  @ViewChild('content', { read: ViewContainerRef }) content: ViewContainerRef;
  @Input() html: string;
  @Output() eventRaised = new EventEmitter<any>();

  constructor(private compiler: Compiler, private injector: Injector, private moduleRef: NgModuleRef<any>) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.html.currentValue !== changes.html.previousValue) {
      const that = this;
      class Bridge {
        raiseEvent(eventType: string, eventArgs: any) {
          that.raiseEvent(eventType, eventArgs);
        }
      }

      const html = this.parseHtml(changes.html.currentValue);
      this.createComponentFromRaw(Bridge, html);
    }
  }

  raiseEvent(eventType: string, eventArgs: any) {
    this.eventRaised.emit({
      type: eventType,
      args: eventArgs
    });
  }

  // Here we create the component.
  private createComponentFromRaw(klass: any, template: string, styles = null) {
    if (this.content) {
      this.content.clear();
    }
    // Now we create a new component. It has that template, and we can even give it data.
    const tmpCmp2: any = Component({ template, styles })(new klass().constructor);

    // Now, also create a dynamic module.
    const tmpModule = NgModule({
        imports: [CommonModule, RouterModule],
        declarations: [tmpCmp2],
        // providers: [] - e.g. if your dynamic component needs any service, provide it here.
    })(class { });

    // Now compile this module and component, and inject it into that #vc in your current component template.
    this.compiler.compileModuleAndAllComponentsAsync(tmpModule)
    .then((factories) => {
      const f = factories.componentFactories[factories.componentFactories.length - 1];
      const cmpRef = f.create(this.injector, [], undefined, this.moduleRef);
      cmpRef.instance.name = 'app-dynamic';
      this.content.insert(cmpRef.hostView);
    });
  }

  private parseHtml(html: string): string {
    let result = html;
    const hrefContentRegex = /href="(.*?)"/ig;
    let regexResult = hrefContentRegex.exec(html);

    while (regexResult !== null) {
      let parsedResult = regexResult[0].replace(/href=/g, '');
      parsedResult = '\"raiseEvent(\'click\', ' + parsedResult.replace(/"/g, '\'') + ')\"';
      parsedResult = 'class=\'link-orig\' (click)=' + parsedResult;

      result = result.replace(regexResult[0], parsedResult);

      regexResult = hrefContentRegex.exec(html);
    }

    return result;
  }
}
