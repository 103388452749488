<div class="dropdown show">
  <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
  aria-haspopup="true" aria-expanded="false">
    {{selectedOption?.text}}
  </a>
  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
    <a class="dropdown-item"
      [ngClass]="{'active': option.value === selectedOption.value}"
      (click)="selectValue(option)"
      *ngFor="let option of values">
      {{option.text}}
    </a>
  </div>
</div>
