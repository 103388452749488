<!-- <footer class="container-fluid fixed-bottom py-4"> -->
<app-news *ngIf="newsVisible"></app-news>
<footer class="container-fluid">
  <div class="row">
    <div class="col-10 offset-1">
      <a class="float-left d-inline mr-5" href="https://www.datalogic.com/" target="_blank">
        <img src="assets/images/logo-footer.png" class="img-fluid" />
      </a>
      <p>
        Copyright 2020 - All rights reserved  | <a href="#">{{'FOOTER.LEGAL-NOTICE' | translate}}</a> |
        <a href="#">{{'FOOTER.PRIVACY-POLICY' | translate}}</a> |
        <a href="#">{{'FOOTER.TERMS-AND-CONDITIONS' | translate}}</a> | <a href="#">{{'FOOTER.PATENTS' | translate}}</a>
      </p>
    </div>
  </div>
</footer>
