import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {

  constructor() { }
  public navbarElement: HTMLElement;
  public footerElement: HTMLElement;
}
