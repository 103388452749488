import { Component, OnInit } from '@angular/core';
import { Helper } from 'src/app/classes/utility';
@Component({
  selector: 'app-force-device-connection',
  templateUrl: './force-device-connection.component.html',
  styleUrls: ['./force-device-connection.component.css']
})
export class ForceDeviceConnectionComponent implements OnInit {

  constructor() { }
  developerMode = Helper.developerModeEnabled;
  ngOnInit(): void {
  }

  show() {
    const element: HTMLElement = document.getElementById('forcedeviceconnectionButton');
    element.click();
  }
}
