
<table id="smartBatteryData" [attr.data-command]="code" class="table table-striped table-bordered" style="width:100%">
    <thead>
        <tr>
            <th>{{'SMART-BATTERY.PROPERTIES' | translate}}</th>
            <th>{{'SMART-BATTERY.VALUE' | translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr
        *ngFor="let item of values">
            <td>{{item.name}}</td>
            <td>{{item.value}}</td>
        </tr>
    </tbody>
</table>
