import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-bootstrap-combo',
  templateUrl: './bootstrap-combo.component.html',
  styleUrls: ['./bootstrap-combo.component.css']
})
export class BootstrapComboComponent implements OnInit {
  @Input() defaultValue: any;
  @Input() values: any[] = [];
  @Output() valueChanged: EventEmitter<any> = new EventEmitter<string>();
  selectedOption: any;

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectedOption = this.defaultValue;
    }
  }

  ngOnChanges(): void {
    if (this.defaultValue) {
      this.selectedOption = this.defaultValue;
    }
  }

  selectValue(option: any) {
    this.selectedOption = option;
    this.valueChanged.emit(option);
  }
}
