<button type="button" id="firmwareUpgradeButton" class="btn btn-primary" data-toggle="modal" data-target="#firmwareUpgradeModal"></button>
<!-- Modal -->
<div class="modal fade" id="firmwareUpgradeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Firmware upgrade</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body clearfix">
           <div class="container-fluid">
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <button class="btn btn-warning" type="button" id="btnFWUSearchFirmware">Search Firmware</button>
                </div>
                <div class="col-8">
                    <input class="form-control" type="text" id="inputFWUSearchFirmware" placeholder="Plase input a hash code here...">
                </div>
            </div>
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <button class="btn btn-warning" type="button" id="btnFWULoadDFWFile">Load firmware file</button>
                </div>
                <div class="col-8">
                    <input class="form-control" type="text" id="inputFWULoadDFWFile" placeholder="Plase choose a firmware file...">
                </div>
            </div>    
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Device</label>
                </div>
                <div class="col-8">
                    <label id="lblFWUDevice">-</label>
                </div>
            </div>    
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>SW Release</label>
                </div>
                <div class="col-8">
                    <label id="lblFWUSwRelease">-</label>
                </div>
            </div> 
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Boot Version</label>
                </div>
                <div class="col-8">
                    <label id="lblFWUBootVersion">-</label>
                </div>
            </div> 
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>PID</label>
                </div>
                <div class="col-8">
                    <label id="lblFWUPID">-</label>
                </div>
            </div>    
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Check PID</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" id="inputFWUCheckPID" value="">
                </div>
            </div>    
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Upgrade Application</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" id="inputFWUCheckUpgradeApp" value="">
                </div>
            </div>    
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Upgrade Boot</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" id="inputFWUCheckUpgradeBoot" value="">
                </div>
            </div>    
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Upgrade Eth. Mod. Application</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" id="inputFWUCheckUpgradeAppEth" value="">
                </div>
            </div>    
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Upgrade Eth. Mod. Backup</label>
                </div>
                <div class="col-8">
                    <input type="checkbox" id="inputFWUCheckUpgradeBackupEth"value="">
                </div>
            </div>   
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Port Connection</label>
                </div>
                <div class="col-8">
                    <select class="custom-select"  id="selectFWUPort" required="">
                        <option value="">Select Connection</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                   
                </div>
            </div>    
            <div class="form-group row d-flex align-items-center">
                 
                <div class="offset-4 col-8">
                    <button type="button" class="btn btn-outline-warning btn-lg " id="btnFWUUpgradeViaUSB">Firmware Upgrade with a USB cable</button>
               </div>
            </div>
            <div class="form-group row d-flex align-items-center">
               
                <div class="offset-4 col-8">
                    <button type="button" class="btn btn-outline-warning btn-lg disabled" id="btnFWUUpgradeViaRS232STD">Firmware Upgrade with a RS232 device with an RS232 cable</button>
               </div>
            </div>
            <div class="form-group row d-flex align-items-center">
                
                <div class="offset-4 col-8">
                    <button type="button" class="btn btn-outline-warning btn-lg disabled" id="btnFWUUpgradeViaRS232NonSTD">Firmware Upgrade with a Not-RS232 device with an RS232 cable</button>    
               </div>
            </div>
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Host</label>
                </div>
                <div class="col-8">
                    <select class="custom-select" id="selectFWUHost" disabled required="">
                        <option value="">Select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                   
                </div>
            </div>    
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Host</label>
                </div>
                <div class="col-8">
                    <input class="form-control" id="inputFWUHost" disabled type="text" placeholder="">
                   
                </div>
            </div>
            <div class="form-group row d-flex align-items-center">                
                <div class="offset-4 col-8">
                    <button type="button" id="btnFWUUpgradeViaEthernet" class="btn btn-outline-warning btn-lg disabled">Firmware Upgrade on Ethernet Connection</button>    
               </div>
            </div>
            <div *ngIf="developerMode" class="form-group row d-flex align-items-center">
                
                <div class="offset-4 col-8">
                    <button type="button" id="btnFWURemoteUpgradeViaEthernet" class="btn btn-outline-warning btn-lg disabled">Remote Firmware Upgrade on Ethernet Connection</button>    
               </div>
            </div>
            
        </div>    
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" id="btnFWUClose" data-dismiss="modal">Close</button>
         <!--<button type="button" class="btn btn-primary">Save changes</button>--> 
        </div>
      </div>
    </div>
</div>
