import { TranslateService } from '@ngx-translate/core';
import { Parameter } from '../parameters';
import { CustomManualItem } from './custom-manual-item';

export class Command {

  constructor(text: string, description: string, protection: string, isPackCommand: boolean, family: string) {
    this.text = text;
    this.protection = protection;
    this.isPackCommand = isPackCommand;
    this.description = description;
    this.family = family;
  }
  public text: string;
  public description: string;
  public protection: string;
  public isPackCommand: boolean;
  public family: string;

  static fromXml(node, translateService: TranslateService): Command {
    let descr = node['@title'];
    if (descr === 'ENTER_CONFIG' || descr === 'EXIT_CONFIG') {
      descr = translateService.instant('PARAMS.' + descr);
    }

    const obj = new this(
      node['@value'].replace(/\\\\r/g,"\r").replace(/\\r/g,"\r"),
      descr,
      node['@protection'],
      false,
      ''
    );

    return obj;
  }

  static fromParameter(param: Parameter, translateService: TranslateService, valuef: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): Command {
    let descr = param.getDescriptionWithValue();
    let family = valuef;
    if (descr === 'ENTER_CONFIG' || descr === 'EXIT_CONFIG') {
      descr = translateService.instant('PARAMS.' + descr);
    }

    const obj = new this(
      param.getCommand(family, preCmd, enterConfigurationCmd, exitConfigurationCmd),
      descr,
      param.getCommandForDevice(),
      param.isPackParameter,
      family
    );

    return obj;
  }

  static fromCustomManualItem(item: CustomManualItem, translateService: TranslateService, valuef:string): Command {
    let descr = item.description;
    let family = valuef;
    if (descr === 'ENTER_CONFIG' || descr === 'EXIT_CONFIG') {
      descr = translateService.instant('PARAMS.' + descr);
    }

    const obj = new this(
      item.value,
      descr,
      '',
      false,
      family
    );

    return obj;
  }

  getDisplayValue(type: string, removeProgrammingMode: boolean = false, enterConfigurationCmd: string): string {
    return (type === 'code128' ? '<FNC3>' : '~3') + this.getValue(removeProgrammingMode, enterConfigurationCmd).replace(/\r/g,"<CR>") + '<CR>';
  }

  getValue(removeProgrammingMode: boolean = false, enterConfigurationCmd: string): string {
    let value = this.text.replace('\\n', '');
    if (removeProgrammingMode) {

      if(this.family == 'FRS')
      {
        value = value.substring(6, value.length - 6);
      }
      else
      { 
        value = value.substring(0, value.length - 2);
        value = value.replace('$' + enterConfigurationCmd + ',', '$');
      }
    }

    return value;
  }
}
