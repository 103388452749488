import { LanguageService } from "src/app/services/language.service";

export class Message {
  public name: string;
  public description: string;
  public header: string;
  public command: string;
  public readBack: boolean;

  constructor(name: string, description: string, header: string, command: string, readBack: boolean) {
    this.name = name;
    this.description = description;
    this.header = header;
    this.command = command;
    this.readBack = readBack;
  }

  static fromXml(element, languageService: LanguageService): Message {
    const obj = new this(
      element['@name'],
      languageService.getProductString(element.context),
      element['@header'],
      element['@terminator'],
      element['@readBack']
    );

    return obj;
  }
  
  public getCommand(family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): string {
    let commandInMessage = "";
    if(this.command === "$AD" || this.command.startsWith("$AD,")) {
      commandInMessage = "$AD";
    } else {
      commandInMessage = this.command;
    }

    if (commandInMessage.indexOf('$') >= 0) {
      return '$' + enterConfigurationCmd + ',' + commandInMessage.replace('$', '') + ',' + exitConfigurationCmd;
    }
    let head = '$' + enterConfigurationCmd + ',' + preCmd;
    let tail = ',' + exitConfigurationCmd;
    if (family == 'FRS') {
      head = '00000\r';
      tail = '\r00000';
    }
    return head + commandInMessage + tail;
  }
}
