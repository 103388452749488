import { Parameter } from '../parameters';
import { Message } from '../utility';

export class CustomManualItem {
  constructor(product: string, description: string, value: string, paramater: Parameter = null) {
    this.product = product;
    this.description = description;
    this.value = value;
    this.param = paramater;
  }
  public product: string;
  public description: string;
  public value: string;
  public param: Parameter;

  static fromParameter(product: string, param: Parameter, family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): CustomManualItem {
    const obj = new this(
      product,
      param.getDescriptionWithValue(),
      param.getCommand(family, preCmd, enterConfigurationCmd, exitConfigurationCmd),
      param
    );

    return obj;
  }

  static fromMessage(product: string, message: Message, family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): CustomManualItem {
    const obj = new this(
      product,
      message.description,
      message.getCommand(family, preCmd, enterConfigurationCmd, exitConfigurationCmd)
    );

    return obj;
  }

  getDisplayValue(type: string): string {
    return (type === 'code128' ? '<FNC3>' : '~3') + this.getValue() + '<CR>';
  }

  getValue(): string {
    return this.value.replace('\\\\n', '');
  }
}
