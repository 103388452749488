<button type="button" id="jingleButton" class="btn btn-primary" data-toggle="modal" data-target="#jingleModal"></button>
<!-- Modal -->
<div class="modal fade" id="jingleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Jingle</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body clearfix">
            <div class="container-fluid">
                <div class="form-group row d-flex align-items-center">
                    <div class="col-8">
                        <input class="form-control" type="text" placeholder="">
                      </div>
                    <div class="col-4 col-form-label text-left">
                      <button class="btn btn-warning" type="button">Browse</button>
                    </div>
                    
                </div>
                <div class="form-group row d-flex align-items-center">
                    <div class="col-12 col-form-label text-left">
                        <button style="margin-right:10px;" type="button" class="btn btn-outline-warning disabled">Pre Hear</button> <button type="button" class="btn btn-outline-warning disabled">Download</button>
                    </div>
                </div>
                <div class="form-group row d-flex align-items-center">
                    <div class="col-12 col-form-label text-left">
                        <div class="btn-group" role="group" aria-label="Basic example">
                            <button type="button" class="btn btn-warning">Play</button>
                            <button type="button" class="btn btn-warning">Delete</button>
                            <button type="button" class="btn btn-warning">Format</button>
                          
                          </div>
                          <label style="margin-left:15px;">Free Space: 1,572,864 bytes</label>
                       
                    </div>
                </div>
                <div class="table-files row">
                      <div class="col-1">
                        <span class="th">N.</span>
                      </div>
                      <div class="col-8">
                        <span class="th">File</span>
                      </div>
                      <div class="col-3">
                        <span class="th">Size</span>
                      </div>
                    </div>
                    <div class="table-files row  active">
                      <div class="col-1"><span class="td">1</span></div>
                      <div class="col-8">
                        <span class="td">file1Name.wav</span>
                      </div>
                      <div class="col-3">
                        <span class="td">8,296,899 bytes</span>
                      </div>
                    </div>
                    <div class="table-files row">
                        <div class="col-1"><span class="td">2</span></div>
                        <div class="col-8">
                          <span class="td">file2Name.wav</span>
                        </div>
                        <div class="col-3">
                          <span class="td">34,296,899 bytes</span>
                        </div>
                      </div>
                      <div class="table-files row">
                        <div class="col-1"><span class="td">3</span></div>
                        <div class="col-8">
                          <span class="td">file3Name.wav</span>
                        </div>
                        <div class="col-3">
                          <span class="td">8,296,899 bytes</span>
                        </div>
                      </div>
                      <div class="table-files row">
                          <div class="col-1"><span class="td">4</span></div>
                          <div class="col-8">
                            <span class="td">file4Name.wav</span>
                          </div>
                          <div class="col-3">
                            <span class="td">9,296,899 bytes</span>
                          </div>
                      </div>
                      <div class="row">
                          <div class="col-12">
                              <label id="soundselected">Sound Selected: 1 - file1Name.wav</label>
                          </div>
                      </div>
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set Good Read</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning">Play</button>
                                <button type="button" class="btn btn-warning">Clear</button>
                            </div>
                            <label style="margin-left:15px;">1 - file1Name.wav</label>
                        </div>
                      </div>
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set Power Up</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning">Play</button>
                                <button type="button" class="btn btn-warning">Clear</button>
                            </div>
                            <label style="margin-left:15px;">1 - file2Name.wav</label>
                        </div>
                      </div> 
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set Enter Stand Mode</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning disabled">Play</button>
                                <button type="button" class="btn btn-warning disabled">Clear</button>
                            </div>
                            <label style="margin-left:15px;"> - </label>
                        </div>
                      </div>
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set Exit Stand Mode</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning disabled">Play</button>
                                <button type="button" class="btn btn-warning disabled">Clear</button>
                            </div>
                            <label style="margin-left:15px;"> - </label>
                        </div>
                      </div>
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set Transmit Error</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning disabled">Play</button>
                                <button type="button" class="btn btn-warning disabled">Clear</button>
                            </div>
                            <label style="margin-left:15px;"> - </label>
                        </div>
                      </div>
                      <div class="form-group row d-flex align-items-center">

                        <div class="col-12 col-md-4">
                          <button type="button" class="btn btn-outline-warning btn-lg ">Set ULE</button>
                        </div>
                        <div class="col-12 col-md-8">
                            <div class="btn-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-warning disabled">Play</button>
                                <button type="button" class="btn btn-warning disabled">Clear</button>
                            </div>
                            <label style="margin-left:15px;"> - </label>
                        </div>
                      </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">Close</button>
         <!--<button type="button" class="btn btn-primary">Save changes</button>--> 
        </div>
      </div>
    </div>
</div>

