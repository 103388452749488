import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-img-link',
  templateUrl: './img-link.component.html',
  styleUrls: ['./img-link.component.css'],
})
export class ImgLinkComponent implements OnInit {
  @Input() id = 0;
  @Input() title = '';
  @Input() image = '';
  @Input() link = '';
  @Input() isActive = false;
  @Input() isUserConfig: boolean = false;
  @Output() removeEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}
  viewDetail(id){
  }
  
  remove() {
    this.removeEvent.emit("");
  }
}
