import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TreeModule } from 'angular-tree-component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { CategoryComponent } from './pages/category/category.component';
import { ProductComponent } from './pages/product/product.component';
import { HeaderComponent } from './shared/header/header.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RecentComponent } from './shared/recent/recent.component';
import { NewsComponent } from './shared/news/news.component';
import { ImgLinkComponent } from './shared/img-link/img-link.component';
import { ToolbarComponent } from './shared/toolbar/toolbar.component';
import { ParamSectionComponent } from './shared/param-section/param-section.component';
import { ParamEditorComponent } from './shared/param-editor/param-editor.component';
import { StringFilledEditorComponent } from './shared/string-filled-editor/string-filled-editor.component';
import { BarcodeComponent } from './shared/barcode/barcode.component';
import { DriverLicenseEditorComponent } from './shared/driver-license-editor/driver-license-editor.component';
import { FirmwareUpgradeComponent } from './shared/firmware-upgrade/firmware-upgrade.component';
import { ImageCaptureComponent } from './shared/image-capture/image-capture.component';
import { ForceDeviceConnectionComponent } from './shared/force-device-connection/force-device-connection.component';
import { DynamicHtmlComponent } from './shared/dynamic-html/dynamic-html.component';
import { CustomManualComponent } from './shared/custom-manual/custom-manual.component';
import { PdfPreviewModalComponent } from './shared/pdf-preview-modal/pdf-preview-modal.component';
import { PdfContentComponent } from './shared/pdf-content/pdf-content.component';
import { SmartBatteryComponent } from './shared/smart-battery/smart-battery.component';
import { CodeEditorComponent } from './shared/code-editor/code-editor.component';
import { ReleaseComboPipe } from './pipes/release-combo.pipe';
import { TerminalComponent } from './shared/terminal/terminal.component';
import { AutodetectionComponent } from './shared/autodetection/autodetection.component';
import { CompareConfigurationComponent } from './shared/compare-configuration/compare-configuration.component';
import { JingleComponent } from './shared/jingle/jingle.component';
import { LanguageSelectionComponent } from './shared/language-selection/language-selection.component';
import { SendFeedbackComponent } from './shared/send-feedback/send-feedback.component';
import { ExportCCFOptionsComponent } from './shared/export-ccf-options/export-ccf-options.component';
import { BootstrapComboComponent } from './shared/bootstrap-combo/bootstrap-combo.component';
import { StatisticPageComponent } from './shared/statistic-page/statistic-page.component';
import { MessageEditorComponent } from './shared/message-editor/message-editor.component';
import { LoadingWindowComponent } from './shared/loading-window/loading-window.component';
import { ReadableAsciiComponent } from './shared/readable-ascii/readable-ascii.component';
import { CertificateEditorComponent } from './shared/certificate-editor/certificate-editor.component';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
    MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes
} from '@azure/msal-angular';

import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { CloudSaveConfigComponent } from './shared/cloud-save-config/cloud-save-config.component';
import { AlternativeProductsModalComponent } from './shared/alternative-products-modal/alternative-products-modal.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { LoadingBarComponent } from './shared/loading-bar/loading-bar.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
* MSAL Angular will automatically retrieve tokens for resources
* added to protectedResourceMap. For more info, visit:
* https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
*/
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

  protectedResourceMap.set(protectedResources.apiTodoList.endpoint, [
      {
          httpMethod: 'GET',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
          httpMethod: 'POST',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
          httpMethod: 'PUT',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      },
      {
          httpMethod: 'DELETE',
          scopes: [...protectedResources.apiTodoList.scopes.read]
      }
  ]);

  return {
      interactionType: InteractionType.Popup,
      protectedResourceMap,
  };
}

/**
* Set your default interaction type for MSALGuard here. If you have any
* additional scopes you want the user to consent upon login, add them here as well.
*/
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
      interactionType: InteractionType.Redirect,
      authRequest: loginRequest
  };
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CategoryComponent,
    ProductComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    RecentComponent,
    NewsComponent,
    ImgLinkComponent,
    ToolbarComponent,
    ParamSectionComponent,
    ParamEditorComponent,
    StringFilledEditorComponent,
    BarcodeComponent,
    DriverLicenseEditorComponent,
    FirmwareUpgradeComponent,
    ImageCaptureComponent,
    ForceDeviceConnectionComponent,
    ReleaseComboPipe,
    DynamicHtmlComponent,
    CustomManualComponent,
    PdfPreviewModalComponent,
    PdfContentComponent,
    SmartBatteryComponent,
    CodeEditorComponent,
    TerminalComponent,
    AutodetectionComponent,
    CompareConfigurationComponent,
    JingleComponent,
    LanguageSelectionComponent,
    SendFeedbackComponent,
    ExportCCFOptionsComponent,
    BootstrapComboComponent,
    StatisticPageComponent,
    MessageEditorComponent,
    LoadingWindowComponent,
    ReadableAsciiComponent,
    CertificateEditorComponent,
    CloudSaveConfigComponent,
    AlternativeProductsModalComponent,
    NotificationComponent,
    LoadingBarComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    TreeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true
    },
    {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
    },
    {
        provide: MSAL_GUARD_CONFIG,
        useFactory: MSALGuardConfigFactory
    },
    {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
