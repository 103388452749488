import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-window',
  templateUrl: './loading-window.component.html',
  styleUrls: ['./loading-window.component.css']
})
export class LoadingWindowComponent implements OnInit {
  title: string = "Loading";
  mode: string = "Aladdin Application";
  constructor() { }

  ngOnInit(): void {
  }

  show(title: string, mode: string): void {
    this.title = title;
    this.mode = mode;
    const element: HTMLElement = document.getElementById('loadingWindowButton');
    if (element) {
      element.click();
    }
  }
  
  hide(): void {
    const element: HTMLElement = document.getElementById('loadingWindowClose');
    if (element) {
      element.click();
    }
  }
}
