import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Message } from 'src/app/classes/utility';
import { CustomManualService } from 'src/app/services/custom-manual.service';

@Component({
  selector: 'app-message-editor',
  templateUrl: './message-editor.component.html',
  styleUrls: ['./message-editor.component.css']
})
export class MessageEditorComponent implements OnInit {

  @Input() customManualEnabled = false;
  @Input() message: Message;
  @Input() productName = '';
  @Input() isOnline = false;
  @Output() toggleFromCustomManualWithMessage: EventEmitter<Message> = new EventEmitter<Message>();

  isInCustomManual = false;
  showAddButton = false;

  constructor(private customManualService: CustomManualService) { }

  ngOnInit(): void {
    //Check the message command. Only show Add button for Custom Manual with some suitable commands
    if(this.message.command === "$AD" || this.message.command.startsWith("$AD,")) {
      this.showAddButton = true;
    }

    this.customManualService.getCustomManualSubscriber()
    .subscribe(() => 
    {
      this.isInCustomManual = this.customManualService.isInManualWithMessage(this.productName, this.message);
    });
  }

  sendEventForCustomManualWithMessage(): void {
    this.toggleFromCustomManualWithMessage.emit(this.message);
  }
}
