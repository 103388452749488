import { Component, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { RecentlyViewed } from 'src/app/classes/utility';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recent',
  templateUrl: './recent.component.html',
  styleUrls: ['./recent.component.css']
})
export class RecentComponent implements OnInit {
  @Input() standaloneMode: boolean = false;
  recentProducts: RecentlyViewed[] = [];

  constructor(private dataService: DataService, private router: Router) { }

  ngOnInit(): void {
    this.recentProducts = this.dataService.getRecentlyViewed();
  }

  goToProduct(product: RecentlyViewed): void {
    //Standalone app will open the online product page by itself 
    if (!this.standaloneMode || product.connection === "OFFLINE") {
        this.router.navigate([product.link]);
    }
  }

  removeRecentUsedItem(index: number): void {
    if(!this.standaloneMode) {
      this.dataService.removeItemFromRecentlyViewed(index);
      this.recentProducts = this.dataService.getRecentlyViewed();
    }
  }

  refreshRecentlyViewedProducts(jsonStr: string) {
    this.recentProducts = JSON.parse(JSON.stringify(jsonStr));
  }
}
