var RGB = require('./rgb');
module.exports = {
    BLACK : new RGB(0,0,0),
    WHITE : new RGB(255,255,255),
    RED : new RGB(255,0,0),
    BLUE : new RGB(0,0,255),
    LIME : new RGB(191,255,0),
    YELLOW : new RGB(255,255,0),
    MAROON : new RGB(128,0,0),
    GREEN : new RGB(0,255,0),
    GRAY : new RGB(80,80,80),
    ORANGE : new RGB(255,127,0)
};
