import { Helper } from '../utility';
import { ParameterRuleAcceptedValue } from './parameter-rule-accepted-value';

export class ParameterRule {
  public sender: string[];
  public targets: string[];
  public action: string;
  public condition: string;
  public senderAcceptedValues: ParameterRuleAcceptedValue[];
  public value: string;

  constructor(sender: string[], targets: string[], action: string, condition: string,
              senderAcceptedValues: ParameterRuleAcceptedValue[], value: string) {
    this.sender = sender;
    this.targets = targets;
    this.action = action;
    this.condition = condition;
    this.senderAcceptedValues = senderAcceptedValues;
    this.value = value;
  }

  static fromRule(rule): ParameterRule {
    const senderAcceptedValues = [];
    if (Helper.isArray(rule.value)) {
      for (const value of rule.value) {
        let param = rule['@sender'];
        let valueToCheck = value;
        if (value['@read']) {
          param = value['@read'];
          valueToCheck = value['#text'];
        }
        senderAcceptedValues.push(new ParameterRuleAcceptedValue(param, valueToCheck));
      }
    } else {
      let param = rule['@sender'];
      let valueToCheck = rule.value;
      if (rule.value && rule.value['@read']) {
        param = rule.value['@read'];
        valueToCheck = rule.value['#text'];
      }
      senderAcceptedValues.push(new ParameterRuleAcceptedValue(param, valueToCheck));
    }

    const obj = new this(
      Helper.splitAndTrim(rule['@sender'], ';'),
      Helper.splitAndTrim(rule['@target'], ';'),
      rule['@action'],
      rule['@condition'],
      senderAcceptedValues,
      ''
    );

    return obj;
  }

  static fromRuleSet(ruleSet): ParameterRule[] {
    const result: ParameterRule[] = [];

    const senderAcceptedValues = [];
    if (Helper.isArray(ruleSet.value)) {
      for (const value of ruleSet.value) {
        let param = ruleSet['@sender'];
        let valueToCheck = value;
        if (value['@read']) {
          param = value['@read'];
          valueToCheck = value['#text'];
        }
        senderAcceptedValues.push(new ParameterRuleAcceptedValue(param, valueToCheck));
      }
    } else {
      senderAcceptedValues.push(new ParameterRuleAcceptedValue(ruleSet['@sender'], ruleSet.value));
    }

    if (Helper.isArray(ruleSet.target)) {
      for (const target of ruleSet.target) {
        const obj = new this(
          Helper.splitAndTrim(ruleSet['@sender'], ';'),
          Helper.splitAndTrim(target['@name'], ';'),
          target['@action'],
          ruleSet['@condition'],
          senderAcceptedValues,
          target['@value']
        );

        result.push(obj);
      }
    } else {
      const obj = new this(
        Helper.splitAndTrim(ruleSet['@sender'], ';'),
        Helper.splitAndTrim(ruleSet.target['@name'], ';'),
        ruleSet.target['@action'],
        ruleSet['@condition'],
        senderAcceptedValues,
        ruleSet.target['@value']
      );

      result.push(obj);
    }

    return result;
  }
}
