module.exports = {
    ARIAL : 'Arial',
    COMIC_SANS : 'Comic Sans MS',
    GEORGIA : 'Georgia',
    IMPACT : 'Impact',
    TAHOMA : 'Tahoma',
    HELVETICA : 'Helvetica',
    VERDANA : 'Verdana',
    COURIER_NEW : 'Courier New',
    PALATINO : 'Palatino Linotype',
    TIMES_NEW_ROMAN : 'Times New Roman'
};
