<div class="form-group row d-flex align-items-center" *ngIf="parameter.childParameters.length === 0">
  <div class="col-5 col-form-label text-right">
    {{parameter.label}}
  </div>
  <div class="col-5">
    <button type="button" class="btn btn-primary" data-toggle="modal"
      [attr.data-target]="'#modalStringFilled-' + parameter.code" [disabled]="readonly"
      (click)="refreshValue()">
      {{'SHARED.EDIT' | translate}}
    </button>
    <div class="values no-uppercase">
      {{selectedOptionsText}}
    </div>
  </div>
  <div class="col-2">
    <!--<a role="button" *ngIf="customManualEnabled" class="btn btn-outline-warning btn-sm">+Add</a>-->
  </div>
</div>

<!-- Modal -->
<div class="modal fade" [id]="'modalStringFilled-' + parameter.code" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{parameter.label}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <span class="title">{{'DRIVER-LICENSE.COMMON' | translate}}</span>
        <span class="range">
          [{{'SHARED.MIN' | translate}} {{parameter.minLen}} - {{'SHARED.MAX' | translate}} {{parameter.maxLen}}]
        </span>
        <div class="possible-values-list">
          <div class="possible-values">
            <div *ngFor="let option of commonFields" (click)="selectOption(option, '02')">
              {{option['@name']}}
            </div>
          </div>
          <span class="title">{{'DRIVER-LICENSE.LESS-COMMON' | translate}}</span>
          <div class="possible-values">
            <div *ngFor="let option of lessCommonFields" (click)="selectOption(option, '02')">
              {{option['@name']}}
            </div>
          </div>
          <span class="title">{{'DRIVER-LICENSE.SEPARATOR' | translate}}</span>
          <div class="possible-values">
            <div *ngFor="let option of separatorFields; let i = index" (click)="selectOption(i, '01')">
              {{option}}
            </div>
          </div>
        </div>
        <div class="selected-values-container">
          <div class="buttons clearfix">
            <div class="button" (click)="moveUp()"
              [ngClass]="{disabled: activeOptions.indexOf(0) >= 0 || activeOptions.length === 0}">
              <img src="assets/images/move-up.svg" />
            </div>
            <div class="button" (click)="moveDown()"
              [ngClass]="{disabled: activeOptions.indexOf(selectedOptions.length - 1) >= 0 || activeOptions.length === 0}">
              <img src="assets/images/move-down.svg" />
            </div>
            <div class="button delete" (click)="deleteActiveOptions()"
              [ngClass]="{disabled: activeOptions.length === 0}">
              <img src="assets/images/delete.svg" />
            </div>
          </div>
          <div class="selected-values">
            <div *ngFor="let option of selectedOptions; let i = index" [ngClass]="{active: activeOptions.indexOf(i) >= 0}"
              (click)="toggleActiveOption(i)">
              {{option.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-warning" (click)="close()"
          data-dismiss="modal">{{'SHARED.CLOSE' | translate}}</button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="saveChanges()">{{'SHARED.SAVE-CHANGES' | translate}}</button>
      </div>
    </div>
  </div>
</div>
