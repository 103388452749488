import { QueryList } from '@angular/core';
import { Component, Input, ElementRef, ViewChild, OnChanges, SimpleChanges, ViewChildren } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as jsRTF from 'src/app/jsrtf';
import { saveAs } from 'file-saver';

import { Command, Helper } from 'src/app/classes/utility';
import { BarcodeComponent } from '../barcode/barcode.component';
import { ScannerInterfaceType } from 'src/app/classes/enums/scanner-interface-type.enum';

@Component({
  selector: 'app-pdf-content',
  templateUrl: './pdf-content.component.html',
  styleUrls: ['./pdf-content.component.css']
})
export class PdfContentComponent implements OnChanges {
  @Input() preCommands: Command[] = [];
  @Input() postCommands: Command[] = [];
  @Input() commands: Command[] = [];
  @Input() productName = '';
  @Input() margin: any;
  @Input() height: any;
  @Input() width: any;
  @Input() maxCommand: any;
  @Input() family = '';
  @Input() preCmd: string = 'C';
  @Input() enterConfigurationCmd: string = 'P';
  @Input() exitConfigurationCmd: string = 'P';
  @Input() currentUle: string = '';
  @ViewChild('pdfBody') pdfBody: ElementRef;
  @ViewChildren('barcodes') barcodes: QueryList<BarcodeComponent>;
  type = 'code128';
  date = '';
  concatenate = true;
  showConfiguration = false;
  showProgrammingModeLabel = false;
  internalCommands: Command[] = [];

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.commands) {
      if (changes.commands.previousValue !== changes.commands.currentValue) {
        this.refreshData();
      }
    }

    if (changes.height) {
      if (changes.height.previousValue !== changes.height.currentValue) {
        this.refreshData();
      }
    }

    if (changes.width) {
      if (changes.width.previousValue !== changes.width.currentValue) {
        this.refreshData();
      }
    }

    if (changes.maxCommand) {
      if (changes.maxCommand.previousValue !== changes.maxCommand.currentValue) {
        this.refreshData();
      }
    }
  }

  toggleProgrammingModeLabel(): void {
    this.showProgrammingModeLabel = !this.showProgrammingModeLabel;

    this.refreshData();
  }

  setProgrammingModeLabel(value: boolean): void {
    this.showProgrammingModeLabel = value;

    this.refreshData();
  }

  toggleShowConfigutation(): void {
    this.showConfiguration = !this.showConfiguration;
  }

  addInterfaceCommand(interfaceCommand: Command, check: boolean): void {
    if(check){
      this.commands = [interfaceCommand, ...this.commands];
    } 
    else {
      if(this.commands[0].getValue(false,this.enterConfigurationCmd) == interfaceCommand.getValue(false,this.enterConfigurationCmd)) this.commands.splice(0, 1);
    }
    this.refreshData();
  }

  setConcatenate(concat: boolean): void {
    this.concatenate = concat;

    this.refreshData();
  }

  setType(type: string): void {
    this.type = type;

    this.refreshData();
  }

  prePostCommandType() {
    if (this.family == "FRS"){
      return "code128"
    } else {
      return this.type;
    }
  }

  downloadPdf(download: boolean = true) {
    const date = new Date();
    const dateString = date.toLocaleString('en-GB');

    const doc = new jsPDF();
    doc.setFontSize(17);

    const img = Helper.datalogicLogoBase64;

    const pageSize = 300;
    const pageMin = 20;
    let margin = 55 + (50 / 3); // Margin from last element + small margin

    ///Margin used on the left of A4 exported pdf
    const leftmargin = 15;

    doc.addImage(img, 'PNG', leftmargin, pageMin, 80, 10);
    doc.text(leftmargin, 45, dateString);
    doc.text(leftmargin, 55, this.productName);

    let commandType = this.prePostCommandType();

    const preCommandsToPrint = this.preCommands.filter((it, index, array) => this.isConfigurationItemVisible(index, array));
    const postCommandsToPrint = this.postCommands.filter((it, index, array) => this.isConfigurationItemVisible(index, array));

    for (const command of preCommandsToPrint) {
      margin = this.printPdfCommand(doc, command, margin, pageSize, pageMin, false, leftmargin, commandType);
    }

    for (const command of this.internalCommands) {
      margin = this.printPdfCommand(doc, command, margin, pageSize, pageMin, this.showProgrammingModeLabel, leftmargin, this.type);
    }

    for (const command of postCommandsToPrint) {
      margin = this.printPdfCommand(doc, command, margin, pageSize, pageMin, false, leftmargin, commandType);
    }

    if ((margin + 10) > pageSize) {
      doc.addPage();
      margin = pageMin;
    }
    doc.addImage(img, 'PNG', leftmargin, margin, 80, 10);

    if (download) {
      doc.save('myfile-' + ((new Date().getTime() * 10000) + 621355968000000000) + '.pdf');
    }

    return new Blob([doc.output()], {type : 'application/pdf'});
  }

  downloadRtf(download: boolean = true) {
    // Create RTF object
    var myDoc = new jsRTF();

    const date = new Date();
    const dateString = date.toLocaleString('en-GB');

    // Formatter object
    var textFormatNoSpaceBefore = new jsRTF.Format({
      spaceBefore : 0,
      spaceAfter : 300,
      paragraph : true,
    });
    var textFormatNoSpaceAfter = new jsRTF.Format({
      spaceBefore : 300,
      spaceAfter : 0,
      paragraph : true,
    });
    var textFormatNoSpaceBeforeAfter = new jsRTF.Format({
      spaceBefore : 0,
      spaceAfter : 0,
      paragraph : true,
    });

    myDoc.addImage(Helper.datalogicLogoBase64.substring(Helper.datalogicLogoBase64.indexOf('base64,') + 7));

    // Adding text styled with formatter
    myDoc.writeText('', textFormatNoSpaceBeforeAfter);
    myDoc.writeText(dateString, textFormatNoSpaceAfter);
    myDoc.writeText(this.productName, textFormatNoSpaceBefore);

    let commandType = this.prePostCommandType();

    const preCommandsToPrint = this.preCommands.filter((it, index, array) => this.isConfigurationItemVisible(index, array));
    const postCommandsToPrint = this.postCommands.filter((it, index, array) => this.isConfigurationItemVisible(index, array));

    for (const command of preCommandsToPrint) {
      this.printRtfCommand(myDoc, command, false, commandType);
    }

    for (const command of this.internalCommands) {
      this.printRtfCommand(myDoc, command, this.showProgrammingModeLabel, this.type);
    }

    for (const command of postCommandsToPrint) {
      this.printRtfCommand(myDoc, command, false, commandType);
    }

    myDoc.addImage(Helper.datalogicLogoBase64.substring(Helper.datalogicLogoBase64.indexOf('base64,') + 7));

    // Make content...
    var content = myDoc.createDocument();

    const blob = new Blob([content], {
      type: 'application/rtf;character=utf-8'
    });

    if (download) {
      saveAs(blob, 'myfile-' + ((new Date().getTime() * 10000) + 621355968000000000) + '.rtf');
    }

    return blob;
  }

  isPageBreak(index: number, addPreCommands: boolean = false, addCommands: boolean = false): boolean {
    let newIndex = index;
    if (addPreCommands && this.showConfiguration) {
      newIndex += this.preCommands.slice(0, -1).length;
    }

    if (addCommands) {
      newIndex += this.commands.length + index;
    }

    return newIndex > 0 && newIndex % 4 === 0;
  }

  isConfigurationItemVisible(index: number, array: Command[]): boolean {
    if (index < (array.length - 1)) {
      return this.showConfiguration;
    }

    return this.showProgrammingModeLabel;
  }

  private refreshData() {
    const date = new Date();
    this.date = date.toLocaleString('en-GB');

    let setStart = "$" + this.enterConfigurationCmd;
    let setEnd = this.exitConfigurationCmd;
    let sep = ",";
    let family = "";
    // change parts of barcode if FRS
    if (this.commands.length > 0) {
      family = this.commands[0].family;
      if (family == 'FRS') {
        setStart = "00000";
        setEnd = "00000";
        sep = "\r";
      }
    }
    let headerLength = setStart.length + sep.length;
    let footerLength = sep.length + setEnd.length;

    let commandDescription = '';
    let commandString = setStart + sep;
    let uleCommandsString = '';
    this.internalCommands = [];
    for (const command of this.commands) {
      if (command.isPackCommand) {
        uleCommandsString += '$' + this.preCmd + command.getValue(false, this.enterConfigurationCmd) + '\n';
      } else {
        //check interface in commands
        let checkInterfaceInCommands = false;
        const description = command.description;
        for(const [key, value] of Object.entries(ScannerInterfaceType)) {
          if(description.includes(value)) {
            this.internalCommands = [command,...this.internalCommands];
            checkInterfaceInCommands = true;
            break;
          }
        }

        if(!checkInterfaceInCommands) {
          // If i'm concatenating commands go to a string otherwise generate barcode
          if (this.concatenate) {
            if (this.maxCommand.value === 0) {
              if (commandString.length > headerLength) {
                commandString += sep;
                commandDescription += ', ';
              }
              commandString += command.getValue(false, this.enterConfigurationCmd).substring(headerLength).slice(0, -footerLength);
              commandDescription += command.description;
            } else {
              if ((commandString + sep + command.getValue(false, this.enterConfigurationCmd).substring(headerLength).slice(0, -footerLength) + sep + setEnd).length > this.maxCommand.value) {
                this.internalCommands.push(new Command(commandString + sep + setEnd, commandDescription, '', false, family));
                commandDescription = '';
                commandString = setStart + sep;
              } else {  
                if (commandString.length > headerLength) {
                  commandString += sep;
                  commandDescription += ', ';
                }
              }
              commandString += command.getValue(false, this.enterConfigurationCmd).substring(headerLength).slice(0, -footerLength);
              commandDescription += command.description;
            }
          } else {
            this.internalCommands.push(command);
          }
        }
      }
    }

    // If i'm concatenating show single barcode for commands
    if (this.concatenate && this.commands.length > 0) {
      if(commandString != setStart + sep) this.internalCommands.push(new Command(commandString + sep + setEnd, commandDescription, '', false, family));
    }

    let ule: string = "";
    if (uleCommandsString.length > 0) {
      ule = window.af2ule(uleCommandsString);
    } else if (this.currentUle) {
      ule = this.currentUle;
    }

    if (ule && ule !== "" && ule.trim() !== "") {
      let uleCommands;
      if(this.productName.includes('Touch'))
        uleCommands = window.ule2barcodeCmds(ule, 4, family);
      else
        uleCommands = window.ule2barcodeCmds(ule, 16, family);

      // determine amount of command to cut off based on family
      let substringIndex = 1;
      if (this.family == 'FRS') {
        substringIndex = 0;
      }
      
      if (this.type !== 'code128' && this.concatenate) {
        if (this.maxCommand.value === 0) {
          const uleChunks = Helper.splitIntoChunks(uleCommands, 48);
          let preIndex = 0;
          for (const chunk of uleChunks) {
            let uleDescription = '';
            let uleString = setStart + sep;
            chunk.reduce((accumulator, currentValue, index) => {
              uleString += currentValue.substring(substringIndex);
              uleDescription += 'ule ' + (preIndex + index + 1);

              if (index < (chunk.length - 1)) {
                uleString += sep;
                uleDescription += ', ';
              }

              return '';
            }, {});

            preIndex = chunk.length;

            this.internalCommands.push(new Command(uleString + sep + setEnd, uleDescription, '', false, family));
          }
        } else {
          let preIndex = 0;
          let uleDescription = '';
          let uleString = setStart + sep;
          for (let uleCommandElement of uleCommands) {
            if ((uleString + sep + uleCommandElement.substring(substringIndex) + sep + setEnd).length > this.maxCommand.value) {
              this.internalCommands.push(new Command(uleString + sep + setEnd, uleDescription, '', false, family));
              uleDescription = '';
              uleString = setStart + sep;
            } else {
              if (preIndex !== 0) {
                uleString += sep;
                uleDescription += ', ';
              }
            }

            uleString += uleCommandElement.substring(substringIndex);
            uleDescription += 'ule ' + (preIndex + 1);

            preIndex++;

            if (preIndex === uleCommands.length) {
              this.internalCommands.push(new Command(uleString + sep + setEnd, uleDescription, '', false, family));
            }
          }
        }
      } else {
        this.internalCommands = this.internalCommands.concat(uleCommands.map((it, i) => new Command(setStart + sep + it.substring(substringIndex) + sep + setEnd, 'ule ' + (i + 1), '', false, family)));
      }
    }
  }

  private addText(doc, text, margin, pageSize, pageMin, leftmargin) {
    var splitTitle = doc.splitTextToSize(text, 180);
    for (let titleElement of splitTitle) {
      if ((margin + 27) > pageSize) {
        doc.addPage();
        margin = pageMin;
        doc.text(leftmargin, margin, titleElement);
        margin = margin + 7;
      } else {
        doc.text(leftmargin, margin, titleElement);
        margin = margin + 7;
      }
    }

    return margin;
  }

  private printPdfCommand(doc, command, margin, pageSize, pageMin, showProgrammingModeLabel, leftmargin, commandType) {
    margin = this.addText(doc, command.description, margin, pageSize, pageMin, leftmargin);
    margin = this.addText(doc, command.getDisplayValue(commandType, showProgrammingModeLabel, this.enterConfigurationCmd), margin, pageSize, pageMin, leftmargin);

    let barcodeWidth = 30;
    let barcodeHeight = 30;
    const commandValue = command.getValue(showProgrammingModeLabel, this.enterConfigurationCmd);

    const barcodeComponent = this.barcodes.find(it => it.text === commandValue);
    if (barcodeComponent) {
      var imgData = barcodeComponent.getBarcodeAsBase64();
      let imgWidth = barcodeComponent.getBarcodeWidth();
      let imgHeight = barcodeComponent.getBarcodeHeight();

      barcodeWidth = Math.min(imgWidth / 4, 180);
      if (commandType === 'code128') {
        barcodeHeight = this.height.value / 3;
      } else {
        barcodeHeight = Math.min(imgHeight / 4, 180);
      }

      if ((margin + barcodeHeight + 10) > pageSize) {
        doc.addPage();
        margin = pageMin;
      }
      
      doc.addImage(imgData, 'PNG', leftmargin, margin - 3, barcodeWidth, barcodeHeight);
    }

    margin += barcodeHeight + 5;

    // Combo margin
    const postMargin = (this.margin.value / 3);
    margin += postMargin;
    if (margin > pageSize) {
      doc.addPage();
      margin = pageMin;
    }

    return margin;
  }

  private printRtfCommand(doc, command, showProgrammingModeLabel, commandType) {
    var textFormatNoSpaceBeforeAfter = new jsRTF.Format({
      spaceBefore : 0,
      spaceAfter : 0,
      paragraph : true,
    });
    var textFormatNoSpaceBefore = new jsRTF.Format({
      spaceBefore : 0,
      spaceAfter : 100,
      paragraph : true,
    });

    doc.writeText(command.description, textFormatNoSpaceBeforeAfter);
    doc.writeText(command.getDisplayValue(commandType, showProgrammingModeLabel, this.enterConfigurationCmd), textFormatNoSpaceBefore);

    let barcodeWidth = 90;
    let barcodeHeight = 90;
    const commandValue = command.getValue(showProgrammingModeLabel, this.enterConfigurationCmd);

    const barcodeComponent = this.barcodes.find(it => it.text === commandValue);
    if (barcodeComponent) {
      var imgData = barcodeComponent.getBarcodeAsBase64();
      let imgWidth = barcodeComponent.getBarcodeWidth();
      let imgHeight = barcodeComponent.getBarcodeHeight();
      if (commandType === 'code128') {
        if (imgWidth > 600) {
          barcodeWidth = (barcodeWidth * imgWidth) / 600;
        }
        barcodeHeight = 90;
      } else {
        if (imgWidth > 600) {
          barcodeWidth = (barcodeWidth * imgWidth) / 600;
        }
        barcodeHeight = barcodeWidth;
      }
      imgData = imgData.substring(imgData.indexOf('base64,') + 7)
      doc.addImage(imgData, barcodeWidth, barcodeHeight);
    }

    var textFormatNoSpaceBefore = new jsRTF.Format({
      spaceBefore : 0,
      spaceAfter : this.margin.value * 20,
      paragraph : true,
    });
    doc.writeText('', textFormatNoSpaceBefore);
  }
}
