<div class="body" #pdfBody>
  <div>Datalogic Aladdin</div>
  <div>{{date}}</div>
  <div class="last-line">{{productName}}</div>
  <div *ngFor="let command of preCommands; let i = index" class="item" [style.margin-bottom.px]="margin?.value"
    [hidden]="!isConfigurationItemVisible(i, preCommands)">
    <div>{{command.description}}</div>
    <div class="command-text">{{command.getDisplayValue(prePostCommandType())}}</div>
    <app-barcode #barcodes [type]="prePostCommandType()" [text]="command.getValue()" [height]="height"
      [width]="width"></app-barcode>
  </div>

  <div *ngFor="let command of internalCommands; let i = index" class="item" [style.margin-bottom.px]="margin?.value">
    <div>{{command.description}}</div>
    <div class="command-text">{{command.getDisplayValue(type, showProgrammingModeLabel, enterConfigurationCmd)}}</div>
    <app-barcode #barcodes [type]="type" [text]="command.getValue(showProgrammingModeLabel, enterConfigurationCmd)"
      [height]="height" [width]="width"></app-barcode>
  </div>

  <div *ngFor="let command of postCommands; let i = index" class="item" [style.margin-bottom.px]="margin?.value"
    [hidden]="!isConfigurationItemVisible(i, postCommands)">
    <div>{{command.description}}</div>
    <div class="command-text">{{command.getDisplayValue(prePostCommandType())}}</div>
    <app-barcode #barcodes [type]="prePostCommandType()" [text]="command.getValue()" [height]="height"
      [width]="width"></app-barcode>
  </div>
</div>
