<div class="card" [hidden]="!visible">
  <div class="card-header" [attr.id]="'head' + id" [ngClass]="{'has-children': item.children.length > 0 && hasSearchResults}">
    <h5 class="mb-0">
      <button
        #titleButton
        class="btn btn-link w-100 text-left align-self-center collapsed"
        data-toggle="collapse"
        (click)="titleClicked(item)"
        [attr.data-target]="'#collapse' + id"
        aria-expanded="false"
        [attr.aria-controls]="'collapse' + id">
        <img [src]="image" (error)="setDefault()" class="mr-2" />
        {{item.title}}
      </button>
    </h5>
  </div>

  <div [attr.id]="'collapse' + id" class="collapse"
    [hidden]="item.children.length === 0 || !hasSearchResults"
    [attr.aria-labelledby]="'head' + id"
    data-parent="#params-accordion">
    <div class="card-body">
      <tree-root #tree [nodes]="treeItems" [options]="treeOptions"  (click)="nodeClicked($event)"></tree-root>
    </div>
  </div>
</div>
