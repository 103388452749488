import { Parameter } from '../parameters/parameter';

export class SearchItem {
  public id: string;
  public page: string;
  public parameter: Parameter;

  constructor(id: string, page: string, parameter: Parameter) {
    this.id = id;
    this.page = page;
    this.parameter = parameter;
  }
}
