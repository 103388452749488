export enum DetectionModeType {
    AllPorts = "all",
    DefaultPorts = "default"
}

export enum AutoDetectType {
    Auto = "auto",
    Manual = "manual"
}

export class DetectionSetting{
    public detectionMode: DetectionModeType;
    constructor(detectionMode: DetectionModeType){
        this.detectionMode = detectionMode;
    }
}