import { Component, NgZone, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  newsVisible = true;

  constructor(private zone: NgZone) {  
    // Creating the public object to be called by JxBrowser
    window.footerComponent = {
      component: this,
      zone: this.zone,
      toggleNewsVisible: (value: boolean) => this.newsVisible = value
    };
  }

  ngOnInit(): void {
  }

}
