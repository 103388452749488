import { Product } from '../products/product';

export class RecentlyViewed {
  public id: string;
  public description: string;
  public image: string;
  public link: string;
  public date: Date;
  public connection: string;

  constructor(id: string, description: string, image: string, link: string, connection: string) {
    this.id = id;
    this.description = description;
    this.image = image;
    this.link = link;
    this.date = new Date();
    this.connection = connection;
  }

  static fromProduct(product: Product, connection: string) {
    const obj = new this(
      product.internalName,
      product.name,
      product.imageUrl,
      '/configuration/product/' + product.index,
      connection
    );

    return obj;
  }
}
