import { Injectable } from '@angular/core';
import { CustomManualItem, Message } from '../classes/utility';
import { Parameter } from '../classes/parameters';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomManualService {
  private items: CustomManualItem[] = [];
  private customManualSubscriber: BehaviorSubject<CustomManualItem[]> = new BehaviorSubject<CustomManualItem[]>([]);

  constructor() { }

  getCustomManualSubscriber(): BehaviorSubject<CustomManualItem[]> {
    return this.customManualSubscriber;
  }

  // toggleFromManual(product: string, param: Parameter, family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): number {
  //   const paramDesc = param.getDescriptionWithValue();
  //   const itemIndex = this.items.findIndex(it => it.product === product && it.description === paramDesc);
  //   if (itemIndex >= 0) {
  //     this.items.splice(itemIndex, 1);
  //   }
  //   else {
  //     const itemIndexWithLabel = this.items.findIndex(it => it.product === product && it.description.split(": ")[0] == param.label);
  //     if(itemIndexWithLabel > -1) {
  //       this.items.splice(itemIndexWithLabel, 1, CustomManualItem.fromParameter(product, param, family, preCmd, enterConfigurationCmd, exitConfigurationCmd));
  //     } else this.items.push(CustomManualItem.fromParameter(product, param, family, preCmd, enterConfigurationCmd, exitConfigurationCmd));
  //   }

  //   this.customManualSubscriber.next(this.items);

  //   return this.items.filter(it => it.product === product).length;
  // }
  updateCustomManualItems(parameter: Parameter, checkToggle: boolean, product: string) {
    const itemIndex = this.items.findIndex(it => it.product === product && (!!!it.param || it.param.code === parameter.code));
    if (itemIndex > -1) {
      if(checkToggle) this.items.splice(itemIndex, 1);
      else this.items[itemIndex].param = parameter;
    } else {
      if (checkToggle) this.items.push(new CustomManualItem(product, "", "", parameter));
    }

    this.customManualSubscriber.next([...this.items]);
  }

  getCompleteCustomManual(product: string,family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string) {
    let customManualList = [];
    this.items.forEach((value) => {
      if(value.product == product) {
        if(!!value.param){
          value.description = value.param.getDescriptionWithValue();
          value.value = value.param.getCommand(family, preCmd, enterConfigurationCmd, exitConfigurationCmd);
        } 
        customManualList.push(value);
      }
    });

    return customManualList;
  }


  toggleFromManualWithMessage(product: string, message: Message, family: string, preCmd: string, enterConfigurationCmd: string, exitConfigurationCmd: string): number {
    const messageDesc = message.description;
    const itemIndex = this.items.findIndex(it => it.product === product && it.description === messageDesc);
    if (itemIndex >= 0) {
      this.items.splice(itemIndex, 1);
    }
    else {
      this.items.push(CustomManualItem.fromMessage(product, message, family, preCmd, enterConfigurationCmd, exitConfigurationCmd));
    }

    this.customManualSubscriber.next(this.items);

    return this.items.filter(it => it.product === product).length;
  }

  deleteFromManual(item: CustomManualItem) {
    const itemIndex = this.items.findIndex(it => it.product === item.product && ((!!!it.param && it.description === item.description) || it.param.code === item.param.code));
    if (itemIndex >= 0) {
      this.items.splice(itemIndex, 1);
    }
    this.customManualSubscriber.next(this.items);
  }

  getByProduct(product: string): CustomManualItem[] {
    return this.items.filter(it => it.product === product);
  }

  isInManual(product: string, param: Parameter): boolean {
    const paramDesc = param.getDescriptionWithValue();
    return this.items.findIndex(it => it.product === product && ((!it.param && it.description == paramDesc) ||it.param.code == param.code)) >= 0;
  }

  isInManualWithMessage(product: string, message: Message): boolean {
    const messageDesc = message.description;
    return this.items.findIndex(it => it.product === product && it.description === messageDesc) >= 0;
  }
}
