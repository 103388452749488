<button type="button" id="ImageCaptureButton" class="btn btn-primary" data-toggle="modal" data-target="#ImageCaptureModal"></button>

<div class="modal fade" id="ImageCaptureModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Image Capture</h5>
         <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Brightness <span id="brightnessView">0</span></label>
                </div>
                <div class="col-8">
                    <input id="brightness" name="brightness" type="range" class="form-control-range"  value="0" max="100" min="-100" step="25" (input)="rangeValue($event,'brightness')">
                
                </div>
            </div>
            <div class="form-group row d-flex align-items-center">
                <div class="col-4 col-form-label text-right">
                    <label>Contrast <span id="contrastView">0</span></label>
                </div>
                <div class="col-8">
                    <input id="contrast" name="contrast" type="range" class="form-control-range"  value="0" max="100" min="-100" step="25" (input)="rangeValue($event,'contrast')">
                
                </div>
            </div>
            <ul class="nav nav-tabs" id="ImagesTab" role="tablist">
                
                <li class="nav-item">
                  <a class="nav-link" id="second-tab" data-toggle="tab" href="#second" role="tab" aria-controls="second" aria-selected="false">
                      10:10:45.jpg
                        <i class="icon close"></i>
                    </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="third-tab" data-toggle="tab" href="#third" role="tab" aria-controls="third" aria-selected="false">
                      11:05:01.jpg
                    <i class="icon close"></i>
                  </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active" id="first-tab" data-toggle="tab" href="#first" role="tab" aria-controls="first" aria-selected="true">
                        12:10:21.jpg
                        <i class="icon close"></i>
                    </a>
                  </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                
                <div class="tab-pane fade" id="second" role="tabpanel" aria-labelledby="second-tab">
                        <div class="actions">
                            <button type="button" class="btn btn-warning">
                                <a><img src="assets/images/save.svg"/></a>
                            </button>
                            
                            <button type="button" class="btn btn-warning">
                                <a><img src="assets/images/print.svg"/></a>
                            </button>

                            <button type="button" class="btn btn-warning">
                                <a><img src="assets/images/zoom+.svg"/></a>
                            </button>

                            <button type="button" class="btn btn-warning">
                                <a><img src="assets/images/zoom-.svg"/></a>    
                            </button>
                        </div>
                        <div class="img-content">
                            <div class="img-wrapper">
                                <img src="/assets/images/imagecapture3.jpg" />        
                            </div>
                            
                        </div>
                      
               </div>
                <div class="tab-pane fade" id="third" role="tabpanel" aria-labelledby="third-tab">
                    <div class="actions">
                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/save.svg"/></a>
                        </button>
                        
                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/print.svg"/></a>
                        </button>

                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/zoom+.svg"/></a>
                        </button>

                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/zoom-.svg"/></a>    
                        </button>
                    </div>
                    <div class="img-content">
                        <div class="img-wrapper">
                            <img src="/assets/images/imagecapture2.jpg" />        
                        </div>
                        
                    </div>
                </div>
                <div class="tab-pane fade show active" id="first" role="tabpanel" aria-labelledby="first-tab">
                    <div class="actions">
                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/save.svg"/></a>
                        </button>
                        
                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/print.svg"/></a>
                        </button>

                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/zoom+.svg"/></a>
                        </button>

                        <button type="button" class="btn btn-warning">
                            <a><img src="assets/images/zoom-.svg"/></a>    
                        </button>
                    </div>
                    <div class="img-content">
                        <div class="img-wrapper">
                            <img src="/assets/images/imagecapture1.jpg" />        
                        </div>
                        
                    </div>
                </div>
              </div>
              <div class="bottom-actions">
                <div class="btn-group" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-warning">Capture Auto</button>
                    <button type="button" class="btn btn-warning">Capture On Trigger</button>
                    <button type="button" class="btn btn-warning">Capture On Decode</button>
                  </div>
              </div>
              
        </div>
      </div>
    </div>
  </div>
</div>
