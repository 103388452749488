import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-smart-battery',
  templateUrl: './smart-battery.component.html',
  styleUrls: ['./smart-battery.component.css']
})
export class SmartBatteryComponent implements OnInit {
  @Input() values: any[] = [];
  @Input() code: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
