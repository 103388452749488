import { SearchItem } from './../../classes/utility/search-item';
import { isBrowserOnRefresh } from './../../app.component';
import { ElementsService } from './../../services/elements/elements.service';
import { Component, OnInit, QueryList, ViewChild, ViewChildren, NgZone, ElementRef, HostListener, AfterViewChecked, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { saveAs } from 'file-saver';
import { delay } from 'rxjs/operators';

import { XmlService } from '../../services/xml.service';
import { ProductData } from '../../classes/products/product-data';
import { ParamSectionComponent } from '../../shared/param-section/param-section.component';
import { DataService } from 'src/app/services/data.service';
import { ParamEditorComponent } from 'src/app/shared/param-editor/param-editor.component';
import { Parameter } from 'src/app/classes/parameters';
import { Product } from 'src/app/classes/products/product';
import { ImageCaptureComponent } from 'src/app/shared/image-capture/image-capture.component';
import { Command, HelpItem, TabItem } from 'src/app/classes/utility';
import { DriverLicenseEditorComponent } from 'src/app/shared/driver-license-editor/driver-license-editor.component';
import { DynamicHtmlComponent } from 'src/app/shared/dynamic-html/dynamic-html.component';
import { TabNavigationService } from 'src/app/services/tab-navigation.service';
import { CustomManualService } from 'src/app/services/custom-manual.service';
import { CustomManualComponent } from 'src/app/shared/custom-manual/custom-manual.component';
import { JingleComponent } from 'src/app/shared/jingle/jingle.component';
import { PdfPreviewModalComponent } from 'src/app/shared/pdf-preview-modal/pdf-preview-modal.component';
import { CompareConfigurationComponent } from 'src/app/shared/compare-configuration/compare-configuration.component';
import { ConfigurationElement } from 'src/app/classes/configuration-element';
import { ParamMenuItem } from 'src/app/classes/param-menu-item';
import { TranslateService } from '@ngx-translate/core';
import { Message } from 'src/app/classes/utility';
import { RemoteService } from 'src/app/services/remote.service';
import { Helper } from 'src/app/classes/utility';
import { SettingsService } from '../../services/settings.service';
import { HttpClient } from '@angular/common/http';

//import { webSocket } from 'rxjs/webSocket';
import { ExportCCFOptionsComponent } from 'src/app/shared/export-ccf-options/export-ccf-options.component';
import { LoadingWindowComponent } from 'src/app/shared/loading-window/loading-window.component';
import { OfflineCCFImport } from './offline-ccf-import';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { filter, switchMap } from 'rxjs/operators';
import { createClaimsTable } from '../../claim-utils';
import { CloudSaveConfigComponent } from 'src/app/shared/cloud-save-config/cloud-save-config.component';
import { protectedResources, enableCloudService } from '../../auth-config';
import { ScannerInterfaceType } from "./../../classes/enums/scanner-interface-type.enum";
import { ConfigurationManagementService } from 'src/app/services/cloud/configuration-management.service';
import { RemoteManagementService } from 'src/app/services/cloud/remote-management.service';
import { NotificationComponent } from 'src/app/shared/notification/notification.component';
import { NotificationType } from 'src/app/classes/enums/notification-type.enum';
import { SessionStorageService } from 'src/app/services/session-storage.service';
import { TerminalCustomButton } from 'src/app/classes/utility/terminal-custom-btn';

declare var $: any;
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css'],
})
export class ProductComponent implements OnInit, AfterViewChecked, AfterContentInit {
  @ViewChildren('paramMenu') menuSections: QueryList<ParamSectionComponent>;
  @ViewChildren('paramEditor') paramEditors: QueryList<ParamEditorComponent>;
  @ViewChildren('driverLicenseEditor') driverLicenseEditors: QueryList<DriverLicenseEditorComponent>;
  @ViewChild('pdfPreview') pdfPreview: PdfPreviewModalComponent;
  @ViewChild('compareConfiguration') compareConfiguration: CompareConfigurationComponent;
  @ViewChild('jingle') jingle: JingleComponent;
  @ViewChild('customManual') customManual: CustomManualComponent;
  @ViewChild('imageCapture') imageCapture: ImageCaptureComponent;
  @ViewChild('helpContent') helpContent: DynamicHtmlComponent;
  @ViewChild('currentProduct') currentProduct: ElementRef;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('exportCCFOptions') exportCCFOptions: ExportCCFOptionsComponent;
  @ViewChild('loadingWindow') loadingWindow: LoadingWindowComponent;
  @ViewChild('cloudSaveConfig') cloudSaveConfig: CloudSaveConfigComponent;
  @ViewChild('productNotification') notification: NotificationComponent;
  @ViewChild('chatAreaEditor') chatAreaEditor: ElementRef;

  product: Product;
  searchParam: Parameter;
  searchHistory: SearchItem[] = [];
  isOnSearchInputClick = false;
  data: ProductData;
  paramMenu: ParamMenuItem[] = [];
  isOnline = false;
  viewImageEnabled = false;
  family = '';
  connectedTo = '';
  searchValue = '';
  helpText = '';
  selectedReleaseIndex = 0;
  customManualEnabled = false;
  customManualLength = 0;
  clearVisible = false;
  helpItem: HelpItem = undefined;
  currentConfig: any = undefined;
  notDefaultParameters: Parameter[] = [];
  modifiedParameters: Parameter[] = [];
  pdfCommands: Command[] = [];
  helpItems: HelpItem[] = [];
  searchedParams: any[] = [];
  newsVisible = true;
  lastInterface = '';
  preCmd: string = 'C';
  enterConfigurationCmd: string = 'P';
  exitConfigurationCmd: string = 'P';
  interfaceChangeEvent: CustomEvent = new CustomEvent('INTERFACE_CHANGE');
  plainTextCheckBoxStatus: boolean = false;
  acceptedFiles = '.txt,.tex,.tex.txt';
  tabSampleElement: TabItem;
  tabTerminalContents: string[] = [];
  terminal_customButtons: TerminalCustomButton[] = [];
  CI_INTERFACE_TYPE: string = "0001";
  isInCustomManual = false;
  releaseToMCF = new Map<string, string>();
  deviceRelease = '';
  lastGeneratedCCFName = '';
  userName = "Aladdin_Offline";
  interfaceCommand: Command = null;
  enableCloudService: boolean = false;

  sendTerminalRequest = "sendTerminalRequest";
  sendTerminalResponse = "sendTerminalResponse";
  writeConfigurationRequest = "writeConfigurationRequest";
  writeConfigurationResponse = "writeConfigurationResponse";
  readConfigurationRequest = "readConfigurationRequest";
  readConfigurationResponse = "readConfigurationResponse";

  tabModeTitle: string = "";
  isRemoted: boolean = false;
  standaloneMode: boolean = false;
  standaloneEnableCloud: boolean = false;
  private channelName: string = "";

  loginDisplayB2c = false;
  dataSource: any = [];
  webApiRes: any = [];
  noHelpDataMessage = '\n<p><span style="font-weight: bold;"><b><a>No help data found</a></b></span></p>\n';
  interfaceParams = [];
  btnWriteArrow = 'btn-default';
  allInterfaceParamsCommands: string[] = [];
  serialNumber: string = "";
  tokenRequest = {
    scopes: [...protectedResources.apiTodoList.scopes.read],
  };
  hiddenString: string = "*****";
  isBrowserOnRefresh: boolean;
  currentConfigBlocks: any[] = [];
  static isActiveHover: boolean = true;
  prevScrollPosition: number = window.scrollY;
  currentScrollPosition: number;

  constructor(private dataService: DataService, private xmlService: XmlService, private route: ActivatedRoute,
              private router: Router, private zone: NgZone, private tabNavigationService: TabNavigationService,
              private customManualService: CustomManualService, private translate: TranslateService,
              private remoteService: RemoteService, private settingsService: SettingsService, private http: HttpClient,
              private authService: MsalService, private msalBroadcastService: MsalBroadcastService,
              private configService: ConfigurationManagementService, private remoteManagementService: RemoteManagementService, private elementsService: ElementsService) {
      window.homeComponent = {
          zone: this.zone,
          toggleNewsVisible: (value: boolean) =>this.newsVisible = value,
          setSearchDeviceStatus: (value: string) => this.remoteService.setSearchDeviceStatus(value)
        };
        
      this.remoteService.triggerDataChannel.subscribe((value) => {
        if (value == this.channelName) {
          console.log("triggerDataChannel.subscribe");
          this.triggerDataChannelInit();
        }
      });

      this.settingsService.getParamCodeSubjectEvent().subscribe(x => this.refreshParameterLabels());
    }

  ngAfterViewChecked(): void {
    if(this.searchParam) {
      let configElements = document.getElementsByClassName('config-element');
      let index  = Array.from(configElements).findIndex((val) => val.textContent.includes(this.searchParam.label));
      
      if(index > 0) {
        let position =  (configElements[index] as HTMLElement).offsetTop;
        window.scroll({top: position, behavior: "smooth"});
        this.searchParam = null;
        (configElements[index] as HTMLElement).style.cssText += 'background-color:rgba(27, 255, 133, 0.16);border-radius: 5px';
        setTimeout(() =>{
          if(index < configElements.length && configElements[index]) {
            (configElements[index] as HTMLElement).style.removeProperty("background-color");
            (configElements[index] as HTMLElement).style.removeProperty("border-radius");
          }
        }, 5000);
      }
    }

    // call once to update scrollbar
    if(this.isLargeSCreen()){
      this.onPageScrollEvent();
    }
  }
  
  ngOnInit(): void { 
    // Creating the public object to be called by JxBrowser
    window.productComponent = {
      component: this,
      zone: this.zone,
      getCurrentProductParameters: () => this.getCurrentProductParameters(),
      getCurrentChangedParameters: () => this.getCurrentChangedParameters(),
      getPreCmd: () => this.getPreCmd(),
      getCurrentUleParameters: () => this.getCurrentUleParameters(),
      setAllProductParameterToDefault: () => this.setAllProductParameterToDefault(),
      setCurrentProductParameter: (code: string, value: string, resetULEFollowingAF: boolean = true, setDeviceValue: boolean = true) => this.setCurrentProductParameter(code, value, setDeviceValue, resetULEFollowingAF),
      setDeviceRelease: (release: string) => this.setDeviceRelease(release),
      addSmartBatteryRow: (name: string, value: string) => this.addSmartBatteryRow(name, value),
      clearSmartBattery: () => this.clearSmartBattery(),
      addStatisticPageRow: (code: string, name: string, value: string) => this.addStatisticPageRow(code, name, value),
      clearStatisticPage: () => this.clearStatisticPage(),
      setProductPageOffline: () => this.setProductPageOffline(),
      setUleScript: (value: string, improveTextDisplaying: boolean = false) => this.setUleScript(value, improveTextDisplaying),
      setCertificate: (value: string) => this.setCertificate(value),
      setCcfScript: (value: string) => this.data.currentConfigFile = value,
      getCurrentUleScript: () => this.data.currentUle,
      getCurrentCertificate: () => this.data.currentCertificate,
      getCurrentCertificateCode: () => this.getCurrentCertificateCode(),
      getMaxSizeCertificate: () => this.getMaxSizeCertificate(),
      getPackageSizeCertificate: () => this.getPackageSizeCertificate(),
      getCurrentCcfScript: () => this.data.currentConfigFile,
      setTerminalContent: (value: string) => this.setTerminalContent(value),
      clearTerminalContent: () => this.clearTerminalContent(),
      getCurrentConfigAfterOpenTab: (value: String) => this.getCurrentConfigAfterOpenTab(value),
      setUserName: (value: string) => this.setUserName(value),
      setGeneratedCCFName: (value: string) => this.setGeneratedCCFName(value),
      getAllInterfaceCommands:() => this.allInterfaceParamsCommands.toString(),
      getCurrentInterface:() => this.getCurrentInterfaceCmd(),
      isCurrentConfigHideValue:() => this.isCurrentConfigHideValue(),
      onCertificateWriteFinish:() => this.onCertificateWriteFinish(),
      terminal_getCustomButtons:(buttons: string) => this.terminal_getCustomButtons(buttons)
    };

    this.enableCloudService = enableCloudService.toLowerCase() === "true";
    
    Helper.standaloneMode.subscribe((value) => {
      this.standaloneMode = value;
    });
    
    //This function will enable cloud mode from standalone.
    //The cloud mode can be disable by authConfig.js
    Helper.standaloneEnableCloud.subscribe((value) => {
      this.standaloneEnableCloud = value;
      if (this.standaloneEnableCloud) {
        this.enableCloudService = true;
      } else {
        this.enableCloudService = enableCloudService.toLowerCase() === "true";
      }
    });

    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
    });
    this.onTabReload();
    this.route.params.subscribe(() => {
      this.initProductRelease();
    });

    //const subject = webSocket('ws://localhost:8080');

    //subject.subscribe({
    //  next: msg => console.log('message received: ' + msg), // Called whenever there is a message from the server.
    //  error: err => console.log(err), // Called if at any point WebSocket API signals some kind of error.
    //  complete: () => console.log('complete') // Called when connection is closed (for whatever reason).
    //});
    setTimeout(() => {
      if (!this.standaloneMode && this.isRemoted && this.tabModeTitle== "remote" && this.isOnline) {
        console.log("Open Data Channel 123456 Message");
        this.dataChannelInit();
      }
      
    }, 200);

    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        )
        .subscribe((result: EventMessage) => {
            const payload = result.payload as AuthenticationResult;
            this.authService.instance.setActiveAccount(payload.account);
        });

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None)
        )
        .subscribe(() => {
            this.setLoginDisplay();
            this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
        });
    if(this.isLargeSCreen()){
      this.setLeftPageMinHeightOnInit();
    }
  }

  ngAfterContentInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="tooltip"]').hover(function(){
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  setLoginDisplay() {
    this.loginDisplayB2c = this.authService.instance.getAllAccounts().length > 0;
  }

  getClaims(claims: any) {
    if (claims) {
        const claimsTable = createClaimsTable(claims);
        this.dataSource = [...claimsTable];
    }
  }

  dataChannelInit(): void {

    //creating data channel 
    if (!this.remoteService.mapDataChannels.has(this.channelName)) {
      this.loadingWindow.show("loading", "Aladdin Application");
      let dataChannelCreated = this.remoteService.yourConn.createDataChannel(this.channelName);
      this.remoteService.mapDataChannels.set(this.channelName, dataChannelCreated);
    }
  
    this.remoteService.mapDataChannels.get(this.channelName).onerror = (error) => { 
      console.log("Ooops...error:", error); 
    }; 
  
    //when we receive a message from the other peer, display it on the screen 
    this.remoteService.mapDataChannels.get(this.channelName).onmessage = (event) => {
      let data = JSON.parse(event.data); 
      if(data.function === this.sendTerminalResponse) {
        let stringMessage = data.response;

        console.log("Data Channel Message:", stringMessage);
        this.setTerminalContent(stringMessage);
        var t = window.document.getElementById("terminalText");
        t.click();
        t.scroll(0,t.scrollHeight);
      } else if(data.function === this.writeConfigurationResponse) {
        let stringMessage = data.status;
        //call java function from javascript
        if (stringMessage === "writeOkay") {
          this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.readConfigurationRequest + '"}'); 
        }
      } else if(data.function === this.readConfigurationResponse) {
        let stringMessage = data.content;
        if (stringMessage !== "" && stringMessage !== "[]") {
          this.setAllProductParameterToDefault();
          for(let commandAndValue of stringMessage) {
            if(this.isInterfaceChangeParameter(commandAndValue.command)) {
              this.lastInterface = commandAndValue.value;
            } 
            this.setCurrentProductParameter(commandAndValue.command, commandAndValue.value);
          }
        }
        this.loadingWindow.hide();
        var t = window.document.getElementById("release");
        t.click();
      }
    }; 
  
    this.remoteService.mapDataChannels.get(this.channelName).onclose = () => { 
      console.log("data channel is closed"); 
    };
  }
  
  triggerDataChannelInit(): void {
  
    //when we receive a message from the other peer, display it on the screen 
    this.remoteService.mapDataChannels.get(this.channelName).onmessage = (event) => {
      let data = JSON.parse(event.data); 
      if(data.function === this.sendTerminalRequest) {
        let stringMessage = data.command;
        console.log("Data Channel Message:", stringMessage);

        if(this.isOnline && this.standaloneMode) {
          //call java function from javascript
          let responseFromAladdin = window.sendCommandFromJs(stringMessage);
        }
      } else if(data.function === this.writeConfigurationRequest) {
        if(this.isOnline) {
          let stringMessage: string = '[';
          let tmpList01 = data.content;
          for (let i=0; i<tmpList01.length; i++) {
            if (tmpList01[i].command && tmpList01[i].command.length > 0) {
              if (i === tmpList01.length - 1) {
                stringMessage += '{"command":"' + tmpList01[i].command + '","value":"' + tmpList01[i].value + '"}';
              } else {
                stringMessage += '{"command":"' + tmpList01[i].command + '","value":"' + tmpList01[i].value + '"},';
              }
            }
          }
          stringMessage += ']';
          
          //call java function from javascript
          let responseFromAladdin = window.writeConfigurationHandlerFromJs(stringMessage);

          this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.writeConfigurationResponse
                                                                      + '","status":"' + responseFromAladdin + '"}'); 
        }

      } else if(data.function === this.readConfigurationRequest) {
        if(this.isOnline) {
          //call java function from javascript
          let responseFromAladdin = window.readConfigurationHandlerFromJs("readRequest");
          
          this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.readConfigurationResponse
                                                                      + '","content":' + responseFromAladdin + '}'); 
        }

      }
    }; 
  
    this.remoteService.mapDataChannels.get(this.channelName).onclose = () => { 
      console.log("data channel is closed"); 
    };
  }

  clickOnSendTerminalButtonHandler(sendCommand: string): void {
    if (!this.standaloneMode && this.isRemoted && this.tabModeTitle=="remote" && this.isOnline) {
      //sending a message to a connected peer 
      this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.sendTerminalRequest
                                                                 + '","command":"' + sendCommand + '"}');
    }
  }

  clickOnReadConfigurationButtonEvent(): void {
    if (!this.standaloneMode && this.isRemoted && this.tabModeTitle=="remote" && this.isOnline) {
      this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.readConfigurationRequest + '"}');
    } else if(this.tabModeTitle == 'azure') {
      this.readConfigurationFromAzure();
    }
  }

  clickOnWriteConfigurationButtonEvent(): void {
    if (!this.standaloneMode && this.isRemoted && this.tabModeTitle=="remote" && this.isOnline) {
      let val = '[';
      for (let i = 0; i < this.modifiedParameters.length; i++) {
        if (i === this.modifiedParameters.length - 1) {
          val += '{"command":"' + this.modifiedParameters[i].code + '","value":"' + this.modifiedParameters[i].value + '"}';
        } else {
          val += '{"command":"' + this.modifiedParameters[i].code + '","value":"' + this.modifiedParameters[i].value + '"},';
        }
      }
      val += ']';

      this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.writeConfigurationRequest
                                                                  + '","content":' + val + '}');
    } else if(this.tabModeTitle == "azure") {
      this.writeConfigurationToDeviceByAzure();
    }
  }

  getCurrentConfigAfterOpenTab(responseFromAladdin: String) {
    this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.readConfigurationResponse
                                                                 + '","content":' + responseFromAladdin + '}'); 
  }







  getCurrentProductParameters(): string {
    return this.data.parameters.filter(it => it.code !== undefined && it.code !== '').map(it => it.code).join(',');
  }

  getCurrentChangedParameters(): string {
    const packParameters = [];
    const commandsStrings = [];
    for (const param of this.modifiedParameters) {
      if (param.isPackParameter) {
        packParameters.push(param);
      } else {
        commandsStrings.push(param.getCommandForDevice());
      }
    }

    let uleCommandsString = '';
    for (const packParam of packParameters) {
      uleCommandsString += '$' + this.preCmd + packParam.getCommandForDevice() + '\n';
    }

    if (uleCommandsString.length > 0) {
      const ule = window.af2ule(uleCommandsString);
      
      const commands = window.ule2barcodeCmds(ule, 16, this.family);
      for (const command of commands) {
        commandsStrings.push(command);
      }
    }

    return commandsStrings.join(',');
  }

  cloneProductComponent(): ProductComponent {
    let productComponent = new ProductComponent(this.dataService, this.xmlService, this.route, this.router, this.zone, this.tabNavigationService, 
      this.customManualService, this.translate, this.remoteService, this.settingsService, this.http,
      this.authService, this.msalBroadcastService, this.configService, this.remoteManagementService, this.elementsService);
    productComponent.data = this.data;
    productComponent.family = this.family;
    productComponent.isOnline = false;
    return productComponent;
  }

  getPreCmd(): string {
    return this.preCmd;
  }

  getCurrentUleParameters(): string {
    return this.modifiedParameters.filter(it => it.isPackParameter && !it.disabled).map(it => it.getCommandForDevice()).join(',');
  }

  setAllProductParameterToDefault(): void {
    var parameterEditors : Parameter[] = this.data.parameters;
    this.setProductParameterToDefault(parameterEditors);
  }

  setProductParameterToDefault(pEditors: Parameter[]): void {
    for (var pEditor of pEditors) {
      if (pEditor.childParameters.length > 0) 
      {
        this.setProductParameterToDefault(pEditor.childParameters);
      }
      else
      {
        var value = pEditor.defaultValue;
        pEditor.value = value;
        pEditor.deviceValue = value;
        this.onParamValueChanged(pEditor.name);
      }
    }
  }

  // set the actual release loaded on the device (online only)
  setDeviceRelease(release: string): void {
    this.deviceRelease = release;
  }

  setCurrentProductParameter(code: string, value: string, setDeviceValue: boolean = true, resetULEFollowingAF: boolean = true): void {
    // The parameter this.lastInterface should not be changed here.
    // The parameter this.lastInterface will be changed when the function onParamValueChanged() is executed
    let hideValueParam = this.data.parameters.find(p => p.code === code && p.hideValue === true);
    if(hideValueParam){
      value = hideValueParam.defaultValue;
    }

    let paramEditor = null;
    if (this.paramEditors) 
    {
      paramEditor = this.paramEditors.find(it => it.parameter && it.parameter.code === code);
    }
    if(paramEditor && value)
    {
      paramEditor.changeInputValue(value, setDeviceValue);
    } 
    else 
    {
      var pEditor : Parameter = this.data.parameters.find(it => it.code === code);
      if (pEditor && pEditor.childParameters.length > 0 && value) 
      {
        for (let index = 0; index < pEditor.childParameters.length; index++) 
        {
          if (pEditor.type === "sumHex") {
            let numInput: number = parseInt(value, 16);
            for (let index = 0; index < pEditor.childParameters.length; index++) {
              let strOption = pEditor.childParameters[index].options[1].value;
              let numOption: number = parseInt(strOption, 16);
              let numCheck: number = numInput & numOption;
              if (numCheck && numCheck !== 0) {
                pEditor.childParameters[index].value = strOption;
      
                if (setDeviceValue) {
                  pEditor.childParameters[index].deviceValue = strOption;
                }
      
                this.onParamValueChanged(pEditor.name, resetULEFollowingAF);
              } else {
                pEditor.childParameters[index].value = pEditor.childParameters[index].options[0].value;
      
                if (setDeviceValue) {
                  pEditor.childParameters[index].deviceValue = pEditor.childParameters[index].options[0].value;
                }
      
                this.onParamValueChanged(pEditor.name, resetULEFollowingAF);
              }
            }
          } else {
            let valueStartingIndex:number = 0;
            let valueEndIndex:number = 0;
            
            for (let index = 0; index < pEditor.childParameters.length; index++) 
            {
              valueEndIndex = Number(valueStartingIndex) + Number(pEditor.childParameters[index].size);
              const subvalue = value.substring(valueStartingIndex,  valueEndIndex);
              if (subvalue && pEditor.childParameters[index])
              {
                pEditor.childParameters[index].value = subvalue;
      
                if (setDeviceValue) {
                  pEditor.childParameters[index].deviceValue = subvalue;
                }
      
                this.onParamValueChanged(pEditor.name, resetULEFollowingAF);
              } 
              
              valueStartingIndex = Number(valueStartingIndex) + Number(pEditor.childParameters[index].size);
            }
          }
        }
      }
      else
      {
        const parameter = this.data.parameters.find(it => it.code === code);
        if (parameter) 
        {
          parameter.value = value;
          if (setDeviceValue) 
          {
            parameter.deviceValue = value;
          }
          this.onParamValueChanged(parameter.name, resetULEFollowingAF);
        }
      }
    }
  }

  setCertificate(certificate: string): void {
    this.data.currentCertificate = certificate;
  }

  getCurrentCertificateCode(): string {
    return this.currentConfig.configurationElements[0].code;
  }

  getMaxSizeCertificate(): string {
    return this.currentConfig.configurationElements[0].maxSize;
  }

  getPackageSizeCertificate(): string {
    return this.currentConfig.configurationElements[0].packageSize;
  }

  setUleScript(ule: string, improveTextDisplaying: boolean = false): void {
    this.data.currentUle = ule;
    if (!this.plainTextCheckBoxStatus && improveTextDisplaying) {
      let formatUle = this.plainTextFormatForUle(this.data.currentUle);
      this.data.currentUle = this.richTextFormatForUle(formatUle);
    }

    // update packParamater. 
    const packParametersModifier = this.modifiedParameters.filter(it => it.isPackParameter);
    for (const param of packParametersModifier) {
        param.deviceValue = param.defaultValue;
        this.onParamValueChanged(param.name, false);
        const paramEditor = this.paramEditors.find(it => it.parameter && it.parameter.name == param.name);
        if(paramEditor) {
          paramEditor.changeInputValue(param.defaultValue, true);
        }
    }
    // when ULE is empty, change params to default
    if(ule === ""){
      const packParameters = this.notDefaultParameters.filter(it => it.isPackParameter);
      for (const param of packParameters) {
        var value = param.defaultValue;
        param.value = value;
        param.deviceValue = value;
        this.onParamValueChanged(param.name, false);
      }
      window.setConfigInfoToDefault();      
    }
  }

  onPlainTextCheckboxChange(event: any): void {
    this.plainTextCheckBoxStatus = event.target.checked;
    if (event.target.checked) {
      this.data.currentUle = this.plainTextFormatForUle(this.data.currentUle);
    } else {
      let formatUle = this.plainTextFormatForUle(this.data.currentUle);
      this.data.currentUle = this.richTextFormatForUle(formatUle);
    }
  }

  plainTextFormatForUle(ule: string): string {
    let sb: string = "";
    let inComment: boolean = false;
    let inString: boolean = false;
    for (let c of ule) {
      if (!inString) {
        if (c == ';' || c == '#') {
          inComment = true;
        } else if (c.charCodeAt(0) == 0x0A || c.charCodeAt(0) == 0x0D) {
          inComment = false;
        }
      }
      if (!inComment) {
        if (c.charCodeAt(0) != 0x0D && c.charCodeAt(0) != 0x09) {
          if (c == '"') {
            inString = !inString;
          }
          if (c.charCodeAt(0) != 0x20 || inString) {
            sb += c;
          }
        }
      }
    }
    sb = sb.trim();
    sb = sb.replace(/(\r\n|\r|\n){2,}/g, '$1');
    return sb;
  }

  richTextFormatForUle(ule: string): string {
    let sb: string = "";
    let bracesIndex: number = 0;
    let bracesRightBefore: boolean = false;
    let newlineRightBefore: boolean = false;
    for (let c of ule) {
      if (c == '{') {
        sb += ' '.repeat(bracesIndex*4);
        newlineRightBefore = false;
        bracesRightBefore = true;
        bracesIndex += 1;
      } else if (c == '}') {
        if (!newlineRightBefore) {
          sb += '\n'
        }
        newlineRightBefore = false;
        bracesRightBefore = true;
        bracesIndex -= 1;
        sb += ' '.repeat(bracesIndex*4);
      } else if (c == '\n' || c == '\r') {
        newlineRightBefore = true;
        bracesRightBefore = false;
      } else {
        if (bracesRightBefore) {
          sb += '\n'
          if (bracesIndex > 0) {
            sb += ' '.repeat(bracesIndex*4);
          }
        } else if (newlineRightBefore && bracesIndex > 0) {
          sb += ' '.repeat(bracesIndex*4);
        }
        newlineRightBefore = false;
        bracesRightBefore = false;
      }
      sb += c;
    }
    return sb;
  }

  changeRelease(index: string): void {
    this.data.properties.forEach((value: string, key: string) => {
      this.currentProduct.nativeElement.removeAttribute('data-' + key.toLowerCase());
    });

    this.tabNavigationService.closeTab(this.tabSampleElement);
    if (this.tabModeTitle) {
      this.router.navigate(['/configuration/product/' + index + '/' + this.connectedTo? this.connectedTo: 'null' + '/' + this.tabModeTitle], {queryParams:{id: this.serialNumber}});
    } else {
      this.router.navigate(['/configuration/product/' + index + '/' + this.connectedTo]);
    }
  }

  // Menu
  onParamNodeClicked($event) {
    // Config clicked name
    const configToGet = $event.node.data.id;
    // Config clicked obj
    this.currentConfig = this.data.configurationPages.find(it => it.id === configToGet);
    this.parseConfigsToBlocks();
    this.parseConfigElements();

    // Set help for current section
    this.setHelp();

    // Clear this.data.currentCertificate
    this.setCertificate("");
    if(this.isCurrentConfigHideValue()){
      this.setCertificate(this.hiddenString);
    }

    // If i have at least one parameter clear enable
    this.clearVisible =
      this.currentConfig.configurationElements.find(it =>
                                                    it.type === 2 &&
                                                    it.name !== 'JEditUle' &&
                                                    it.name !== 'JEditCertificate' &&
                                                    it.name !== 'JEditCustomConfigFile' &&
                                                    it.name !== 'JSmartBattery' &&
                                                    it.name !== 'JStatisticPage' &&
                                                    this.currentConfig.id !== 'StatisticPage.pnl') !== undefined;
  }

  refreshSearch(value): void {
    // Refresh menu filter
    this.menuSections.forEach(section => section.updateTree(value));

    if (value.length >= 1) {
      // Get configuration pages with parameters that contains searchvalue
      this.searchedParams = this.data.configurationPages.reduce((accumulator, currentValue) => {
        const confElements = currentValue.configurationElements.filter(ce => this.findParameterForSearch(ce, value));
        accumulator = accumulator.concat(confElements.map(it => {
          return new SearchItem(currentValue.id, currentValue.name, it.parameter);
        }));
        return accumulator;
      }, []);
    } else {
      this.searchedParams = [];
    }
  }

  onSearchItemClick(search: SearchItem): void {
    // update history here
    this.updateSearchHistory(search);

    // Set the config part
    let id = search.id;
    this.onParamNodeClicked({node: {data: {id}}});
    this.searchedParams = [];
    this.searchValue = '';
    this.refreshSearch(this.searchValue);

    // Set the menu
    const section = this.menuSections.find(sectionItem => {
      return sectionItem.item.name == search.id  || !!sectionItem.treeComponent.treeModel.getNodeById(search.id);
    });
    if(section) {
      section.openTree(search.id);
    }
    // 
    this.searchParam = search.parameter;
    this.isOnSearchInputClick = false;
  }

  deleteSearchItem(code: string):void{
    this.searchHistory = this.searchHistory.filter(el => el.parameter.code !== code);
    this.data.searchHistory = this.searchHistory;
  }

  // Params
  onParamValueChanged(name: string, resetULEFollowingAF: boolean = true): void {
    // Execute rules to activate/deactivate/setvalue to other parameters
    this.data.executeParameterRules(name, true);

    const parameter = this.data.parameters.find(it => it.name === name);
    if (parameter) {
      this.updateNotDefaultParameters(parameter);
      this.updateModifiedParameters(parameter);
      this.updateCustomManualItems(parameter);

      const packParameters = this.notDefaultParameters.filter(it => it.isPackParameter);

      if (packParameters.length > 0) {
        let uleCommandsString = '';
        for (const packParam of packParameters) {
          uleCommandsString += '$C' + packParam.getCommandForDevice() + '\n';
        }

        if (uleCommandsString.length > 0) {
          if (resetULEFollowingAF) {
            const ule = window.af2ule(uleCommandsString);
            this.data.currentUle = ule;
          }
        }
      } else {
        this.data.currentUle = '';
      }

      if (this.isInterfaceChangeParameter(parameter.code) && (parameter.value !== this.lastInterface)) {
        if (this.currentProduct) {
          this.currentProduct.nativeElement.dispatchEvent(this.interfaceChangeEvent);
        }
        this.lastInterface = parameter.value;

        // Update default value following the current interface
        if (parameter.options !== undefined && parameter.options.length > 0) {
          if (parameter.options.find(it => it.value === this.lastInterface)) {
            let currentInterfaceName = parameter.options.find(it => it.value === this.lastInterface).value;
            this.updateDefaultValueFollowingCurrentInterface(currentInterfaceName);
          }
        }
      }

      // Save product model in case of tab change
      this.xmlService.saveProductEditedData(this.data);
    }
  }

  updateNotDefaultParameters(parameter: Parameter): void {
    if (parameter.defaultValue !== parameter.value) {
      if (parameter.code != undefined) {
        let tempParameterCommand = parameter.getCommandForDevice();
        //Do not add the parameter in the notDefaultParameters array if it does not contain any command
        if (tempParameterCommand) {
          // Add to correct list/s
          const itemIndex = this.notDefaultParameters.findIndex(it => it.code === parameter.code);
          if (itemIndex >= 0) {
            this.notDefaultParameters[itemIndex] = parameter;
          }
          else {
            this.notDefaultParameters.push(parameter);
          }
        }
      }
    } else {
      // Remove from modified
      const itemIndex = this.notDefaultParameters.findIndex(it => it.code === parameter.code);
      if (itemIndex >= 0) {
        this.notDefaultParameters.splice(itemIndex, 1);
      }
    }
  }

  updateModifiedParameters(parameter: Parameter): void {
    if (parameter.deviceValue !== parameter.value) {
      if (parameter.code != undefined) {
        let tempParameterCommand = parameter.getCommandForDevice();
        //Do not add the parameter in the modifiedParameters array if it does not contain any command
        if (tempParameterCommand) {
          // Add to correct list/s
          const itemIndex = this.modifiedParameters.findIndex(it => it.code === parameter.code);
          if (itemIndex >= 0) {
            this.modifiedParameters[itemIndex] = parameter;
          }
          else {
            if (this.isInterfaceChangeParameter(parameter.code)) {
              this.modifiedParameters.unshift(parameter);
            } else {
              this.modifiedParameters.push(parameter);
            }
          }
        }
      }
    } else {
      // Remove from modified
      const modItemIndex = this.modifiedParameters.findIndex(it => it.code === parameter.code);
      if (modItemIndex >= 0) {
        this.modifiedParameters.splice(modItemIndex, 1);
      }
    }
  }

  updateCustomManualItems(parameter: Parameter, checkToggle: boolean = false): void {
    if (parameter.value != undefined) {
      // Add to correct list/s
      this.customManualService.updateCustomManualItems(parameter, checkToggle, this.product.internalName);
    }
  }


  updateDefaultValueFollowingCurrentInterface(currentInterfaceName: string): void {
    for (let j = 0; j < this.data.configurationPages.length; j++) {
      for (let l = 0; l < this.data.configurationPages[j].configurationElements.length; l++) {
        if(this.data.configurationPages[j].configurationElements[l].parameter !== undefined) {
          this.data.configurationPages[j].configurationElements[l].parameter.setValueFollowingInterface(currentInterfaceName, this.isOnline,
                                  this.isInterfaceChangeParameter(this.data.configurationPages[j].configurationElements[l].parameter.code));
                                  
          this.updateNotDefaultParameters(this.data.configurationPages[j].configurationElements[l].parameter);
          this.updateModifiedParameters(this.data.configurationPages[j].configurationElements[l].parameter);
        }
      }
    }
  }

  isInterfaceChangeParameter(code: string) {
    return code === this.interfaceChangeParameter();
  }

  interfaceChangeParameter() {
    if (this.family === 'FRS') {
      return '0001';
    } else {
      return '$hA';
    }
  }

  getInitialChangeInterfaceCmd() {
    if (this.family === 'FRS') {
      return '0001';
    } else {
      return '$HA';
    }
  }

  onToggleFromCustomManual(name: string): void {
    const parameter = this.data.parameters.find(it => it.name === name);
    if(parameter) {
      this.updateCustomManualItems(parameter, true);
    }
  }

  onCodeEditorValueChanged(value: any): void {
    if (this.currentConfig.configurationElements[0].name === 'JEditUle') {
      this.data.currentUle = value;
    } else {
      this.data.currentConfigFile = value;
    }
  }

  onCertificateEditorValueChanged(value: any): void {
    if (this.currentConfig.configurationElements[0].name === 'JEditCertificate') {
      this.data.currentCertificate = value;
    }
  }

  // Help internal link click
  onHelpRaisedEvent(event: any): void {
    this.dataService.getHelpContent(this.helpItem.basePath, 'Html/' + event.args)
    .subscribe(currentHelp => {
      this.helpText = currentHelp;
    });
  }

  // Buttons
  toggleCustomManual(): void {
    this.customManualEnabled = !this.customManualEnabled;
    $('[data-toggle="tooltip"]').tooltip('hide');
  }

  openCustomManual(): void {
    this.refreshCommands();
    this.customManual.show();
  }

  generateBarcode(): void {
    this.refreshCommands();
    this.pdfPreview.show();
  }

  showCompareConfiguration(): void {
    this.refreshCommands();

    this.compareConfiguration.show();
  }

  loadConfiguration(): void {
    this.acceptedFiles = '.xml';
    setTimeout(() => {
      this.fileInput.nativeElement.click();
    }, 50);
  }

  saveConfiguration(): void {
    this.xmlService.getXmlConfigAsString(this.product)
    .subscribe((content: string) => {
      const concat = this.modifiedParameters.filter(mp => this.notDefaultParameters.find(nd => nd.code === mp.code) === undefined);
      const changedParams = this.notDefaultParameters.concat(concat);

      let contentWithValues = content.replace('</device>', '');
      contentWithValues += '  <values>\n';
      for (const param of changedParams) {
        contentWithValues += '    <value code="' + param.code + '">' + param.value + '</value>\n';
      }
      contentWithValues += '  </values>\n';
      contentWithValues += '</device>';

      const blob = new Blob([contentWithValues], {
        type: 'application/xml;character=utf-8'
      });
      saveAs(blob, 'config_' + this.product.internalName + '.xml');
    });
  }

  clearParameters(): void {
    if (this.paramEditors && this.paramEditors.length > 0) {
      for (const param of this.paramEditors) {
        param.resetToDefault();
      }
    }

    if (this.driverLicenseEditors && this.driverLicenseEditors.length > 0) {
      for (const param of this.driverLicenseEditors) {
        param.resetToDefault();
      }
    }
  }

  refreshParameterLabels(): void {
    if (this.paramEditors && this.paramEditors.length > 0) {
      for (const param of this.paramEditors) {
        param.refreshLabel();
      }
    }
  }

  // Smart battery
  addSmartBatteryRow(name: string, value: string): void {
    this.data.smartBattery.push({
      name,
      value
    });
  }

  clearSmartBattery(): void {
    this.data.smartBattery = [];
  }

  // Statistic Page
  addStatisticPageRow(code: string, name: string, value: string): void {
    this.data.statisticPage.push([code, name, value]);
  }

  clearStatisticPage(): void {
    this.data.statisticPage = [];
  }

  setProductPageOffline(): void {
    this.viewImageEnabled = false;
    this.isOnline = false;
  }

  // Files
  loadFile(): void {
    if (this.currentConfig.configurationElements.find(it =>
      it.type === 2 && it.name === 'JEditUle')) {
      this.acceptedFiles = '.txt,.ule,.ule.txt';
    }

    if (this.currentConfig.configurationElements.find(it =>
      it.type === 2 && it.name === 'JEditCustomConfigFile')) {
      this.acceptedFiles = '.txt,.tex,.tex.txt';
    }

    setTimeout(() => {
      this.fileInput.nativeElement.click();
    }, 50);
  }

  loadedFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.executeLoadedFile(e.target.result);
      };

      reader.readAsText(fileInput.target.files[0]);

      this.fileInput.nativeElement.value = '';
    }
  }

  saveToFile(): void {
    let content = '';
    if (this.currentConfig.configurationElements[0].name === 'JEditUle') {
      content = this.data.currentUle;
    } else if (this.currentConfig.configurationElements[0].name === 'JEditCertificate') {
      content = this.data.currentCertificate;
    } else {
      content = this.data.currentConfigFile;
    }

    const blob = new Blob([content], {
      type: 'text/plain;character=utf-8'
    });
    
    if (!(this.currentConfig.configurationElements[0].name === 'JEditUle')) {
      if (this.family === "FRS") {
        if (this.lastGeneratedCCFName === "") {
          saveAs(blob, 'Datalogic.tex');  
        } else {
          saveAs(blob, this.lastGeneratedCCFName + '.tex');
        }
      } else {
        saveAs(blob, 'Datalogic.txt');
      }
    } else {
      saveAs(blob, 'Datalogic.txt');
    }
  }

  // Attribute methods
  isEditorSelected(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 &&
           this.currentConfig.configurationElements[0].type === 2 &&
           (this.currentConfig.configurationElements[0].name === 'JEditUle' ||
            this.currentConfig.configurationElements[0].name === 'JEditCustomConfigFile');
  }

  isCCFSelected(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 &&
           this.currentConfig.configurationElements[0].type === 2 &&
           this.currentConfig.configurationElements[0].name === 'JEditCustomConfigFile';
  }

  isULESelected(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 &&
           this.currentConfig.configurationElements[0].type === 2 &&
           this.currentConfig.configurationElements[0].name === 'JEditUle';
  }

  isCertificateSelected(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 &&
           this.currentConfig.configurationElements[0].type === 2 &&
           this.currentConfig.configurationElements[0].name === 'JEditCertificate';
  }

  isScriptingJSSelected(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 &&
           this.currentConfig.configurationElements[0].type === 2 &&
           this.currentConfig.configurationElements[0].name === 'JEditUle' &&
           this.currentConfig.configurationElements[0].text === 'User Scripting JS';
  }

  isCurrentConfigHideValue(): boolean {
    return this.currentConfig &&
      this.currentConfig.configurationElements.length > 0 &&
      this.currentConfig.configurationElements[0].hideValue === true;
  }

  isHideReadCertificationButton(): boolean{
    let isHide = true;
    let isCertSelected = this.isCertificateSelected();
    let isHideValue = this.isCurrentConfigHideValue();
    if(isCertSelected === true && isHideValue === false){
      isHide = false;
    }
    return isHide;
  }

  hidePlainTextCheckBox(): boolean {
    return !this.isULESelected() || this.isScriptingJSSelected();
  }

  @HostListener('click') onClick(){
    if (this.searchedParams.length > 0) {
      this.searchedParams = [];
    }
  }

  initProductRelease(index: number = -1): void {
    
    this.isOnline = false;
    this.connectedTo = '';
    this.searchValue = '';
    this.selectedReleaseIndex = 0;
    this.customManualEnabled = false;
    this.customManualLength = 0;
    this.clearVisible = false;
    this.currentConfig = undefined;
    this.notDefaultParameters = [];
    this.modifiedParameters = [];
    this.pdfCommands = [];
    this.helpItems = [];
    this.helpItem = undefined;
    this.helpText = '';
    this.paramMenu = [];
    this.serialNumber = "";
    this.tabModeTitle = "";

    // Setting online variable
    const usbCom = this.route.snapshot.paramMap.get('usbcom');
    this.isOnline = usbCom !== null && usbCom !== "null";

    this.connectedTo = usbCom !== null && usbCom !== "null" ? usbCom.toUpperCase() : '';

    const mode = this.route.snapshot.paramMap.get('mode');
    if (mode) {
      this.tabModeTitle = mode;
    }
    const temp = this.route.snapshot.queryParamMap.get('id');
    if(temp) {
      this.serialNumber = temp;
    }
    this.selectedReleaseIndex = parseInt(this.route.snapshot.paramMap.get('index'), 10);
    if (index > 0) 
    {
      this.selectedReleaseIndex = index;
    }
    this.channelName = this.selectedReleaseIndex + "/" + this.connectedTo;
    
    this.dataService.getProduct(this.selectedReleaseIndex)
    .subscribe(product => {
      // Content ready
      this.product = product;

      if (this.selectedReleaseIndex >= 0 && !this.standaloneMode) {
        // Add to recently viewed
        if (this.isOnline) {
          this.dataService.saveProductToRecentlyViewed(product, this.connectedTo);
        } else {
          this.dataService.saveProductToRecentlyViewed(product, "OFFLINE");
        }
      }

      // Subscribe to custom manual service for changes
      this.customManualService.getCustomManualSubscriber()
      .subscribe((results) => {
        this.customManualLength = this.customManualService.getByProduct(this.product.internalName).length;
      });

      const productInternalName = product.internalName + (this.selectedReleaseIndex < 0 ? '_' + this.selectedReleaseIndex : '');
      // Wait for more than one action
      forkJoin([
        this.xmlService.getXmlConfig(productInternalName, product, this.connectedTo),
        this.dataService.getProductHelp(product)
      ]).subscribe((results) => {
        this.data = results[0];

        if (this.data.parsingResult['@preCmd'] !== undefined) {
          this.preCmd = this.data.parsingResult['@preCmd'];
        } else {
          this.preCmd = 'C';
        }

        if (this.data.parsingResult['@enterConfigurationCmd'] !== undefined) {
          this.enterConfigurationCmd = this.data.parsingResult['@enterConfigurationCmd'];
        } else {
          this.enterConfigurationCmd = 'P';
        }
        
        if (this.data.parsingResult['@exitConfigurationCmd'] !== undefined) {
          this.exitConfigurationCmd = this.data.parsingResult['@exitConfigurationCmd'];
        } else {
          this.exitConfigurationCmd = 'P';
        }

        this.helpItems = results[1];
        this.paramMenu = this.data.paramMenu.slice(this.isOnline ? 0 : 1);
        this.setDefaultPage();

        if (this.selectedReleaseIndex < 0) {
          this.product.name = this.data.parsingResult['@name'];
          this.product.releases[0].name = this.data.parsingResult['@name'] + '_' + this.data.parsingResult['@releaseSW'];
        }

        this.currentProduct.nativeElement.getAttributeNames().forEach((key: string) => {
          if (key.startsWith('data-')) {
            this.currentProduct.nativeElement.removeAttribute(key);
          }
        });

        // Set current product attributes
        this.data.properties.forEach((value: string, key: string) => {
          if (key === 'disableImageCapture') {
            this.viewImageEnabled = value === 'false' && this.isOnline;
          }

          if (key === 'family') {
            this.family = value;
            this.customManual.family = value;
          }

          this.currentProduct.nativeElement.setAttribute('data-' + key, value);
        });
        // set default family to HHS
        if (this.data.properties.get('family') == undefined) {
          this.family = "HHS";
          this.customManual.family = "HHS";
        }

        // build table of MCF numbers to use in release dropdown
        if (this.family === "FRS") {
          for (var release of product.releases) {
            this.xmlService.getMCFNumber(release.name)
            .subscribe((retObj) =>{
              this.releaseToMCF.set(retObj['productName'],retObj['mcf']);
            });
          }
        }

        // Add to tab navigation
        this.tabSampleElement = TabItem.fromProduct(product, usbCom, this.tabModeTitle, this.serialNumber);
        this.tabNavigationService.addTab(this.tabSampleElement);
        this.tabNavigationService.setCurrentTab(this.tabSampleElement);
        this.tabTerminalContents = this.getTerminalContent();

        const interfChangeParamCode = this.interfaceChangeParameter();
        const interfChangeParam = this.data.parameters.find(it => it.code === interfChangeParamCode)
        if (interfChangeParam !== undefined) {
          this.onParamValueChanged(interfChangeParam.name);
        }

        // Find if there are already different parameters
        const differentParams = this.data.parameters.filter(it =>
          it.value !== it.defaultValue || it.value !== it.deviceValue ||
          it.deviceValue !== it.defaultValue);

        for (const param of differentParams) {
          this.onParamValueChanged(param.name);
        }

        if (this.selectedReleaseIndex < 0 && this.xmlService.genericDeviceContent.length > 0) 
        {
          this.acceptedFiles = '.xml'; 
          this.executeLoadedFile(this.xmlService.genericDeviceContent);
          this.xmlService.genericDeviceContent = '';
        }   


        this.data.configurationPages.forEach((element) => {
          element.configurationElements.map((value, index, arr) => {
            if(index > 0 && !!value.parameter && !value.parameter.label && arr[index -1].type === 1) {
              value.parameter.text = arr[index - 1].text;
            }
          })
        });

        this.searchHistory = this.data.searchHistory;
        this.getAllInterfaceCommands();
        this.readConfigurationFromAzure();
      });
    });
  }

  public getAllInterfaceCommands(): void{
    if(this.family === "FRS"){
      let interfaceFRSObject = this.data.interfaceClasses;
      this.interfaceParams = Object.keys(interfaceFRSObject).map(key => interfaceFRSObject[key]);
      let cmd = this.getInitialChangeInterfaceCmd();
      this.interfaceParams.forEach(el =>{
        this.allInterfaceParamsCommands.push(cmd + el);
      })      
    }else{
      let interfConfigPage = this.data.configurationPages.find(el => el.name == "Interface Selection");
      if (interfConfigPage) {
        let interfaces = interfConfigPage.configurationElements[1].parameter.options;
        interfaces.forEach(interf => {
          this.allInterfaceParamsCommands.push(interf.command);
        })
      }
    }
  }

  public releaseValue(name: string): string {
    const values = name.split('_');
    let release = values[values.length - 1];

    if (this.family === "FRS") {
      let mcf = this.releaseToMCF.get(name);
      if (mcf === undefined) {
        return release;
      } else {
        return release + " (MCF: " + mcf + ")";
      }
    } else {
      return release;
    }
  }

  private setDefaultPage(): void {
    if (this.data && this.data.configurationPages && this.data.configurationPages.length) {
      if (this.isOnline && !this.tabModeTitle) {
        if ((this.data.configurationPages[0].id === 'Configuration.pnl') || this.data.configurationPages[0].id === 'configuraton.pnl') {
          this.currentConfig = this.data.configurationPages[0];
          this.parseConfigsToBlocks();
          this.parseConfigElements();
          // Set default page help
          this.setHelp();
        }
      } else {
        this.currentConfig = this.data.configurationPages[1];
        this.parseConfigsToBlocks();
        this.parseConfigElements();
        // Set default page help
        this.setHelp();
      }
    }
  }

  private setHelp(): void {
    const helpItem = this.helpItems.find(it => it.id === this.currentConfig.tocId);
    if (helpItem) {
      this.dataService.getHelpItemContent(helpItem)
      .subscribe(currentHelp => {
        this.helpText = currentHelp;
        this.helpItem = helpItem;
      });
    }else{
      this.helpText = this.noHelpDataMessage;
    }
  }

  private refreshCommands(): void {
    this.interfaceCommand = null;
    let programmingCommands = this.notDefaultParameters.filter(param => !param.isNoLabelWrite);
    let checknterfaceInCommands = -1;

    for(const [key, value] of Object.entries(ScannerInterfaceType)) {
      checknterfaceInCommands = programmingCommands.findIndex((param) => param.label?.includes(value));
      if(checknterfaceInCommands > -1) break;
    }
    
    if(checknterfaceInCommands == -1) {
      let interfaceParamater = undefined;
      for(const [key, value] of Object.entries(ScannerInterfaceType)) {
        interfaceParamater = this.data.parameters.find((param) => param.label?.includes(value));
        if(interfaceParamater) break;
      }

      if(interfaceParamater) this.interfaceCommand = Command.fromParameter(interfaceParamater, this.translate, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd);
    }
    
    this.pdfCommands = programmingCommands.map(it => Command.fromParameter(it, this.translate, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd));
  }

  private parseConfigElements(): void {
    // With the obj -> fetch the parameters list
    for (const configElement of this.currentConfig.configurationElements) {
      const message = this.data.messages.find(it => it.name === configElement.name);
      if (message) {
        configElement.message = message;
      }
    }
  }

  private findParameterForSearch(input: ConfigurationElement, value: string): boolean {
    return input.type === 2 &&
          input.parameter &&
          (
            (input.parameter.label && input.parameter.label.toLowerCase().indexOf(value.toLowerCase()) >= 0) ||
            (input.parameter.code && input.parameter.code.toLowerCase().indexOf(value.toLowerCase()) >= 0)
          );
  }

  private executeLoadedFile(content: string): void {
    if (this.acceptedFiles === '.xml') {
      // Reset params
      const notDefaultParams = this.notDefaultParameters.slice();
      for (const param of notDefaultParams) {
        this.setCurrentProductParameter(param.code, param.defaultValue, false);
      }

      // Coinfig file, parse params and apply custom values
      const customValues = this.xmlService.getConfigValuesFromXmlContent(content);
      if (customValues.length > 0) {
        for (const value of customValues) {
          // Support to load the old format of <value> (2.3.7) for Advanced Formatting in xml file  
          if ((value['@code'] === "_ADV1" || value['@code'] === "_ADV2" || value['@code'] === "_ADV3" || value['@code'] === "_ADV4") &&
              value['#text'].startsWith("%24C") && value['#text'].endsWith("%0A")) {
            let tempString: string = value['#text'].substring(4,value['#text'].length-3);
            let tempStingList: string[] = tempString.split("%0A%24C");
            for (const tempStingElem of tempStingList) {
              let tempCodeStr: string = tempStingElem.substring(0, 4);
              let tempValueStr: string = tempStingElem.substring(4, tempStingElem.length);
              this.setCurrentProductParameter(tempCodeStr, tempValueStr, false);
            }
          } else {
            this.setCurrentProductParameter(value['@code'], value['#text'], false);
          }
        }
      }
    } else {
      this.onCodeEditorValueChanged(content);
    }
  }

  private setTerminalContent(value: string): void {
    this.tabNavigationService.tabStack.find(it => it.url === this.tabSampleElement.url).terminalContents.push(value);
    this.tabTerminalContents = this.getTerminalContent();

    if (this.remoteService.isRemoted && this.remoteService.mapDataChannels.has(this.channelName)) {
      this.remoteService.mapDataChannels.get(this.channelName).send('{"function":"' + this.sendTerminalResponse
                                                                   + '","response":"' + value + '"}'); 
    }
  }
  
  private getTerminalContent(): string[] {
    let result: string[] = this.tabNavigationService.tabStack.find(it => it.url === this.tabSampleElement.url).terminalContents;
    return result;
  }
  
  private clearTerminalContent(): void {
    this.tabNavigationService.clearTerminalContent(this.tabSampleElement);
    this.tabTerminalContents = this.getTerminalContent();
  }

  sendEventForCustomManualWithMessage(message: Message): void 
  {
    this.customManualService.toggleFromManualWithMessage(this.product.internalName, message, this.family, this.preCmd, this.enterConfigurationCmd, this.exitConfigurationCmd);
  }

  exportCCF(): void {
    // Only FRS is currently supported by this function
    this.exportCCFOptions.show(this);
  }

  importCCF(): void {
    // Only FRS is currently supported by this function
    new OfflineCCFImport(this).importCCF();
  }

  setUserName(value: string): void {
    this.userName = value;
  }

  setGeneratedCCFName(value: string): void {
    this.lastGeneratedCCFName = value;
  }
  
  getShowParameterCode(): boolean {
    return this.settingsService.getShowParameterCode();
  }

  getConfigCloud(): void {
    this.configService.getConfiguations().subscribe(
      (value) => {
        if(value) {
          this.webApiRes = value;
        }
      },
      (error) => {
        this.checkError(error, this.translate.instant('NOTIFICATION.GET-FAILED-SERVER-ERROR'));
      }
    )
  }

  showConfigService(): void {
    this.getConfigCloud();
    this.cloudSaveConfig.show();
  }
  saveConfigToCloud(name: string): Observable<Object> {
    return this.xmlService.getXmlConfigAsString(this.product).pipe(
      switchMap((value: string) => {
        const concat = this.modifiedParameters.filter(mp => this.notDefaultParameters.find(nd => nd.code === mp.code) === undefined);
        const changedParams = this.notDefaultParameters.concat(concat);
  
        let contentWithValues = value.replace('</device>', '');
        contentWithValues += '  <values>\n';
        for (const param of changedParams) {
          contentWithValues += '    <value code="' + param.code + '">' + param.value + '</value>\n';
        }
        contentWithValues += '  </values>\n';
        contentWithValues += '</device>';
  
        //Generate form data for POST request
        let formData = new FormData();
        formData.append('name', name);
  
        // Convert the string to a Blob object
        const blob = new Blob([contentWithValues], { type: 'application/octet-stream' });
        // Append the Blob to the FormData object
        formData.append('file', blob, 'filename.txt');
        //formData.append('data', contentWithValues);
  
        let currentProductInfo = this.product.internalName.split("_");
        let metadataPost ='{"product":"' + currentProductInfo[0] + '","releaseSW":"' + currentProductInfo[1] + '"}'
        formData.append('metadata', metadataPost);

        return this.configService.saveConfiguration(formData)
      }),
    )
  }

  saveBlobToCloud(name: string): void {
    this.saveConfigToCloud(name).pipe(
      switchMap((value: any) => {
        if(value?.id) {
          return this.configService.getConfiguations()
        } else return of(null);
      })
    )
    .subscribe(
      (content: any) => {
        if(content) {
          this.webApiRes = content;
        }
      },
      (error) => {
        this.checkError(error, this.translate.instant('NOTIFICATION.SAVE-FAILED-SERVER-ERROR'));
      }
    );
  }
  
  loadBlobFromCloud(blobId: string): void {
    this.configService.getConfigurationById(blobId).subscribe(
      (value) => {
        this.acceptedFiles = '.xml';
        this.executeLoadedFile(String(value));
        this.cloudSaveConfig.hide();
      },
      (error) => {
        this.checkError(error, this.translate.instant('NOTIFICATION.LOAD-FAILED-SERVER-ERROR'));
      }
    );
  }
  
  deleteBlobFromCloud(blobIds: string[]): void {
    this.configService.deleteConfiguration(blobIds).pipe(
      switchMap((apiResp: any) => {
        if(apiResp.message.includes("deleted")) {
          return this.configService.getConfiguations()
        } else return of([])
      })
    ).subscribe(
      (respNext: any) => {
        if(respNext && respNext?.length > 0) {
          this.webApiRes = respNext;
        } else {
          this.notification.showAlert(NotificationType.Error, this.translate.instant('NOTIFICATION.DELETE-FAILED'));
        }
      },
      (error) => {
        this.checkError(error, this.translate.instant('NOTIFICATION.DELETE-FAILED-SERVER-ERROR'));
      } 
    )
  }
  
  getParamParentText(itemParent: any, itemChild: any) {
    if(itemParent.type === 1 && !!itemChild.parameter && itemChild.parameter.childParameters?.length > 0)  return itemParent.text || "";
    return "";
  }

  checkSingleParams(item: any, currentConfigId) {
    if(item.type === 2 && !!item.parameter && item.parameter?.type !== 'DriverLicense' && currentConfigId !== 'StatisticPage.pnl') {
      if(item.parameter.childParameters?.length > 0) return false;
    }
    return true;
  }
 
  getCurrentInterfaceCmd():string{
    let currentInterfaceCmd: string = "";
    currentInterfaceCmd = this.getInitialChangeInterfaceCmd() + this.lastInterface;
    return currentInterfaceCmd;
  }

  writeConfigurationToDeviceByAzure() {
    let name = "Save configuration";
    this.showStatusDialog("writing", "Cloud Service");
    this.saveConfigToCloud(name).pipe(
      switchMap((value: any) => {
        if(value?.id) {
          const id = value.id;
          const commandName =  "setConfigurationFromAladdin";
          const body = {
            "request": {
              "configurationId": id
            }
          }
          return this.remoteManagementService.runCommandOnDevice(this.serialNumber, commandName, body);
        } else return of({});
      }),
      //Temporary add delay after write configuration. because the device resets and cannot read the configuration immediately.
      delay(7000),
      switchMap((value: any) => {
        if(value && this.serialNumber) {
          const commandName = "getConfiguration";
          return this.remoteManagementService.runCommandOnDevice(this.serialNumber, commandName);
        } else {
          return of({});
        }
      })
    ).subscribe(
      (value: any) => {
        if(value?.response?.code == 200) {
          let content = value.response.content;
          content.replace("$>", "").trim();
          let arrCmd = content.split(",");
          arrCmd.forEach((cmd) => {
            const code = cmd.slice(0, 4);
            const value = cmd.slice(4);
            this.setCurrentProductParameter(code, value);
          });
        } else {
          this.notification.showAlert(NotificationType.Error, this.translate.instant('NOTIFICATION.CANT-CONNECT-DEVICE'));
          
        }
      },
      (error) => {
        setTimeout(() => {
          this.checkError(error, this.translate.instant('NOTIFICATION.WRITE-CONFIGURATION-FAILED-SERVER-ERROR'));
        },1000)
      },
      () => {
        this.loadingWindow.hide();
      }
    )
  }

  checkError(error, message) {
    this.loadingWindow.hide();
    if (error instanceof InteractionRequiredAuthError || error?.message.includes("Unauthorized")) {
      this.authService.instance.acquireTokenRedirect(this.tokenRequest)
    } else {
      this.notification.showAlert(NotificationType.Error, message);
    }
  }


  showStatusDialog(title: string, mode: string) {
    this.loadingWindow.show(title, mode);
  }
  
  readConfigurationFromAzure() {
    if(this.serialNumber) {
      this.showStatusDialog("loading", "Cloud service");
      const commandName = "getConfiguration";
      this.remoteManagementService.runCommandOnDevice(this.serialNumber, commandName).subscribe(
        (value: any) => {
          if(value?.response?.code == 200) {
            let content = value.response.content;
            content.replace("$>", "").trim();
            let arrCmd = content.split(",");
            arrCmd.forEach((cmd) => {
              const code = cmd.slice(0, 4);
              const value = cmd.slice(4);
              this.setCurrentProductParameter(code, value);
            });
          } else {
            this.notification.showAlert(NotificationType.Error, this.translate.instant('NOTIFICATION.CANT-CONNECT-DEVICE'));
          }
        },
        (error) => {
          this.checkError(error, this.translate.instant('NOTIFICATION.LOAD-CONFIGURATION-FAILED-SERVER-ERROR'));
        },
        () => {
          this.loadingWindow.hide();
        }
      )
    }
  }

  catchError(error: string) {
    this.notification.showAlert(NotificationType.Error, error);
  }

  applyConfigToAllInterfaces(): void{
    if(this.modifiedParameters.length > 0){
      for (let i = 0; i < this.modifiedParameters.length; i++) {
        let newValue = this.modifiedParameters[i].value;
        this.modifiedParameters[i].setParamValueForAllInterfaces(newValue);
      }
      this.xmlService.saveProductEditedData(this.data);
      this.notification.showAlert(NotificationType.Success,this.translate.instant('NOTIFICATION.SUCCESS-APPLY-ALL-INTERFACES'));
    }else{
      this.notification.showAlert(NotificationType.Error,this.translate.instant('NOTIFICATION.NO-CHANGE-TO-APPLY'));
    }
  }

  rotateArrow() {
    if(this.btnWriteArrow == 'btn-change') {
      this.btnWriteArrow = 'btn-default';
      
    } else {
      this.btnWriteArrow = 'btn-change';
    }
  }
  
  onCertificateWriteFinish():void{
    if(this.isCurrentConfigHideValue()){
      this.setCertificate(this.hiddenString);
    }
  }

  onHideValueCertClick(): void {
    if(this.isCurrentConfigHideValue()){
      if(this.data.currentCertificate === this.hiddenString) {
        this.setCertificate("");
      }
    }
  }

  updateEditorBlur(): void {
    if(this.isCurrentConfigHideValue()){
      if(this.data.currentCertificate === "") {
        this.setCertificate(this.hiddenString);
      }
    }
  }
  
  onTabReload(): void{
    this.isBrowserOnRefresh = isBrowserOnRefresh;
    if(this.isBrowserOnRefresh){
      let tabs = SessionStorageService.getItem('openingTabs');
      if(tabs){
        tabs.forEach(tab => {
          this.tabNavigationService.addTab(tab);
        });
      }else{
        TabNavigationService.openingTabs = [];
      }
    }
  }

  isPage(): boolean {
    return this.currentConfig &&
           this.currentConfig.configurationElements.length > 0 && 
           this.currentConfigBlocks.length > 0;
  }

  parseConfigsToBlocks(): void{
    this.currentConfigBlocks = [];
    let block: ConfigurationElement[] = [];
    for (let i = 0; i < this.currentConfig.configurationElements.length; i++) {
      const item = this.currentConfig.configurationElements[i];
      const secondItem = (i + 1 < this.currentConfig.configurationElements.length)? this.currentConfig.configurationElements[i+1] : null;
      if(item && item.type === 1) {
        if(!(secondItem && secondItem.type !== 1 && secondItem.parameter)) {
          continue;
        }
      }
      if(secondItem && secondItem.type !== 1){
        block.push(item);
      }else if(secondItem && secondItem.type === 1){
        block.push(item);
        this.currentConfigBlocks.push(block);
        block = [];
      }else{
        block.push(item);
        this.currentConfigBlocks.push(block);
      }
    }
  }

  checkIsActiveHover(): boolean{
    return ProductComponent.isActiveHover;
  }

  // calculate height in pixel based on percentage of screen
  vh(percent): number {
    var h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
    return (percent * h) / 100;
  }

  setLeftPageMinHeightOnInit(): void{
    let centerPageHeader = document.getElementById("center-page-header");
    let leftPageItems = document.getElementById("left-page-items");
    let centerPageHeader_bound = centerPageHeader.getBoundingClientRect();
    let centerPageHeaderHeight = 55;

    leftPageItems.style['height']= `${this.vh(100) - centerPageHeader_bound.top - centerPageHeaderHeight}px`;
  }

  onPageScrollEvent(): void{
    let element_LeftHeader = document.getElementById("left_header");
    let rightPageInfo = document.getElementById("right_page_info");
    let leftPageHeader_boundObj = element_LeftHeader.getBoundingClientRect();
    let leftPageItems = document.getElementById("left-page-items");
    let centerPageHeaderHeight = 55;
    let footer = this.elementsService.footerElement;
    let footer_bound = footer.getBoundingClientRect();
    let footerHeightOnScreen = this.vh(100) - footer_bound.top;
    let distanceBetweenPageAndFooter = 10;
    let writeButtonMarginTop = 10;
    let writeButtonGroup = document.getElementById("write_button_group");
    let info = document.getElementById("info");
    
    if(footerHeightOnScreen < 0){
      footerHeightOnScreen = 0;
    }
    leftPageItems.style['height']= `${this.vh(100) - leftPageHeader_boundObj.top - centerPageHeaderHeight - footerHeightOnScreen - distanceBetweenPageAndFooter}px`; 
    info.style['height'] = `${this.vh(100) - leftPageHeader_boundObj.top - footerHeightOnScreen - distanceBetweenPageAndFooter}px`; 
    if(writeButtonGroup){
      rightPageInfo.style['maxHeight'] = `${info.offsetHeight - writeButtonGroup.offsetHeight - writeButtonMarginTop}px`; 
    }else{
      rightPageInfo.style['maxHeight'] = `${info.offsetHeight}px`; 
    }
  }

  setComponentHeight_toMaxContent(): void{
    let rightPageInfo = document.getElementById("right_page_info");
    let leftPageItems = document.getElementById("left-page-items");
    let info = document.getElementById("info");

    leftPageItems.style['height']= 'max-content'; 
    info.style['height'] = 'max-content'; 
    rightPageInfo.style['maxHeight'] = 'max-content'; 
  }

  hideConfigHeader_onScrollDown():void {
    this.currentScrollPosition = window.scrollY;
    let configHeaderHeight = document.getElementById("center-page-header").clientHeight;
    if (this.prevScrollPosition > this.currentScrollPosition) {
      document.getElementById("center-page-header").style.top = "0";
    } else {
      document.getElementById("center-page-header").style.top = `-${configHeaderHeight + 1}px`;
    }
    this.prevScrollPosition = this.currentScrollPosition;
  }
  
  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if(this.isLargeSCreen()){
      this.onPageScrollEvent();
    }
    this.hideConfigHeader_onScrollDown();
  }

  isLargeSCreen(): boolean{
    // check large screen (> 1199.5px)
    if(window.innerWidth > 1199.5){
      return true;
    }else{
      this.setComponentHeight_toMaxContent();
      return false;
    }
  }

  updateSearchHistory(search: SearchItem): void{
    if(this.searchHistory.length > 0){
      this.searchHistory.forEach((item, idx) => {
        if(item.parameter.code === search.parameter.code){
          this.searchHistory.splice(idx,1);
        }
      })
    }

    this.searchHistory.unshift(new SearchItem(search.id, search.page, search.parameter));
    // only keep 5 search items
    if(this.searchHistory.length > 5){
      this.searchHistory = this.searchHistory.splice(0,5);
    }
    this.data.searchHistory = this.searchHistory;
  }

  set_isOnSearchInputClick(value: boolean): void{
    this.isOnSearchInputClick = value;
  }

  terminal_getCustomButtons(buttons: string): void{
    if(buttons != "[]"){
      this.terminal_customButtons = JSON.parse(buttons);
    }
  }
}
