import { Component, OnInit, NgZone, ViewChild, ElementRef, AfterContentInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { DataService } from 'src/app/services/data.service';
import { Helper } from 'src/app/classes/utility';
import { SidebarComponent } from 'src/app/shared/sidebar/sidebar.component';
import { RemoteService } from 'src/app/services/remote.service';
import { XmlService } from 'src/app/services/xml.service';
import { HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';

declare var $;
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit, AfterContentInit {
  @ViewChild('sidebar') sidebar: SidebarComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  // Current category
  categories: any[] = [];
  currentCategoryIndex: number = undefined;
  currentCategory: any = { name: '', children: [] };
  isRemoted = false;

  subCategories: any[] = [];
  selectedSubCategoryIndex: number = undefined;
  standaloneMode: boolean;
  newsVisible = true;
  isUserConfig: boolean = false;
  azureMode: boolean = false;

  constructor(private dataService: DataService, private route: ActivatedRoute, private zone: NgZone,
              private remoteService: RemoteService, private xmlService: XmlService, private http: HttpClient) {

    window.homeComponent = {
      zone: this.zone,
      sendCurrentDevicesToRemoteFromJs: (value: string) => this.sendCurrentDevicesToRemoteFromJs(value),
      setSearchDeviceStatus: (value: string) => this.remoteService.setSearchDeviceStatus(value),
      sendCurrentPortsToRemoteFromJs: (value: string) => this.sendCurrentPortsToRemoteFromJs(value),
      getPortList: () => this.remoteService.getPortList(),
      updatePortListFromJava:(ports: string) => this.updatePortListFromJava(ports),
      enableStandaloneMode: () => Helper.enableStandaloneMode(),
      activeStandaloneEnableCloud: () => Helper.activeStandaloneEnableCloud(),
      toggleNewsVisible: (value: boolean) =>this.newsVisible = value,
      loadCustomConfiuration: () => this.initData()
    };

    // Subscribing to the productsMenu result to have it as soon as possible
    if(this.route.snapshot.paramMap.get('id') !== "userconfig") {
      this.currentCategoryIndex = parseInt(this.route.snapshot.paramMap.get('id'), 10);
      dataService.getProductsMenu().subscribe(menu => {
        // Content ready
        // Setting categories array
        this.categories = menu;
        // Getting category index from url
        this.currentCategory = this.categories[this.currentCategoryIndex];
        // Set subcategories
        this.subCategories = this.categories[this.currentCategoryIndex].children;
        console.log(this.currentCategory);
      });
    } else {
      this.initData();
      this.isUserConfig = true;
    }

    Helper.standaloneMode
    .subscribe(value => {
      this.standaloneMode = value;
    });
    
    Helper.azureMode
    .subscribe(value => {
      this.azureMode = value;
    });

    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
    });
  }
  ngAfterContentInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="tooltip"]').hover(function(){
      $('[data-toggle="tooltip"]').tooltip()
    });
  }

  ngOnInit(): void {
  }

  selectSubCategory(index) {
    this.selectedSubCategoryIndex = index;
  }
  sendCurrentDevicesToRemoteFromJs(value: string) {
    this.remoteService.listRemoteDevicesCurrent.next(this.remoteService.generateDeviceListData(JSON.parse(value)));
    this.remoteService.listRemoteDevices.next(this.remoteService.generateDeviceListData(JSON.parse(value)));
    this.remoteService.sendCurrentDevicesToRemote(value);
  }

  sendCurrentPortsToRemoteFromJs(value: string) {
    let portList = value.split(";");
    this.remoteService.setUpPortList(portList);
    this.remoteService.sendCurrentPortListToRemote(value);
  }

  updatePortListFromJava(ports: string) {
    this.remoteService.updatePortListFromJava(ports? ports.split(';'): []);
    if(this.isRemoted){
      this.remoteService.sendUpdatePortListToRemote(ports);
    }
  }

  initData() {
    this.currentCategory = {
      name: "Custom Configuration",
      children: []
    };

    this.selectedSubCategoryIndex = undefined;
    this.subCategories = [];

    this.dataService.getProductMain().pipe(
      switchMap((value) => {
        return forkJoin([of(value.length), this.dataService.getProductUser()])
      })
    ).subscribe(([productLength, value]:[number, string[]]) => {
      value.forEach((element, index) => {
        const split = element.split("_");
        const indexInCategory = this.currentCategory.children.findIndex((item) => item?.name == split[0]);
        if(indexInCategory == -1) {
          this.currentCategory.children?.push({
            name: split[0],
            image: Helper.baseProductsDirectory + element + '/device.png'
          })
          this.subCategories.push({
            name: split[0],
            children: []
          })

          this.subCategories[this.subCategories.length - 1].children.push({
            name: split[1],
            children: [productLength + index]
          });
        } else {
          this.subCategories[indexInCategory].children.push({
            name: split[1],
            children: [productLength + index]
          });
        }
      })
    })
  }

  removeUserConfiguration(indexChild: number = null) {
    const model = this.currentCategory?.children[this.selectedSubCategoryIndex]?.name;

    if(this.standaloneMode) {
      if(indexChild != null) {
        window.deleteCustomConfigurationFromJs(model, this.subCategories[this.selectedSubCategoryIndex].children[indexChild]?.name);
      } else {
        window.deleteCustomConfigurationFromJs(model);
      }
    }
  }
}
