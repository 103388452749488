import { Component, Input, ViewChild, AfterViewInit, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import bwipjs from 'bwip-js';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.css']
})
export class BarcodeComponent implements AfterViewInit, OnChanges {
  @Input() type = '';
  @Input() text = '';
  @Input() height: any;
  @Input() width: any;

  @ViewChild('canvas') canvas: ElementRef;

  constructor() { }

  ngAfterViewInit(): void {
    this.initBarcode();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initBarcode();
  }

  getBarcodeAsBase64(): string {
    return this.canvas.nativeElement.toDataURL();
  }

  getBarcodeWidth(): number {
    return this.canvas.nativeElement.getBoundingClientRect().width;
  }

  getBarcodeHeight(): number {
    return this.canvas.nativeElement.getBoundingClientRect().height;
  }

  private initBarcode() {
    if (!this.height || !this.width) {
      return;
    }

    const preCommand = this.type === 'code128' ? '^FNC3' : '^PROG';
    const postCommand = '^013';
    const options: any = {
      bcid: this.type,
      parse: true,
      parsefnc: true
    };

    if (this.type === 'code128') {
      options.scaleY = this.height.value / 5 / 10;
      options.scaleX = this.width.value;
    } else {
      options.scale = Math.max(this.height.value / 5 / 6, 2);
    }

    options.text = preCommand + this.text + postCommand;

    if (this.canvas) {
      bwipjs.toCanvas(this.canvas.nativeElement, options);
    }
  }
}
