import { DetectionModeType } from './../../classes/detection-setting';
import { Component, OnInit, Input, ChangeDetectorRef, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { BehaviorSubject, Subscription, defer, from, merge, of } from 'rxjs';
import { protectedResources } from 'src/app/auth-config';
import { DataService } from 'src/app/services/data.service';
import { RemoteService } from 'src/app/services/remote.service';
import { DevicePropertyModel } from "src/app/classes/devices/device-property.model"
import { SidebarType } from "src/app/classes/enums/sidebar-type.enum";
import { Helper } from 'src/app/classes/utility';
import { RemoteManagementService } from  'src/app/services/cloud/remote-management.service';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { AlternativeProductsModalComponent } from '../alternative-products-modal/alternative-products-modal.component';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationType } from 'src/app/classes/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {
  @Input() standaloneMode = false;
  @Input() isRemoted = false;
  azureMode: boolean = false;
  @ViewChild('loadingAlternativeProductsModal') alternativeProductsModal: AlternativeProductsModalComponent;
  @ViewChild('sidebarNotification') notification: NotificationComponent;
  @Output() settingModeEvent: EventEmitter<DetectionModeType> = new EventEmitter<DetectionModeType>();
  stateLoadListDeviceInAzure: string = "Closed";
  listDeviceProperties$: BehaviorSubject<DevicePropertyModel[]>;
  subcription$: Subscription[] = [];
  sidebarType: SidebarType = SidebarType.StandaloneMode;
  stopGetDevice: boolean = false;

  //To synchronize sidebar on home page and configuration page. The following parameters must be updated from remote service
  stateDectectDevice: string = "Closed";
  portList: any = [];
  formList: FormArray;
  listOnlineDevices: string[][] = [];
  listRemoteDevices: string[][] = [];
  detectionMode: DetectionModeType = DetectionModeType.DefaultPorts;

  constructor(
    private changeDetection: ChangeDetectorRef, 
    private remoteService: RemoteService, 
    private authService: MsalService,
    private dataService: DataService,
    private router: Router,
    private remoteManagementService : RemoteManagementService,
    private translate: TranslateService
    ) {
      
    this.remoteService.portListSideBarCurrent.subscribe((value) => {
      this.portList = value;
    });
      
    this.remoteService.portListSideBar.subscribe((value) => {
      this.portList = value;
      this.changeDetection.detectChanges();
    });
    
    this.remoteService.formListSideBarCurrent.subscribe((value) => {
      this.formList = value;
    });
    
    this.remoteService.formListSideBar.subscribe((value) => {
      this.formList = value;
      this.changeDetection.detectChanges();
    });

    this.remoteService.listOnlineDevicesSideBarCurrent.subscribe((value) => {
      this.listOnlineDevices = value;
    });

    this.remoteService.listOnlineDevicesSideBar.subscribe((value) => {
      this.listOnlineDevices = value;
      this.changeDetection.detectChanges();
    });

    this.listDeviceProperties$ = new BehaviorSubject<DevicePropertyModel[]>([]);
    this.remoteService.listRemoteDevicesCurrent.subscribe((value) => {
      this.listRemoteDevices = value;
    });

    this.remoteService.listRemoteDevices.subscribe((value) => {
      this.listRemoteDevices = value;
      this.changeDetection.detectChanges();
    });

    this.remoteService.stateDectectDeviceCurrent.subscribe((value) => {
      this.stateDectectDevice = value;
    });

    this.remoteService.stateDectectDevice.subscribe((value) => {
      this.stateDectectDevice = value;
      this.changeDetection.detectChanges();
    });

    this.remoteService.detectionModeCurrent.subscribe((value) => {
      this.detectionMode = value;
      this.settingModeEvent.emit(value);
    });

    this.remoteService.detectionMode.subscribe((value) => {
      this.detectionMode = value;
      this.settingModeEvent.emit(value);
      this.changeDetection.detectChanges();
    });

    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
      if(this.azureMode) {
        this.sidebarType = SidebarType.AzureMode;
      } else if(this.isRemoted && !this.standaloneMode) {
        this.sidebarType = SidebarType.Remote;
      } else {
        this.sidebarType = SidebarType.StandaloneMode;
      } 
    });

    Helper.azureMode.subscribe((value) => {
      this.azureMode = value;
      if(this.azureMode) {
        this.sidebarType = SidebarType.AzureMode;
      } else if(this.isRemoted && !this.standaloneMode) {
        this.sidebarType = SidebarType.Remote;
      } else {
        this.sidebarType = SidebarType.StandaloneMode;
      } 
    });
  }
  ngOnDestroy(): void {
    this.subcription$.forEach((element) => element.unsubscribe());
  }

  ngOnInit(): void {
  }

  onStart(): void {
    this.remoteService.clearListRemoteDevices();
    let portListString: string = this.remoteService.getPortList();
    this.switchCaseSidebarType(
      () => window.getRemoteDeviceHandlerFromJs(portListString),
      () => { if(this.stateLoadListDeviceInAzure == "Closed") {
        this.stopGetDevice = false;
        this.getAllDevicesInAzure();
        }
      },
      () => this.remoteService.getRemoteDeviceExec(portListString), 
      () => {});
  }

  onStop() {
    this.switchCaseSidebarType(
      () => window.stopSearchDeviceHandlerFromJs(),
      () => { this.stateLoadListDeviceInAzure = "Stopping"; this.stopGetDevice = true;},
      () => this.remoteService.stopSearchDeviceExec(), 
      () => {});
  }

  switchCaseSidebarType(fun1: Function, fun2: Function, fun3: Function, fun4: Function) {
    switch(this.sidebarType) {
      case SidebarType.StandaloneMode:
        fun1();
        break;
      case SidebarType.AzureMode:
        fun2();
        break;
      case SidebarType.Remote:
        fun3();
        break;
      default:
        fun4();
        break;
    }
  }
  
  openRemoteDevice(deviceIndex: number): void {
    this.switchCaseSidebarType(
      () => window.openRemoteDeviceHandlerFromJs(deviceIndex.toString(), this.listRemoteDevices[deviceIndex][0], this.listRemoteDevices[deviceIndex][1]),
      () => { this.gotoProductPage(deviceIndex)},
      () => {
        if (this.listRemoteDevices[deviceIndex][3] === "nonexistent") {
          this.notification.showAlert(NotificationType.Error, this.translate.instant('NOTIFICATION.THE-ALADDIN-WEBSITE-HAS-NOT-SUPPORTED-FOR-THIS-PACKAGE'));
        } else {
          this.remoteService.openRemoteDeviceExec(deviceIndex.toString(), this.listRemoteDevices[deviceIndex][0], this.listRemoteDevices[deviceIndex][1]);
        }
      }, 
      () => {});
  }

  getRemoteListPort() {
    this.switchCaseSidebarType(
      () => window.hoverSearchDeviceButtonFromJs("getPort"),
      () => {},
      () => this.remoteService.getPortListExec(), 
      () => {});
  }

  selectPorts(check: boolean, index: number) {
    this.remoteService.selectPorts(check, index);
  }

  gotoProductPage(index: number) {
    if(parseInt(this.listOnlineDevices[index][3], 10) == -1) {
      this.alternativeProductsModal.serialNumber = this.listOnlineDevices[index][6];
      this.alternativeProductsModal.show();
    } else {
      this.router.navigate(['configuration', "product", parseInt(this.listOnlineDevices[index][3], 10), "null", "azure"], {queryParams: {id: this.listOnlineDevices[index][6]}});
    }
  }

  changeSidebarType() {
      let sidebarTypeTemp = (this.standaloneMode)? SidebarType.StandaloneMode : SidebarType.Remote;
      this.sidebarType = (this.sidebarType !== SidebarType.AzureMode)? SidebarType.AzureMode: sidebarTypeTemp;
  }

  getAllDevicesInAzure() {
    this.stateLoadListDeviceInAzure = "Loading";
    this.remoteService.clearListOnlineDevices();
    let tokenRequest = {
      scopes: [...protectedResources.apiTodoList.scopes.read],
    };

    this.remoteManagementService.getListDeviceRemote().pipe(
      tap((data: any) => {
        if(data) {
          let deviceProperties: DevicePropertyModel[] = [];
          data.value.forEach((e) => {
            let deviceProperty = new DevicePropertyModel();
            deviceProperty.id = e.id;
            deviceProperty.displayName = e.displayName;
            deviceProperties.push(deviceProperty);
          })
          this.listDeviceProperties$.next([...deviceProperties]);
        }
      }),
      switchMap((data: any) => {
        if(data) {
          return merge(data.value.map(element => this.remoteManagementService.getPropertiesDeviceById(element.id)));
        }
      }),
      mergeMap((value: any) => (value))
    ).subscribe(
      ([value, id]: any) => {
        if(value && value.length > 0) {
          let deviceProperty: DevicePropertyModel = new DevicePropertyModel();
          let deviceProperties: DevicePropertyModel[] = this.listDeviceProperties$.getValue();
          deviceProperty = deviceProperties.find((element) => element.id == id);

          value.forEach((childItem: any) => {
            let name = childItem?.name;
            if(name.charCodeAt(0) >= 65 && name.charCodeAt(0) <= 90) {
              name = String.fromCharCode(name.charCodeAt(0) + 32) + name.slice(1);
            }
            deviceProperty[name] = childItem?.value;
          });
          
          this.listDeviceProperties$.next([...deviceProperties]);
          if(deviceProperty.serialNumber) {
            let model = deviceProperty.topModelNumber || "Not found";
            let releaseSW = deviceProperty.applicationROM;
  
            let internalName = model + "_" + releaseSW;
            if(!this.stopGetDevice) {
              const productIndex$ = this.dataService.getProductIndexByName(internalName).subscribe((productIndex: Number) => {
                this.remoteService.pushListOnlineDevices(deviceProperty.displayName, deviceProperty.id, Helper.baseProductsDirectory + internalName + '/device.png', productIndex.toString(), model, releaseSW, deviceProperty.serialNumber);
              })
  
              this.subcription$.push(productIndex$);
            }
          }
        }
      },
      (error) => {
        this.stateLoadListDeviceInAzure = "Closed";
        if (error instanceof InteractionRequiredAuthError) {
          return this.authService.instance.acquireTokenRedirect(tokenRequest)
        } else if(error.message.includes("Unauthorized")) {
          this.authService.instance.acquireTokenRedirect(tokenRequest);
        }
      },
      () => {
        this.stateLoadListDeviceInAzure = "Closed";
      }
    );
  }

  getListDevice() {
    if(this.sidebarType == SidebarType.AzureMode) {
      return this.listOnlineDevices;
    } else {
      return this.listRemoteDevices;
    }
  }
  
  checkStatus(status: string) {
     if(this.sidebarType !== SidebarType.AzureMode) {
        if(this.stateDectectDevice == status) return true;
     } else {
        if(this.stateLoadListDeviceInAzure == status) return true;
     }

     return false;
  }

  onDetectionModeChange(value): void {
    this.detectionMode = value;
    this.settingModeEvent.emit(value);
    //if(!this.standaloneMode && this.isRemoted) {
    if(this.isRemoted) {
      this.remoteService.sendCurrentDeviceCOMSearchSetting(value);
    }
  }

  setDetectionMode(mode: DetectionModeType): void{
    this.detectionMode = mode;
  }
}
