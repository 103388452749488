import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {Parameter} from "../../classes/parameters"
import {CompareConfigurationEntry} from "src/app/shared/compare-configuration/compare-configuration-entry"
import {CompareConfigurationImport} from "src/app/shared/compare-configuration/compare-configuration-import"
import { ProductComponent } from 'src/app/pages/product/product.component';

export enum ComparisonType {
  RECOMMENDED_DEFAULTS = "COMPARE-CONFIG.RECOMMENDED-DEFAULTS",
  MASTER_DEFAULTS = "COMPARE-CONFIG.MASTER-DEFAULTS",
  CONFIGURATION_FILE = "COMPARE-CONFIG.CONFIGURATION-FILE"
}

@Component({
  selector: 'app-compare-configuration',
  templateUrl: './compare-configuration.component.html',
  styleUrls: ['./compare-configuration.component.css']
})
export class CompareConfigurationComponent implements OnInit {

  @Input() parentParameterData: Parameter[] = []; // do not modify or else product component will be modified
  @Input() family: string;
  @ViewChild('firstCCFInput') firstCCFInput: ElementRef;
  @ViewChild('secondCCFInput') secondCCFInput: ElementRef;

  filteredParentParameterData: Parameter[]; // do not modify or else product component will be modified
  compareConfigurationEntries: CompareConfigurationEntry[] = [];
  acceptedFiles = '.tex';

  firstColumnHeaderName: string = "";
  firstCCFName: string = "";
  firstCCFContent: string = null;
  firstCCFParameters: Parameter[] = [];
  firstCCFInterface: string = "";

  secondColumnHeaderName: string = ComparisonType.RECOMMENDED_DEFAULTS;
  secondCCFName: string = "";
  secondCCFContent: string = null;
  secondCCFParameters: Parameter[] = [];
  secondCCFInterface: string = "";

  initialCurrentInterface: string;

  comparisonOptions = [ComparisonType.RECOMMENDED_DEFAULTS,ComparisonType.MASTER_DEFAULTS,ComparisonType.CONFIGURATION_FILE];
  selectedComparisonOption = ComparisonType.RECOMMENDED_DEFAULTS;

  compareAllInterfaces = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }
  show() {
    // reset all variables
    this.compareConfigurationEntries = [];
    this.firstColumnHeaderName = "";
    this.firstCCFName = "";
    this.firstCCFParameters = [];
    this.firstCCFInterface = "";

    this.selectedComparisonOption = ComparisonType.RECOMMENDED_DEFAULTS;
    this.secondColumnHeaderName = this.translate.instant(ComparisonType.RECOMMENDED_DEFAULTS);
    this.secondCCFName = "";
    this.secondCCFParameters = [];
    this.secondCCFInterface = "";

    this.initialCurrentInterface = Parameter.getCurrentInterface(); // record current interface so it can be restored after comparison
    this.filteredParentParameterData = this.parentParameterData.filter(param => !param.parent); // filter out child parameters
    const element: HTMLElement = document.getElementById('compareConfigurationButton');
    element.click();
  }

  selectComparisonOption(option: ComparisonType) {
    this.selectedComparisonOption = option;
    if (this.selectedComparisonOption == ComparisonType.CONFIGURATION_FILE) {
      this.secondColumnHeaderName = this.secondCCFName;
    } else {
      this.secondColumnHeaderName = this.translate.instant(this.selectedComparisonOption);
    }
    if (this.firstCCFParameters.length > 0) {
      this.startComparison();
    }
  }

  singleInterfaceCompare(): void {
    this.firstCCFParameters.forEach(param => {
      let value = param.value;
      let comparisonValue = "";
      if (this.selectedComparisonOption == ComparisonType.RECOMMENDED_DEFAULTS) {
        comparisonValue = param.defaultValue;
      } else if (this.selectedComparisonOption == ComparisonType.MASTER_DEFAULTS) {
        comparisonValue = param.masterDefaultValue;
      } else if (this.selectedComparisonOption == ComparisonType.CONFIGURATION_FILE) {
        let secondParam = this.secondCCFParameters.find(param2 => param2.code == param.code)
        if (secondParam === undefined) {
          comparisonValue = value; // if cannot find parameter, show no difference
        }
        comparisonValue = secondParam.value;
      }
      if (value !== comparisonValue && !param.parent && param.code !== this.interfaceChangeParameter()) { // exclude child parameters
        this.compareConfigurationEntries.push(new CompareConfigurationEntry(param.label,param.code,
          param.addDescription(value),param.addDescription(comparisonValue), ""))
      }
    });
  }

  static getValueOnInterface(param: Parameter, interfaceValue: string): string {
    if (param.childParameters.length > 0) {
      return param.childParameters.map(it => this.getValueOnInterface(it, interfaceValue)).join('');
    }
    if (param.valuesForInterfaces == undefined || param.valuesForInterfaces.size == 0) {
      return param.value;
    } else if (param.valuesForInterfaces.get(interfaceValue) == undefined) {
      return param.masterDefaultValue;
    } else {
      return param.valuesForInterfaces.get(interfaceValue);
    }
  }

  static getRecommendedValueOnInterface(param: Parameter, interfaceValue: string): string {
    if (param.childParameters.length > 0) {
      return param.childParameters.map(it => this.getRecommendedValueOnInterface(it, interfaceValue)).join('');
    }
    if (param.defaultValuesForInterfaces == undefined || param.defaultValuesForInterfaces.size == 0 
      || param.defaultValuesForInterfaces.get(interfaceValue) == undefined) {
      return param.masterDefaultValue;
    } else {
      return param.defaultValuesForInterfaces.get(interfaceValue);
    }
  }

  allInterfaceCompare(): void {
    let interfaceParam = this.firstCCFParameters.find(param => param.code == this.interfaceChangeParameter());
    if (interfaceParam == undefined) {
      return;
    }
    let interfaceOptions = interfaceParam.options;
    this.firstCCFParameters.forEach(param => {
      interfaceOptions.forEach(interfaceOption => {
        let value = CompareConfigurationComponent.getValueOnInterface(param, interfaceOption.value);
        let comparisonValue = "";
        if (this.selectedComparisonOption == ComparisonType.RECOMMENDED_DEFAULTS) {
          comparisonValue = CompareConfigurationComponent.getRecommendedValueOnInterface(param, interfaceOption.value);
        } else if (this.selectedComparisonOption == ComparisonType.MASTER_DEFAULTS) {
          comparisonValue = param.masterDefaultValue;
        } else if (this.selectedComparisonOption == ComparisonType.CONFIGURATION_FILE) {
          let secondParam = this.secondCCFParameters.find(param2 => param2.code == param.code)
          if (secondParam === undefined) {
            comparisonValue = value; // if cannot find parameter, show no difference
          }
          comparisonValue = CompareConfigurationComponent.getValueOnInterface(secondParam, interfaceOption.value);
        }
        if (value !== comparisonValue && !param.parent && param.code !== this.interfaceChangeParameter()) { // exclude child parameters
          this.compareConfigurationEntries.push(new CompareConfigurationEntry(param.label,param.code,
            param.addDescription(value),param.addDescription(comparisonValue), interfaceOption.name))
        }
      });
    });
  }

  startComparison() {
    try {
      this.compareConfigurationEntries = [];
      if (this.compareAllInterfaces) {
        this.allInterfaceCompare();
      } else {
        this.singleInterfaceCompare();
      }
      this.compareConfigurationEntries.sort((a,b) => a.parameterCode.localeCompare(b.parameterCode));
      if (this.compareAllInterfaces) {
        this.compareConfigurationEntries.sort((a,b) => a.interfaceValue.localeCompare(b.interfaceValue));
      }
    } catch (err) {
      console.log(err);
    } finally {
      Parameter.setCurrentInterface(this.initialCurrentInterface);
    }
  }

  loadFirstCCF(): void {
    setTimeout(() => {
      this.firstCCFInput.nativeElement.click();
    }, 50);
  }

  loadSecondCCF(): void {
    setTimeout(() => {
      this.secondCCFInput.nativeElement.click();
    }, 50);
  }

  interfaceChangeParameter() {
    if (this.family === 'FRS') {
      return '0001';
    } else {
      return '$hA';
    }
  }

  loadedFirstCCF(e: Event): void {
    let fileInput = <HTMLInputElement>e.target;
    if (fileInput.files && fileInput.files[0]) {
      // clear old data
      this.firstCCFContent = null;
      this.firstCCFParameters = null;
      this.compareConfigurationEntries = null;
      this.firstColumnHeaderName = "";
      this.firstCCFInterface = "";

      this.firstCCFName = fileInput.files[0].name;
      
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.firstCCFContent = e.target.result;
        this.firstCCFParameters = this.filteredParentParameterData.map(param => param.copyParameter(null)); // need to clone each parameter so it will not be modified

        let importer = new CompareConfigurationImport(this.firstCCFParameters, this.family);
        importer.importCCF(this.firstCCFContent);

        this.firstColumnHeaderName = this.firstCCFName;
        this.startComparison();
        let interfaceParam = this.firstCCFParameters.find(param => param.code == this.interfaceChangeParameter());
        this.firstCCFInterface = interfaceParam.getValueDescription();
      };

      reader.readAsText(fileInput.files[0]);
      this.firstCCFInput.nativeElement.value = '';
    }
  }

  loadedSecondCCF(e: Event): void {
    let fileInput = <HTMLInputElement>e.target;
    if (fileInput.files && fileInput.files[0]) {
      // clear old data
      this.secondCCFContent = null;
      this.secondCCFParameters = null;
      this.compareConfigurationEntries = null;
      this.secondColumnHeaderName = "";
      this.secondCCFInterface = "";

      this.secondCCFName = fileInput.files[0].name;
      
      const reader = new FileReader();

      reader.onload = (e: any) => {
        this.secondCCFContent = e.target.result;
        this.secondCCFParameters = this.filteredParentParameterData.map(param => param.copyParameter(null)); // need to clone each parameter so it will not be modified

        let importer = new CompareConfigurationImport(this.secondCCFParameters, this.family);
        importer.importCCF(this.secondCCFContent);

        this.secondColumnHeaderName = this.secondCCFName;
        this.startComparison();
        let interfaceParam = this.secondCCFParameters.find(param => param.code == this.interfaceChangeParameter());
        this.secondCCFInterface = interfaceParam.getValueDescription();
      };

      reader.readAsText(fileInput.files[0]);
      this.secondCCFInput.nativeElement.value = '';
    }
  }

  showLoadSecondFile(): boolean {
    return this.selectedComparisonOption == ComparisonType.CONFIGURATION_FILE;
  }

  getCompareAllInterfaces(): boolean {
    return this.compareAllInterfaces;
  }

  toggleCompareAllInterfaces(): void {
    this.compareAllInterfaces = !this.compareAllInterfaces;
    this.startComparison();
  }
  
}
