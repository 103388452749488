import { Helper } from 'src/app/classes/utility';
import { Parameter } from '../../classes/parameters';
import { ProductComponent } from './product.component'; 

export enum CopyTo {
  COPY_TO_USER = "Copy to user",
  COPY_TO_FACTORY = "Copy to factory",
  COPY_TO_USER_AND_FACTORY = "Copy to user and factory"
}

export class OfflineCCFExport {
    copyToLevel = CopyTo.COPY_TO_USER;
    CI_INTERFACE_TYPE: string = "0001";
    CA_LABEL_EDIT_SCRIPT: number[] = [127, 248];
    ap0A: string = String.fromCharCode(10); //[0x0A]
    doRestore: boolean = true;
    masterRestore: boolean = true;
    addComments: boolean = true;    
    
    constructor(private productComponent: ProductComponent){
    }

    exportCCF(customerName: string, configName: string, copyTo: CopyTo): void {
        // Only FRS is supported by this function in current
        if (this.productComponent.family === "FRS" && !this.productComponent.isOnline) {
          let sCCF: string = this.getCCF(customerName, configName, copyTo);
          this.productComponent.data.currentConfigFile = sCCF;
        }
      }
    
      getCCF(customerName: string, configName: string, copyTo: CopyTo): string {
        const productConfigFile: string = this.productComponent.product.configUrl.substring(this.productComponent.product.configUrl.indexOf("/") + 1);
        const versionAladdin: string = "Aladdin " + document.getElementById("version-spam").innerText.replace("V ", "");
        if (Helper.secureModeEnabled) { // only allow non-copy to user for secure users
          this.copyToLevel = copyTo;
        }
    
        let ccfHeader: string = this.setHeader(productConfigFile, versionAladdin, this.productComponent.userName, "Datalogic", configName, customerName, this.productComponent.product.name);
        let activeInterfaceValue: string = this.productComponent.lastInterface;
        let activeInterfaceView: string = "";
        for (let interfaceView in this.productComponent.data.interfaceClasses) {
          let interfaceValue = this.productComponent.data.interfaceClasses[interfaceView];
          if (interfaceValue === activeInterfaceValue) {
            activeInterfaceView = interfaceView;
            break;
          }
        }
    
        let ccfBody: string = this.setAllInterfaces(activeInterfaceView, activeInterfaceValue);
        let ccfTrailer: string = this.setTrailer(this.productComponent.data.currentUle, activeInterfaceView, activeInterfaceValue);
       
        return ccfHeader + ccfBody + ccfTrailer;
      }
    
      setHeader(masterFile: string, version: string, userName: string, companyName: string, configName: string, customerName: string, productName: string): string {
        const monthNames: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const now = new Date();
        let monthStr: string = monthNames[now.getMonth()];
        let dayStr: string = now.getDate().toString();
        let year: string = now.getFullYear().toString();
        let date: string = monthStr + " " + dayStr + ", " + year;
    
        let result: string = "CF0X 0070\r\n";
        result = result + "Copyright " + companyName + " " + year + "\r\n";
        result = result + "ID CONFIG," + configName + "," + customerName + "," + productName+ ",\r\n";
        result = result + "FC "+ date + "\r\n";
        result = result + "# File created by " + userName + "\r\n";
        result = result + "# " + version + "\r\n";
    
        result = result + "&\r\n";
        result = result + "# Master file: " + masterFile + "\r\n";
        result = result + "#\r\n";
        result = result + this.setSystemInterface(configName);
    
        return result;
      }
    
      getSytemInterfaceConfigurationLines(): string {
        let result: string = "";
        let finishedParamCodes = new Set();
        for (let j = 0; j < this.productComponent.data.configurationPages.length; j++) {
          for (let l = 0; l < this.productComponent.data.configurationPages[j].configurationElements.length; l++) {
            if(this.productComponent.data.configurationPages[j].configurationElements[l].parameter !== undefined && !finishedParamCodes.has(this.productComponent.data.configurationPages[j].configurationElements[l].parameter.code)) {
              finishedParamCodes.add(this.productComponent.data.configurationPages[j].configurationElements[l].parameter.code);
              let currentParameter: Parameter =  this.productComponent.data.configurationPages[j].configurationElements[l].parameter;
              if (currentParameter.isCentralized) {
                let currentDefaultValueForCCF = currentParameter._masterDefaultValue;
                let currentValueForCCF = currentParameter._masterValue;
                if (currentDefaultValueForCCF !== currentValueForCCF){ 
                  result = result + this.generateConfigFileLine(currentParameter.code, currentValueForCCF, currentParameter);
                }
              }
            }
          }
        }
        return result;
      }

      setSystemInterface(configName: string): string {
        let result: string = "#-------< System Interface >-------------------------------\r\n";
        result = result + this.formatLine(this.addChecksum("00010046"),"INTERFACE TYPE: System");
    
        // create Configuration File ID line
        // concatenate config name and change to hex
        let configNameLength: number = configName.length;
        if (configNameLength != 10) {
          if (configNameLength < 10) {
            for (let i = 0; i < 10 - configNameLength; i++) {
              configName = configName + " ";
            }
          }
          configName = configName.substring(0, 10);
        }
    
        let configIDHex: string = this.asciiStringToHexString(configName);
        // format Configuration File ID line
        let configIDCmd = this.addChecksum("0060" + configIDHex).toUpperCase();
        result = result + this.formatLineThreeElements(
          configIDCmd,
          "Configuration File ID",
          configName
        );

        // add sealed parameters if in secure mode
        if (Helper.secureModeEnabled) {
          result = result + this.getSytemInterfaceConfigurationLines();
        }
    
        result = result + this.commit();
        result = result + "#\r\n";
    
        return result;
      }
    
      asciiStringToHexString(s: string): string {
        let result: string = "";
        for (let i = 0; i < s.length; i++) {
          result = result + s.charCodeAt(i).toString(16);
        }
        return result;
      }
      
      commit(): string {
        if (this.copyToLevel == CopyTo.COPY_TO_USER_AND_FACTORY) {
          return this.formatLine(this.addChecksum("7FFF01"),"Commit interface to user AND factory");
        } else if(this.copyToLevel == CopyTo.COPY_TO_FACTORY) {
          return this.formatLine(this.addChecksum("7FFE01"),"Commit interface to factory");
        } else { // for now this is the only path that is used
          return this.commitToUser();
        }
      }
    
      commitToUser(): string {
        return this.formatLine(this.addChecksum("7FFD01"),"Save interface to user area");
      }
    
      addChecksum(s: string): string {
        let bytes: number[] = this.hexStringToByteArray(s);
        let chk_sum: number = 0;
    
        for(let b of bytes) {
            chk_sum = chk_sum + b;
            if (chk_sum > 127) {
              chk_sum = chk_sum - 256;
            }
        }
        chk_sum = 0 - chk_sum;
        bytes.push(chk_sum);
    
        return this.byteArrayToHexString(bytes);
      }
    
      hexStringToByteArray(s: string): number[] {
        let b: number[] = [];
        for (let i = 0; i < s.length/2; i++) {
            let index: number = i * 2;
            let v: number = Number.parseInt(s.substring(index, index + 2), 16);
            if (isNaN(v)) {
              throw new Error('Could not convert string to byte array: ' + s);
            }
            b[i] = v;
        }
        return b;
      }
    
      byteArrayToHexString(data: number[]): string {
        if (data == null) {
            throw new Error('Could not convert byte array to hex string.');
        }
    
        let result: string = "";
    
        for (let b of data) {
          if (b < 0) {
            b = 256 + b;
          }
          let stringHex: string = b.toString(16);
          if (stringHex.length < 2) {
            stringHex = "0" + stringHex;
          }
          result = result + stringHex;
        }
        return result;
      }
    
      formatLine(one: string, two: string): string{
        if (one.length < 30) {
          let spaceLength = 30 - one.length;
          for (let i = 0; i < spaceLength; i++) {
            one = one + " ";
          }
        }
        return one.toUpperCase() + " # " + two + "\r\n";
      }
    
      formatLineThreeElements(one: string ,two: string, three: string): string {
        if (one !== null) {
          if (one.length < 30) {
            let spaceLength = 30 - one.length;
            for (let i = 0; i < spaceLength; i++) {
              one = one + " ";
            }
          }
      
          if (two.length < 40) {
            let spaceLength = 40 - two.length;
            for (let i = 0; i < spaceLength; i++) {
              two = two + " ";
            }
          }
      
          if (three.length < 10) {
            let spaceLength = 10 - three.length;
            for (let i = 0; i < spaceLength; i++) {
              three = " " + three;
            }
          }
      
          return one.toUpperCase() + " # " + two + " : " + three + "\r\n";
        } else {
          return "";
        }
      }
    
      setTrailer(ule: string, activeInterfaceView: string, activeInterfaceValue: string ): string {
        let result: string = "";
        result = result + "#-------< Set active interface to " + activeInterfaceView + " and copy to user area >----\r\n";
        result = result + this.addChecksum(this.CI_INTERFACE_TYPE + activeInterfaceValue).toUpperCase() + "\r\n";
        result = result + this.commitToUser();
        result = result + this.setULE(ule);
        result = result + "$\r\n";
    
        return result;
      }
    
      setULE(ule: string): string {
        let result: string = "";
        result = result + "#-------< BEGIN ULE SCRIPT >-------------------------------\r\n";
        let cmdList: string[] = this.getUleCmdList(ule);
        for(let cmd of cmdList) {
            cmd = cmd.substring(2); // cut off "$U" prefix used for HHS
            cmd = this.byteArrayToHexString(this.CA_LABEL_EDIT_SCRIPT) + cmd;
            result = result + this.addChecksum(cmd).toUpperCase() +"\r\n";
        }
        result = result + "#-------< END ULE SCRIPT >-------------------------------\r\n";
    
        return result;
      }
    
      getUleCmdList(_ule: string): string[] {
        let ule: string = this.stripComment(_ule);
        let cmdList: string[] = [];
        let lenUle: number = ule.length;
        let adjLen: number = lenUle + this.adjustLen(lenUle);
        let lenS: string = (adjLen + 2).toString(16);
        let lenSLength: number = lenS.length;
        if (lenSLength < 4) {
          for (let i = 0; i < 4 - lenSLength; i++) {
            lenS = "0" + lenS;
          }
        }
        let cmdStart: string = "$U00000008554C4532" + lenS + "0A0A";
        cmdList.push(cmdStart);
        let cmd: string = "";
        let addr: number = 8;
        let len: number = 0;
    
        if (ule.length > len) {
          ule = ule.substring(len);
          len = 16;
          while (true) {
            cmd = this.getUleCmd(ule, addr, len);
            if (cmd !== "") {
              cmdList.push(cmd);
            }
            addr += len;
            if (ule.length > len) {
              ule = ule.substring(len);
            } else {
              break;
            }
          }
        }
        return cmdList;
      }
    
      getUleCmd(ule: string, addr: number, maxlen: number): string {
        if (ule === "") {
            return "";
        }
        let cmd: string = "$U";
        if (ule.length < maxlen) {
            maxlen = ule.length;
        }
        if (maxlen == 0) {
            return "";
        }
        let adjLen: number = this.adjustLen(maxlen);
        for (let i = 0; i < adjLen; i++) {
            ule += this.ap0A;
        }
        maxlen += adjLen;
        let addrHex = addr.toString(16);
        let addrHexLength: number = addrHex.length;
        if (addrHexLength < 4) {
          for (let i = 0; i < 4 - addrHexLength; i++) {
            addrHex = "0" + addrHex;
          }
        }
        cmd += addrHex.toUpperCase();
        
        let maxlenHex = maxlen.toString(16);
        let maxlenHexLength: number = maxlenHex.length;
        if (maxlenHexLength < 4) {
          for (let i = 0; i < 4 - maxlenHexLength; i++) {
            maxlenHex = "0" + maxlenHex;
          }
        }
        cmd += maxlenHex.toUpperCase();
    
        for (let i = 0; i < maxlen; i++) {
          let c: number = ule.charCodeAt(i);
          let h: string = c.toString(16);
          let hLength: number = h.length;
          if (hLength < 2) {
            for (let i = 0; i < 2 - hLength; i++) {
              h = "0" + h;
            }
          }
          h = h.toUpperCase();
          cmd += h;
        }
        return cmd;
      }
    
      adjustLen(maxlen: number): number {
        if (maxlen == 4) {
          return 0;
        }
        if (maxlen < 4) {
          return 4 - maxlen;
        } else if (maxlen < 8) {
          return 8 - maxlen;
        } else {
          let mod4: number = maxlen % 4;
          if (mod4 != 0) 
          {
            return 4 - mod4;
          }
        }
        return 0;
      }
    
      stripComment(ule: string): string {
        let result: string = "";
        let inComment: boolean = false;
        let inString: boolean = false;
        for (let i = 0; i < ule.length; i++) {
          let c: string = ule.charAt(i);
          if (!inString) {
            if (c == ';' || c == '#') {
              inComment = true;
            } else if (c.charCodeAt(0) === 10 || c.charCodeAt(0) === 13) {
              inComment = false;
            }
          }
          if (!inComment) {
            if (c.charCodeAt(0) !== 13 && c.charCodeAt(0) !== 9) {
              if (c === '"') {
                inString = !inString;
              }
              if (c.charCodeAt(0) != 32 || inString) {
                result = result + c;
              }
            }
          }
        }
        //System.out.println(sb.toString());
        return result;
      }
    
      setAllInterfaces(activeInterfaceView: string, activeInterfaceValue: string): string {
        let result: string = "";
        let interfacesObj = this.productComponent.data.interfaceClasses;
        // creating array of [interface name, interface ID]
        var interfacesArray = Object.keys(interfacesObj).map((key) => [key, interfacesObj[key]]);
        // sort alphabetically by ID
        interfacesArray.sort((a,b) => a[1] > b[1] ? 1 : -1);
        interfacesArray.forEach (interfaceDataPair => {
          let interfaceView = interfaceDataPair[0];
          let interfaceValue = interfaceDataPair[1];
          if (activeInterfaceValue !== interfaceValue) {
            if (interfaceValue !== "0046") { // skip System interface 
              result = result + this.setInterface(interfaceView, interfaceValue);
            }
          }
        });
    
        if (activeInterfaceValue !== "0046") { // skip System interface 
          result = result + this.setInterface(activeInterfaceView, activeInterfaceValue);
        }
        return result;
      }
    
      setInterface(currentInterfaceView: string, currentInterfaceValue: string): string {
        let result: string = "";
        result = result + "# Interface Block The Following Interfaces:\r\n";
        result = result + "#-----<" + currentInterfaceView + ">-----\r\n";
        result = result + this.generateInterfaceHeader(currentInterfaceValue);
    
        result = result + this.getCCFDetailLine(currentInterfaceValue);
    
        // generate interface tail;
        result = result + this.commit();
        result = result + "#\r\n";
    
        return result;
      }
    
      generateInterfaceHeader(currentInterfaceValue: string): string {
        let temp: string = this.restore() + currentInterfaceValue;
        let comment: string = "";
        
        switch(this.restore()) {
            case "7FFB":
                comment = "Restore from Master Defaults";
                break;
            case "7FFC":
                comment = "Restore from Factory Settings";
                break;
            case this.CI_INTERFACE_TYPE:
                comment = "Switch Interface Without Restore";
                break;
        }
        return this.formatLine(this.addChecksum(temp),comment);
      }
    
      restore(): string {
        if (this.doRestore) {
            if (this.masterRestore) {
                return "7FFB";
            } else {
                return "7FFC";
            }
        } else {
            return this.CI_INTERFACE_TYPE;
        }
      }
    
      getCCFDetailLine(currentInterfaceName: string): string {
        let result: string = "";
        let paramMap = new Map();
        // get map of param codes to parameters
        for (let j = 0; j < this.productComponent.data.configurationPages.length; j++) {
          for (let l = 0; l < this.productComponent.data.configurationPages[j].configurationElements.length; l++) {
            if(this.productComponent.data.configurationPages[j].configurationElements[l].parameter !== undefined && !paramMap.has(this.productComponent.data.configurationPages[j].configurationElements[l].parameter.code)) {
              paramMap.set(this.productComponent.data.configurationPages[j].configurationElements[l].parameter.code, this.productComponent.data.configurationPages[j].configurationElements[l].parameter);
            }
          }
        }
        // create list of param codes and sort
        let paramCodes = Array.from( paramMap.keys() );
        paramCodes.sort((a,b) => a > b ? 1 : -1);
        // generate lines
        paramCodes.forEach(paramCode => {
          let currentParameter: Parameter = paramMap.get(paramCode);
          let isInterfaceParameter: boolean = this.productComponent.isInterfaceChangeParameter(paramCode);
  
          if (!isInterfaceParameter) {
            if (currentParameter.childParameters.length === 0) {
              let currentDefaultValueForCCF = "";
              let currentValueForCCF = "";
              if (currentParameter.valuesForInterfaces !== undefined && currentParameter.valuesForInterfaces.has(currentInterfaceName)) {
                currentDefaultValueForCCF = currentParameter._masterDefaultValue;
                currentValueForCCF = currentParameter.valuesForInterfaces.get(currentInterfaceName);
              } else {
                currentDefaultValueForCCF = currentParameter._masterDefaultValue;
                currentValueForCCF = currentParameter._masterValue;
              }
              if (currentDefaultValueForCCF !== currentValueForCCF){ 
                if (!currentParameter.isCentralized && !currentParameter.isSecured) {
                  result = result + this.generateConfigFileLine(currentParameter.code, currentValueForCCF, currentParameter);
                }
              }
            } else { // handles multiparameters here
              let currentDefaultValueForCCF = "";
              let currentValueForCCF = "";
              if (currentParameter.childParameters.every(it => it.valuesForInterfaces !== undefined && it.valuesForInterfaces.has(currentInterfaceName))) {
                currentDefaultValueForCCF = currentParameter.childParameters.map(it => it._masterDefaultValue).join('');
                currentValueForCCF = currentParameter.childParameters.map(it => it.valuesForInterfaces.get(currentInterfaceName)).join('');
              } else {
                currentDefaultValueForCCF = currentParameter.childParameters.map(it => it._masterDefaultValue).join('');
                currentValueForCCF = currentParameter.childParameters.map(it => it._masterValue).join('');
              }
              if (currentDefaultValueForCCF !== currentValueForCCF){ 
                if (!currentParameter.isCentralized && !currentParameter.isSecured) {
                  result = result + this.generateConfigFileLine(currentParameter.code, currentValueForCCF, currentParameter);
                }
              }
            }
          }
        });
        return result;
      }
    
      generateConfigFileLine(tagName: string, value: string, paramDetails: Parameter): string {
        let returnValue: string = "";
        // don't send CI_INTERFACE_TYPE, MCF_VERSION, or CI_CONFIGURATION_FILE_ID
        if ((tagName !== this.CI_INTERFACE_TYPE) && (tagName !== "0060")) {
          if (paramDetails !== undefined) {
            // TODO validateTagItem
            // exclude tags that should not be sent to device and those whose
            // value is equal to the master value already
            if ((paramDetails.isSendToDevice) && (value !== paramDetails._masterDefaultValue)) {
              let tagItem: string = tagName + value;
              returnValue = this.addChecksum(tagItem);
              if (this.addComments) {
                returnValue = this.formatLineThreeElements(
                        returnValue,
                        paramDetails.label,
                        this.getDisplayText(value, paramDetails)
                );
              } else {
                returnValue = returnValue + "\r\n";
              }
            }
          } else {
            let tagItem: string = tagName + value;
            returnValue = this.addChecksum(tagItem);
            returnValue = returnValue + "\r\n";
          }
        }
        return returnValue;
      }
    
      getDisplayText(value: string, paramDetails: Parameter): string {
        let returnValue: string = "";
    
        if ((value !== undefined) && (value !== null) && (paramDetails !== undefined) && (paramDetails !== null)) {
          if (paramDetails.type === "enum" && paramDetails.options.length > 0) {
            for (let option of paramDetails.options) {
              if (option.value === value) {
                returnValue = option.name;
              }
            }
          } else if (paramDetails.type === "hexInt") {
            returnValue = parseInt(value, 16).toString();
          } else {
            returnValue = value;
            while (returnValue.startsWith("00")) {
              returnValue = returnValue.substring(2);
            }
        
            while (returnValue.endsWith("00")) {
              returnValue = returnValue.substring(0, returnValue.length - 2);
            }
          }
        }
    
        return returnValue;
      }
}