import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Observable, forkJoin, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { GeneralCloudService } from './general-cloud.service';
import { protectedResources } from '../../auth-config';

@Injectable({
  providedIn: 'root'
})
export class RemoteManagementService extends GeneralCloudService {
  //urlEndpoint: string = "https://apim-dl-cloud-rmtmgt-qa.azure-api.net/devices";
  urlEndpoint: string = protectedResources.apiTodoList.endpointRemoteManagement + "devices";
  apiVersion: string = protectedResources?.apiTodoList?.apiVersion || "";
  constructor(protected http: HttpClient, protected authService: MsalService) { super(http, authService)}

  getListDeviceRemote(): Observable<Object> {
    const url = this.urlEndpoint + `?api-version=${this.apiVersion}`;
    return this.getToken().pipe(
      switchMap((value: HttpHeaders) => {
        return this.http.get(url, {headers: value});
      })
    )
  }

  getPropertiesDeviceById(id: string, options: any = null): Observable<Object> {
    const url = `${this.urlEndpoint}/${id}/properties?api-version=${this.apiVersion}`;
    if(options) {
      return this.http.get(url, options);
    } else {
      return this.getToken().pipe(
        switchMap((value: HttpHeaders) => {
          return forkJoin([this.http.get(url, {headers: value}), of(id)]);
        })
      )
    }
  }

  runCommandOnDevice(id: string, commandName: string, data: any = null) {
    const url = `${this.urlEndpoint}/${id}/commands/${commandName}?api-version=${this.apiVersion}`;
    return this.getToken().pipe(
      switchMap((headers: HttpHeaders) => {
        headers.set('Content-Type', "application/json")
               .set('Accept', "application/json");
        
        let body = {
          "request": {}
        };

        if(data) {
          body = data;
        }
        return this.http.post(url, body ,{headers})
      })
    )
  }
}
