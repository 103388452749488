import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationType } from 'src/app/classes/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-alternative-products-modal',
  templateUrl: './alternative-products-modal.component.html',
  styleUrls: ['./alternative-products-modal.component.css']
})
export class AlternativeProductsModalComponent implements OnInit {

  @ViewChild("notification") notification: NotificationComponent;
  serialNumber: string = "";
  defaultDeviceValue: string = "Choose one...";
  defaultReleaseSWValue: string = "Choose release...";
  currentDeviceValue: string;
  currentReleaseSWValue: string;
  clickOrEnterValue: boolean = false;
  swRelease: string[];
  deviceList: string[] = [];
  deviceMap: Map<string, string[]> = new Map<string, string[]>();
  inputSearch: FormControl;

  constructor(private dataService: DataService, private router: Router, private translate: TranslateService) {
    this.currentDeviceValue = this.defaultDeviceValue;
    this.currentReleaseSWValue = this.defaultReleaseSWValue;
    this.inputSearch = new FormControl("");
   }
  ngOnInit(): void {

    this.dataService.getProducts().subscribe((value) => {
      if(value) {
        value.forEach((element) => {
          const split = element.split("_");
          if(this.deviceMap.has(split[0])) {
            this.deviceMap.set(split[0], [...this.deviceMap.get(split[0]), split[1]])
          } else {
            this.deviceMap.set(split[0], [split[1]]);
          }
        });

        this.deviceList = [...this.deviceMap.keys()].sort();
      }
    });

    this.inputSearch.valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        
      }),
    ).subscribe((value) => {
      this.deviceList = this.getListModel().filter((val) => val.toLowerCase().includes(value.toLowerCase()));
      console.log(this.deviceList)
    })
  }

  selectOptionValue(value: string, isRelease: boolean = false) {
    if(!isRelease) {
      this.inputSearch.setValue(value);
      this.currentDeviceValue = value; 
      this.clickOrEnterValue = false;
      this.swRelease = this.deviceMap.get(this.currentDeviceValue);
    } else {
      this.currentReleaseSWValue = value;
    }
  }

  getListModel() {
    return [...this.deviceMap.keys()].sort();
  }

  show() {
    const element: HTMLElement = document.getElementById('alternativeProductButton');
    element.click();
  }

  gotoProductPage() {
    const element: HTMLElement = document.getElementById('closeProductModal');
    
    if(this.checkDeviceValue()) {
      this.notification.showAlert(NotificationType.Warning, this.translate.instant('NOTIFICATION.PLEASE-CHOOSE-DEVICE'));
    } else if(this.currentReleaseSWValue == this.defaultReleaseSWValue) {
      this.notification.showAlert(NotificationType.Warning, this.translate.instant('NOTIFICATION.PLEASE-CHOOSE-RELEASE'));
    } else if(this.serialNumber) {
      const internalName = this.currentDeviceValue + "_" + this.currentReleaseSWValue;
      this.dataService.getProductIndexByName(internalName).subscribe((value: Number) => {
        this.router.navigate(['configuration', "product", value, 'null', "azure"], {queryParams: {id: this.serialNumber}});
        element.click();
      })
    }
  }

  focusOut() {
    this.currentReleaseSWValue = this.defaultReleaseSWValue;
    setTimeout(() => {
      this.clickOrEnterValue = false;
    }, 300)
  }

  checkDeviceValue() {
    return this.getListModel().findIndex(val => val == this.inputSearch.value) == -1;
  }
}
