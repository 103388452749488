import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs'; 
import { Helper } from 'src/app/classes/utility';
import { DataService } from 'src/app/services/data.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DetectionModeType } from '../classes/detection-setting';

@Injectable({
  providedIn: 'root'
})
export class RemoteService {

  private name: any;
  private connectedUser: any;
  yourConn: RTCPeerConnection; 
  private conn: WebSocket;
  allowRemoteConfigButtonText: BehaviorSubject<string> = new BehaviorSubject<string>("Allow");
  startRemoteConfigButtonText: BehaviorSubject<string> = new BehaviorSubject<string>("Remote");
  isRemoted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  listRemoteDevicesCurrent: BehaviorSubject<string[][]> = new BehaviorSubject<string[][]>([]);
  listRemoteDevices: EventEmitter<string[][]> = new EventEmitter<string[][]>();
  mapDataChannels = new Map<string, any>();
  triggerDataChannel: EventEmitter<string> = new EventEmitter<string>();
  remoteAppId: string = this.genUniqueId();
  remoteWebId: string = this.genUniqueId();
  standaloneMode: boolean;
  stateDectectDeviceCurrent: BehaviorSubject<string> = new BehaviorSubject<string>("Closed");
  stateDectectDevice: EventEmitter<string> = new EventEmitter<string>();
  detectionModeCurrent: BehaviorSubject<DetectionModeType> = new BehaviorSubject<DetectionModeType>(DetectionModeType.DefaultPorts);
  detectionMode: EventEmitter<DetectionModeType> = new EventEmitter<DetectionModeType>();
  websocketServer: string;
  turnServer: string;
  private productsList: string[] = undefined;
  private scannerTextToXmlText: Map<string, string> = new Map<string, string>([
                                                            ["Magellan-9800i-CD", "Magellan-9800i"],
                                                            ["Magellan-9800i lite", "Magellan-9800i"],
                                                            ["Magellan-9300i", "Magellan-9400i"],
                                                            ["Magellan-9400i-CD", "Magellan-9400i"],
                                                            ["Magellan-3410VSI", "Magellan-3x10i"],
                                                            ["Magellan-3510HSI", "Magellan-3x10i"],
                                                            ["Magellan-3450VSI", "Magellan-3x50i"],
                                                            ["Magellan-3550HSI", "Magellan-3x50i"],
                                                            ["Magellan-9600i", "Magellan-9900i"]
                                                          ]);

  private portList: any = [];
  private formList: FormArray = new FormArray([]);
  portListSideBarCurrent: BehaviorSubject<any> = new BehaviorSubject<any>(this.portList);
  portListSideBar: EventEmitter<any> = new EventEmitter<any>();
  formListSideBarCurrent: BehaviorSubject<FormArray> = new BehaviorSubject<FormArray>(this.formList);
  formListSideBar: EventEmitter<FormArray> = new EventEmitter<FormArray>();
  private listOnlineDevices: string[][] = [];
  listOnlineDevicesSideBarCurrent: BehaviorSubject<string[][]> = new BehaviorSubject<string[][]>([]);
  listOnlineDevicesSideBar: EventEmitter<string[][]> = new EventEmitter<string[][]>();

  private getRemoteDeviceListRequest = "getRemoteDeviceListRequest";
  private getRemoteDeviceListResponse = "getRemoteDeviceListResponse";
  private openRemoteDeviceRequest = "openRemoteDeviceRequest";
  private openRemoteDeviceResponse = "openRemoteDeviceResponse";
  private selectTabRequest = "selectTabRequest";
  private closeTabRequest = "closeTabRequest";
  private stopSearchDeviceRequest = "stopSearchDeviceRequest";
  private getPortListRequest = "getPortListRequest";
  private getCurrentPortListResponse = "getCurrentPortListResponse";
  private getUpdatePortListResponse = "getUpdatePortListResponse";
  private setSearchDeviceStatusRequest = "setSearchDeviceStatusRequest";
  private setDeviceCOMSearchSettingRequest = "setDeviceCOMSearchSettingRequest";
  private getDeviceCOMSearchSettingRequest = "getDeviceCOMSearchSettingRequest";


  constructor(private dataService: DataService, private fb: FormBuilder) {
    this.setUpPortList([]);
    Helper.standaloneMode.subscribe(value => {
      this.standaloneMode = value;
    });

    Helper.websocketServer.subscribe(value => {
      this.websocketServer = value;
      console.log("websocketServer: ", this.websocketServer);
    });
    
    Helper.turnServer.subscribe(value => {
      this.turnServer = value;
      console.log("turnServer: ", this.turnServer);
    });


    this.dataService.getProducts().subscribe(productsList => {
      this.productsList = productsList;
    });
  }

  genUniqueId(): string {
    const dateStr = Date.now().toString(36).toUpperCase(); // convert num to base 36 and stringify
    const randomStr = Math.random().toString(36).substring(2, 4).toUpperCase(); // start at index 2 to skip decimal point
    return dateStr + randomStr;
  }
  
  webSocketInit(): void {
    //this.conn = new WebSocket('ws://web1947.eastasia.cloudapp.azure.com:8080');
    this.conn = new WebSocket(this.websocketServer);
    //connecting to our signaling server 

    this.conn.onopen = () => { 
      console.log("Connected to the signaling server");
    };
    
    //when we got a message from a signaling server 
    this.conn.onmessage = (msg) => { 
      console.log("Got message", msg.data); 
      var data = JSON.parse(msg.data); 
      
      switch(data.type) { 
          case "login": 
            this.handleLogin(data.success); 
            break; 
          //when somebody wants to call us 
          case "offer": 
            this.handleOffer(data.offer, data.name); 
            break; 
          case "answer": 
            this.handleAnswer(data.answer); 
            break; 
          //when a remote peer sends an ice candidate to us 
          case "candidate": 
            this.handleCandidate(data.candidate); 
            break; 
          case "leave": 
            this.handleLeave();
            if (!this.standaloneMode) {
              this.closeWebSocket();
              this.startRemoteConfigButtonText.next("Remote");
            }
            break; 
          default: 
            break; 
      } 
    }; 

    this.conn.onerror = (err) => { 
      console.log("Got error", err); 
    };

  }

  webRtcInit() {
    const configuration: RTCConfiguration = {
      iceServers: [
        {
          urls: 'stun:stun.l.google.com:19302', 
        },
        {
          urls: this.turnServer,
          username: 'aladdinremoteapp',
          credential: 'mQidWSF234W23ds450erFE21EwsdfQD39378'
        }
      ]
    };

    this.yourConn = new RTCPeerConnection(configuration); 

    //Starting a peer connection 
    //Setup ice handling 
    this.yourConn.onicecandidate = (event) => { 
      if (event.candidate) { 
        this.sendWebsocketMessage({ 
            type: "candidate", 
            candidate: event.candidate 
          }); 
      } 
    }; 

    this.yourConn.ondatachannel = (event) => {
      if (!this.mapDataChannels.has(event.channel.label)) {
        this.mapDataChannels.set(event.channel.label, event.channel);

        this.mapDataChannels.get(event.channel.label).onerror = (error) => { 
          console.log("Ooops...error:", error); 
        }; 
        
        if (event.channel.label === "root-channel") {
          //when we receive a message from the other peer, display it on the screen 
          this.mapDataChannels.get("root-channel").onmessage = (event) => {
            console.log("this.mapDataChannels.root-channel.onmessage:", event.data); 
            let data = JSON.parse(event.data); 
            if (data.function === this.getRemoteDeviceListRequest) {
              //call java function from javascript
              let portsString = data.portListString;
              let portList = portsString.split(";");
              this.updateSelectedPortList(portList);
              let responseFromAladdin = window.getRemoteDeviceHandlerFromJs(portsString);
              //this.sendCurrentDevicesToRemote(responseFromAladdin); 
            } else if(data.function === this.openRemoteDeviceRequest) {
              let deviceIndex = data.deviceIndex;
              let deviceName = data.deviceName;
              let deviceConnection = data.deviceConnection;
              //call java function from javascript
              let responseFromAladdin = window.openRemoteDeviceHandlerFromJs(deviceIndex, deviceName, deviceConnection);
              if (responseFromAladdin.length > 0) {
                this.mapDataChannels.get("root-channel").send('{"function":"' + this.openRemoteDeviceResponse
                                                            + '","url":"' + responseFromAladdin + '"}'); 
              }
            } else if(data.function === this.selectTabRequest) {
              let stringMessage = data.url;
              //call java function from javascript
              let responseFromAladdin = window.selectTabHandlerFromJs(stringMessage);
              console.log("selectTabRequest responseFromAladdin: ", responseFromAladdin); 
            } else if(data.function === this.closeTabRequest) {
              let stringMessage = data.url;
              //call java function from javascript
              let responseFromAladdin = window.closeTabHandlerFromJs(stringMessage);
              console.log("selectTabRequest responseFromAladdin: ", responseFromAladdin); 
            } else if (data.function == this.stopSearchDeviceRequest) {
              window.stopSearchDeviceHandlerFromJs("stopSearchDevice");
            } else if(data.function == this.getPortListRequest) {
              let responseFromAladdin: string = window.hoverSearchDeviceButtonFromJs("getPortListRequest");
            } else if(data.function == this.setDeviceCOMSearchSettingRequest) {
              if(data.content){
                if(data.content == DetectionModeType.AllPorts){
                  this.detectionModeCurrent.next(DetectionModeType.AllPorts);
                  this.detectionMode.next(DetectionModeType.AllPorts);
                }else if(data.content == DetectionModeType.DefaultPorts){
                  this.detectionModeCurrent.next(DetectionModeType.DefaultPorts);
                  this.detectionMode.next(DetectionModeType.DefaultPorts);
                }
              }
            } else if (data.function === this.getDeviceCOMSearchSettingRequest) {
              this.sendCurrentDeviceCOMSearchSetting(this.detectionModeCurrent.getValue());
            }
          }
        } else {
          //when we receive a message from the other peer, display it on the screen 
          console.log("deviceChannel ", event.channel.label); 
          this.triggerDataChannel.next(event.channel.label);
        }
      }
    };

    if(!this.standaloneMode) {
      //creating data channel
      let dataChannelCreated = this.yourConn.createDataChannel("root-channel");
      this.mapDataChannels.set("root-channel", dataChannelCreated); 
    
      this.mapDataChannels.get("root-channel").onerror = (error) => { 
        console.log("Ooops...error:", error); 
      }; 
    
      //when we receive a message from the other peer, display it on the screen 
      this.mapDataChannels.get("root-channel").onmessage = (event) => {  
        let data = JSON.parse(event.data); 
        if (data.function === this.getRemoteDeviceListResponse) {
          let tmpListRemoteDevices: string[][] = this.generateDeviceListData(data.content);
          this.listRemoteDevicesCurrent.next(tmpListRemoteDevices);
          //work around when ngfor not update after change array
          this.listRemoteDevices.next(tmpListRemoteDevices);
        } else if (data.function === this.openRemoteDeviceResponse) {
          let stringMessage = data.url;
          if (stringMessage === "deviceChanged") {
            throw Error("The device connection has been changed. Please detect device again.");
          } else {
            //load url
            let url = stringMessage.substring(stringMessage.indexOf("#/configuration"));
            url += "/remote";
            window.open(url, "_self");
          }
        } else if (data.function === this.getCurrentPortListResponse) {
          let portList = data.content.split(";");
          this.setUpPortList(portList);
        } else if (data.function === this.getUpdatePortListResponse) {
          let ports: string[] = data.ports? data.ports.split(";") : [];
          this.updatePortListFromJava(ports);
        } else if (data.function == this.setSearchDeviceStatusRequest) {
          let stringMessage = data.status;
          this.setSearchDeviceStatus(stringMessage);
        } else if(data.function == this.setDeviceCOMSearchSettingRequest) {
          if(data.content){
            if(data.content == DetectionModeType.AllPorts){
              this.detectionModeCurrent.next(DetectionModeType.AllPorts);
              this.detectionMode.next(DetectionModeType.AllPorts);
            }else if(data.content == DetectionModeType.DefaultPorts){
              this.detectionModeCurrent.next(DetectionModeType.DefaultPorts);
              this.detectionMode.next(DetectionModeType.DefaultPorts);
            }
          }
        }
      }
    }

  }

  //alias for sending JSON encoded messages 
  sendWebsocketMessage(message): void { 

    //attach the other peer username to our messages
    if (this.connectedUser) { 
      message.name = this.connectedUser; 
    } 
  
    //attach the name of sender
    if (this.name) { 
      message.from = this.name; 
    } 

    this.conn.send(JSON.stringify(message)); 
  }

  handleLogin(success): void { 
    if (success === false) {
       throw Error("Ooops...try a different username");
    }
 }

  //when somebody sends us an offer 
  handleOffer(offer, name): void { 
    this.webRtcInit();
    this.connectedUser = name; 
    this.yourConn.setRemoteDescription(new RTCSessionDescription(offer)); 

    this.yourConn.createAnswer().then((answer) => { 
      this.yourConn.setLocalDescription(answer); 
      this.sendWebsocketMessage({ 
          type: "answer", 
          answer: answer 
      });
    });
    
    
  }

  //when we got an answer from a remote user 
  handleAnswer(answer): void { 
    this.yourConn.setRemoteDescription(new RTCSessionDescription(answer)); 
  };

  //when we got an ice candidate from a remote user 
  async handleCandidate(candidate): Promise<void>  { 
    this.yourConn.addIceCandidate(new RTCIceCandidate(candidate)); 
    this.isRemoted.next(true);
    //wait 800 miliseconds to finish login process
    const sleep = (ms) => new Promise(r => setTimeout(r, ms));
    await sleep(800);
    if (!this.standaloneMode) {
      this.getRemoteDeviceExec("SELECT_ALL_SEARCH_DEVICE_OPTIONS");
    }
  }

  handleLeave(): void { 
    this.connectedUser = null; 
    if (this.yourConn !== null && this.yourConn !== undefined) {
      this.yourConn.close(); 
      this.yourConn.onicecandidate = null; 
      this.yourConn = null;
    }
    this.mapDataChannels = new Map<string, any>();
    this.isRemoted.next(false);
  }

  closeWebSocket() {
    if (this.conn !== null && this.conn !== undefined) {
      this.conn.close();
    }
  }
  
  async btnAllowRemoteConfigurationListener(): Promise<void> {
    if (this.allowRemoteConfigButtonText.value === "Allow") {
      this.webSocketInit();
      //wait 1 second to finish login process
      const sleep = (ms) => new Promise(r => setTimeout(r, ms));
      await sleep(1000);
      
      this.name = this.remoteAppId;
    
      if (this.name.length > 0) { 
        this.sendWebsocketMessage({ 
            type: "login", 
            name: this.name 
        }); 
      }
      
      let responseFromAladdin = window.setIsRemotedStatus("true");
      this.allowRemoteConfigButtonText.next("Close");
    } else {
      if (this.isRemoted) {
        this.sendWebsocketMessage({ 
          type: "leave" 
        }); 
        this.handleLeave(); 
      }
      this.closeWebSocket();
      let responseFromAladdin = window.setIsRemotedStatus("false");
      this.allowRemoteConfigButtonText.next("Allow");
    }
  }

  async btnStartRemoteConfigurationListener(callToUsername: string): Promise<void> {
    if (this.startRemoteConfigButtonText.value === "Remote") {
      this.webSocketInit();
      //wait 1 second to finish login process
      const sleep_0 = (ms) => new Promise(r => setTimeout(r, ms));
      await sleep_0(800);
      this.webRtcInit();
      //wait 1 second to finish login process
      const sleep_1 = (ms) => new Promise(r => setTimeout(r, ms));
      await sleep_1(800);

      this.name = this.remoteWebId;
    
      if (this.name.length > 0) { 
        this.sendWebsocketMessage({ 
            type: "login", 
            name: this.name 
        }); 
      }

      //wait 2 second to finish login process
      const sleep_2 = (ms) => new Promise(r => setTimeout(r, ms));
      await sleep_2(800);
    
      if (callToUsername.length > 0) { 
        this.connectedUser = callToUsername; 
        // create an offer 
        this.yourConn.createOffer().then((offer) => { 
          this.sendWebsocketMessage({ 
              type: "offer", 
              offer: offer 
          }); 
          this.yourConn.setLocalDescription(offer); 
        }); 
      }

      this.startRemoteConfigButtonText.next("Close");
    } else {
      if (this.isRemoted) {
        this.sendWebsocketMessage({ 
          type: "leave" 
        }); 
        this.handleLeave();
      }
      this.closeWebSocket();
      this.startRemoteConfigButtonText.next("Remote");
    } 
  }

  getRemoteDeviceExec(portListString: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getRemoteDeviceListRequest
                                                + '","portListString":"' + portListString + '"}');
  }

  getPortListExec() {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getPortListRequest +  '"}');
  }

  openRemoteDeviceExec(deviceIndex: string, deviceName: string, deviceConnection: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.openRemoteDeviceRequest 
                                                + '","deviceIndex":"' + deviceIndex
                                                + '","deviceName":"' + deviceName
                                                + '","deviceConnection":"' + deviceConnection + '"}');
  }

  setSearchDeviceStatusExec(status: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.setSearchDeviceStatusRequest 
                                                 + '","status":"' + status  + '"}');
  }

  sendCurrentDevicesToRemote(responseFromAladdin: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getRemoteDeviceListResponse 
                                                + '","content":' + responseFromAladdin + '}'); 
  }


  sendCurrentPortListToRemote(portList: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getCurrentPortListResponse 
                                                + '","content":"' + portList + '"}'); 
  }

  sendUpdatePortListToRemote(ports: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getUpdatePortListResponse 
                                                + '","ports":"' + ports + '"}'); 
  }

  selectTabListener(url: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.selectTabRequest 
                                                + '","url":"' + url + '"}');
  }
  
  closeTabListener(url: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.closeTabRequest 
                                                + '","url":"' + url + '"}');
  }

  stopSearchDeviceExec() {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.stopSearchDeviceRequest + '"}');
  }

  sendCurrentDeviceCOMSearchSetting(COMSearchSetting: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.setDeviceCOMSearchSettingRequest 
                                                + '","content":"' + COMSearchSetting + '"}'); 
  }

  getCurrentDeviceCOMSearchSetting(COMSearchSetting: string) {
    this.mapDataChannels.get("root-channel").send('{"function":"' + this.getDeviceCOMSearchSettingRequest + '"}'); 
  }

  setSearchDeviceStatus(value: string) {
    this.stateDectectDeviceCurrent.next(value);
    this.stateDectectDevice.next(value);
    if(this.standaloneMode && this.isRemoted) {
      this.setSearchDeviceStatusExec(value);
    }
  }

  generateDeviceListData(data: any): string[][] {
    let tmpListRemoteDevices: string[][] = [];
    let tmpList01 =  data;
    for (let i=0; i<tmpList01.length; i++) {
      if (tmpList01[i].deviceName && tmpList01[i].deviceName.length > 0) {
        let tmpList02: string[] = [tmpList01[i].deviceName, tmpList01[i].connection, tmpList01[i].image, "existent"];

        //Check if device is exist on website
        let lastSpaceIndex = tmpList02[0].lastIndexOf(" ");
        let productSpecificName = tmpList02[0].substring(0, lastSpaceIndex);
        let productSpecificVersion = tmpList02[0].substring(lastSpaceIndex + 1);
        
        if (this.scannerTextToXmlText.has(productSpecificName)) {
          productSpecificName = this.scannerTextToXmlText.get(productSpecificName);
        }

        let productSpecificFullName = productSpecificName + "_" + productSpecificVersion;
        if (!this.productsList.includes(productSpecificFullName)) {
          tmpList02[3] = "nonexistent";
        }
        tmpListRemoteDevices.push(tmpList02);
      }
    }

    return tmpListRemoteDevices;
  }

  selectPorts(check: boolean, index: number) {
    if(this.portList[index].value == "SELECT_ALL_SEARCH_DEVICE_OPTIONS") {
      this.portList.forEach((val) => {
        val.check = !check;
      });
      window.selectAllInSearchDeviceButtonFromJs((!check).toString());
    } else {
      if(this.portList[0].check && check) {
        this.portList[0].check = false;
      }
      this.portList[index].check = !check;
      if(!check && this.portList.filter((val) => !val.check).length == 1) {
        this.portList[0].check = !check;
      }
    }

    this.portList = [...this.portList];
    this.updateFormList();
  }

  updateFormList() {
    this.formList.clear();
    let formArr = new FormArray([]);
    this.portList.forEach((item: any) => {
      let formControlValue = this.fb.control(item.value);
      let formControlCheck = this.fb.control(item.check);
      let formGroup: FormGroup = this.fb.group({
        value: formControlValue,
        check: formControlCheck
      })

      formArr.push(formGroup);
    });
    this.formList = formArr;
    this.formListSideBarCurrent.next(this.formList);
    this.formListSideBar.next(this.formList);
  }

  initPortList(ports: string[]): string[] {
    let temp: any = [];
    temp.push({value: "SELECT_ALL_SEARCH_DEVICE_OPTIONS", check: true});
    ports.forEach(port => {
      if (port.trim().length > 0) {
        temp.push({value: port, check: true});
      }
    });

    temp.push({value: "ETHERNET", check: true});
    return temp;
  }

  updatePortList(ports: String[]) {
    ports.forEach(port => {
      if(port) {
        if(this.portList.findIndex((val) => val.value == port) == -1) {
          if (this.portList.find((element) => element.value === "SELECT_ALL_SEARCH_DEVICE_OPTIONS").check === true) {
            this.portList.splice(this.portList.length-1, 0, {value: port, check: true});
          } else {
            this.portList.splice(this.portList.length-1, 0, {value: port, check: false});
          }
        }
      } 
    });

    // Remove port
    this.portList.forEach((item: any, index: number) => {
      if(!ports.includes(item.value) && item.value != "SELECT_ALL_SEARCH_DEVICE_OPTIONS" && item.value!="ETHERNET") {
        this.portList.splice(index, 1);
      }
    });
  }

  updatePortListFromJava(ports: string[]) {
    this.setUpPortList(ports);
  }

  setUpPortList(ports: string[]) {
    console.log(ports);
    if(ports.length > 0) {
      if(this.portList.length == 0) {
        this.portList = this.initPortList(ports);
      } else {
        this.updatePortList(ports);
      } 
    } else {
      this.portList = this.initPortList([]);
    }
    this.portListSideBarCurrent.next(this.portList);
    this.portListSideBar.next(this.portList);
    this.updateFormList();
  }

  updateSelectedPortList(selectedPorts: String[]) {
    if (this.portList.length > 0) {
      if (selectedPorts.findIndex((val) => val === 'SELECT_ALL_SEARCH_DEVICE_OPTIONS') !== -1) {
        for (let i = 0; i < this.portList.length; i++) {
          let tempPortValue = this.portList[i].value;
          this.portList.splice(i, 1, {value: tempPortValue, check: true});
        }
      } else {
        for (let i = 0; i < this.portList.length; i++) {
          let tempPortValue = this.portList[i].value;
          if(selectedPorts.findIndex((val) => val === tempPortValue) !== -1) {
            this.portList.splice(i, 1, {value: tempPortValue, check: true});
          } else {
            this.portList.splice(i, 1, {value: tempPortValue, check: false});
          }
        }
      }
    }
  }

  setUpSelectedPortList(selectedPorts: string[]) {
    if (this.portList.length > 0 && selectedPorts.length > 0) {
      console.log(selectedPorts);
      this.updateSelectedPortList(selectedPorts);

      this.portListSideBarCurrent.next(this.portList);
      this.portListSideBar.next(this.portList);
      this.updateFormList();
    }
  }

  // send port to java
  getPortList(): string {
    let listPortSelected: string[] = this.portList.filter((val)=> val.check).map((val)=> val.value);
    if(listPortSelected.length == 0) {
      return ";";
    }
    return listPortSelected.join(";");
  }

  clearListOnlineDevices() {
    this.listOnlineDevices = [];
    this.listOnlineDevicesSideBarCurrent.next(this.listOnlineDevices);
    this.listOnlineDevicesSideBar.next(this.listOnlineDevices);
  }

  pushListOnlineDevices(displayName: string, id: string, picturePath: string, productIndex: string, model: string, releaseSW: string, serialNumber: string) {
    this.listOnlineDevices.push([displayName, id, picturePath, productIndex, model, releaseSW, serialNumber]);
    this.listOnlineDevicesSideBarCurrent.next(this.listOnlineDevices);
    this.listOnlineDevicesSideBar.next(this.listOnlineDevices);
  }

  clearListRemoteDevices() {
    this.listRemoteDevicesCurrent.next([]);
    this.listRemoteDevices.next([]);
  }
}
