import { BaseRequestOptions } from '@angular/http';
import { Product } from '../products/product';

export class TabItem {
  constructor(id: string, url: string, name: string, mode: string = "") {
    this.id = id;
    this.url = url;
    this.name = name;
    if (mode) {
      this.mode = " [" + mode.toUpperCase()  + "]";
    } else {
      this.mode = "";
    }
  }
  public url: string;
  public name: string;
  public id: string;
  public mode: string = '';
  public terminalContents: string[] = [];

  static fromProduct(product: Product, connectedTo: string, mode: string = "", serialNumber: string = ""): TabItem {
    let queryParams = serialNumber? "?id=" + serialNumber : "";

    queryParams = queryParams.slice(0, queryParams.length);
    const url = '/configuration/product/' + product.index +
      (connectedTo !== undefined && connectedTo !== null && connectedTo !== '' ?  '/' + connectedTo : '') +
      (mode ? "/" + mode : '') + queryParams;
    return new this(
      product.internalName,
      url,
      product.name,
      mode
    );
  }
}
