<button type="button" id="compareConfigurationButton" class="btn btn-primary" data-toggle="modal" data-target="#compareConfigurationModal"></button>
<!-- Modal -->
<div class="modal fade" id="compareConfigurationModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">{{'HEADER.COMPARE-CONFIG' | translate}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body clearfix">
          <form>
            <div id="divCompareAllInterfaces" class="header-toggle custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchCompareAllInterfaces" 
              [checked]="getCompareAllInterfaces()" (change)="toggleCompareAllInterfaces()">
              <label class="custom-control-label" for="customSwitchCompareAllInterfaces">{{'COMPARE-CONFIG.COMPARE-ALL-INTERFACES' | translate}}</label>
            </div>
          </form>
          <div id="compare-table" class="container-fluid">
            <div class="row">
              <div [ngClass]="getCompareAllInterfaces() ? 'col-3' : 'col-4'">
                <span class="th"></span>
              </div>
              <div class="col-1" [hidden]="!getCompareAllInterfaces()">
                <span class="th"></span>
              </div>
              <div class="col-4">
                <span class="th">
                  <div class="btn btn-outline-warning btn-sm" id="btnLoadFirstCCF" (click)="loadFirstCCF()">
                    {{'PRODUCT.LOAD-CONFIGURATION' | translate}}
                  </div>
                  <input #firstCCFInput type="file" [accept]="acceptedFiles" (change)="loadedFirstCCF($event)"
                            class="hidden" />
                </span>
              </div>
              <div class="col-4">
                <span class="th dropdown-parent"><div class="dropdown show">
                  <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" 
                  aria-haspopup="true" aria-expanded="false">{{selectedComparisonOption | translate}}</a>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a (click)="selectComparisonOption(option)" class="dropdown-item"(click)="selectComparisonOption(option)"
                    *ngFor="let option of comparisonOptions">{{option | translate}}</a>
                  </div>
                </div> <div class="btn btn-outline-warning btn-sm second-load" id="btnLoadSecondCCF" (click)="loadSecondCCF()" [hidden]="!showLoadSecondFile()">
                  {{'PRODUCT.LOAD-CONFIGURATION' | translate}}
                </div>
                <input #secondCCFInput type="file" [accept]="acceptedFiles" (change)="loadedSecondCCF($event)"
                          class="hidden" /></span>
              </div>
            </div>
            <div class="row" [hidden]="getCompareAllInterfaces()">
              <div class="col-4">
              </div>
              <div class="col-4">
                <span class="th">
                  {{firstCCFInterface}}
                </span>
              </div>
              <div class="col-4">
                <span class="th" [hidden]="selectedComparisonOption !== 'COMPARE-CONFIG.CONFIGURATION-FILE'">{{secondCCFInterface}}</span>
              </div>
            </div>
            <div class="row">
              <div [ngClass]="getCompareAllInterfaces() ? 'col-3' : 'col-4'">
                <span class="th">{{'PRODUCT.PARAMETER' | translate}}</span>
              </div>
              <div class="col-1" [hidden]="!getCompareAllInterfaces()">
                <span class="th">{{'COMPARE-CONFIG.INTERFACE' | translate}}</span>
              </div>
              <div class="col-4">
                <span class="th">
                  {{firstColumnHeaderName}}
                </span>
              </div>
              <div class="col-4">
                <span class="th">{{secondColumnHeaderName}}</span>
              </div>
            </div>
            <div class="row" *ngFor="let comparison of compareConfigurationEntries">
              <div [ngClass]="getCompareAllInterfaces() ? 'col-3' : 'col-4'"><span class="td">{{comparison.parameterDisplayName}} ({{comparison.parameterCode}})</span></div>
              <div class="col-1" [hidden]="!getCompareAllInterfaces()">
                <span class="th">{{comparison.interfaceValue}}</span>
              </div>
              <div class="col-4">
                <span class="td">{{comparison.config1Value}}</span>
              </div>
              <div class="col-4">
                <span class="td">{{comparison.config2Value}}</span>
              </div>
            </div>
          </div>
             
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-warning" data-dismiss="modal">{{'SHARED.CLOSE' | translate}}</button>
         <!--<button type="button" class="btn btn-primary">Save changes</button>--> 
        </div>
      </div>
    </div>
</div>

