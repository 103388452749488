<div class="form-group row d-flex align-items-center justify-content-sm-start justify-content-center">
  <div class="col-12 col-sm-5 col-form-label" [ngClass]="!!paramParentText? 'text-sm-left': 'text-sm-right'">
    {{!!paramParentText? paramParentText : label}}
  </div>
  <div [ngClass]="{'col-8 col-sm-5' : customManualEnabled, 'col-8 col-sm-7': !customManualEnabled, 'blockSelection' : showInputCombo, 'flexSelection': !showInputCombo}">
    <ng-container *ngIf="parameter.childParameters.length === 0">
      <input *ngIf="showInputText" type="text" name="{{code}}" class="inputtext form-control text-right"
        [ngClass]="{'border-success text-success': currentValue !== deviceValue}" [readonly]="readonly"
        [(ngModel)]="currentValueText" (ngModelChange)="inputChangeValue($event)" (focusout)="checkParameter()" />
      <div *ngIf="showInputCombo" class="dropdown show" [ngClass]="{'disabled': readonly}">
        <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
        [ngClass]="{'border-success': currentValue !== deviceValue}" aria-haspopup="true" aria-expanded="false">
          {{currentValueText}} <span>{{this.getDropdownMarker(currentValue)}}</span>
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item"
            [ngClass]="{'active': option.value === currentValue}"
            (click)="selectOptionValue(option)"
            *ngFor="let option of parameter.options">
            {{option.name}} <span>{{this.getDropdownMarker(option.value)}}</span>
          </a>
        </div>
      </div>
      <div *ngIf="showInputComboAsCheckbox" class="custom-control custom-switch">
        <input type="checkbox"
          class="custom-control-input"
          [disabled]="readonly"
          [(ngModel)]="checkBoxValue"
          (ngModelChange)="chkChangeValue($event)"
          id="customSwitch{{code}}_{{label}}">
        <label class="custom-control-label" for="customSwitch{{code}}_{{label}}" [ngClass]="{'border-success': currentValue !== deviceValue}"></label>
      </div>
      <app-string-filled-editor #stringFilledEditor *ngIf="showInputStringFilled" [parameter]="parameter" [checkCurrentValue]="currentValue !== deviceValue"
        (valueChanged)="onStringFilledParamValueChanged($event)" [readonly]="readonly">
      </app-string-filled-editor>
      <app-readable-ascii #readableAsciiEditor *ngIf="showInputReadableAscii" [parameter]="parameter" [checkCurrentValue]="currentValue !== deviceValue"
        (valueChanged)="onStringFilledParamValueChanged($event)" [readonly]="readonly">
      </app-readable-ascii>
      <button
        type="button"
        class="btn btn-outline-warning btn-lg ml-2"
        [ngClass]="{'disabled': readonly}"
        *ngIf="hasSetValueButton()"
        (click)="setValueFromButton()">
        {{parameter.setValueButtonText}}
      </button>
      <em class="help-label" *ngIf="defaultValue && (showInputText || showInputStringFilled || showInputReadableAscii || showInputComboAsCheckbox)">{{helpLabelText}}</em>
    </ng-container>
  </div>

  <div *ngIf="!parameter.isPackParameter && customManualEnabled" class="col-4 col-sm-2 text-center">
    <a role="button"  class="btn btn-outline-warning btn-sm"
      [ngClass]="{'active': isInCustomManual}" (click)="sendEventForCustomManual()">
      <span *ngIf="isInCustomManual" data-toggle="tooltip" data-placement="right" [title]="'SHARED.ADDED-TOOLTIP' | translate">{{'SHARED.ADDED' | translate}}</span>
      <span *ngIf="!isInCustomManual" data-toggle="tooltip" data-placement="right" [title]="'SHARED.ADD-TOOLTIP' | translate">+{{'SHARED.ADD' | translate}}</span>
    </a>
  </div>
</div>
<app-param-editor #childParamEditor *ngFor="let item of parameter.childParameters"
  [parameter]="item" [customManualEnabled]="false"
  (toggleFromCustomManual)="onToggleFromCustomManual($event)"
  [productName]="productName"
  [readonly]="item.disabled" (valueChanged)="onChildParamValueChanged($event)"
  [value]="item.value" [interfaceValue]="interfaceValue"
  [parametersOrigin]="parametersOrigin"
  [interfaceCode]="interfaceCode"> 
</app-param-editor>
