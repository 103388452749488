import { SessionStorageService } from './session-storage.service';
import { Injectable } from '@angular/core';
import { TabItem } from '../classes/utility';
import { XmlService } from './xml.service';

@Injectable({
  providedIn: 'root'
})
export class TabNavigationService {

  public tabStack: TabItem[] = [];
  public currentTab:TabItem;
  public static openingTabs: TabItem[] = SessionStorageService.getItem('openingTabs');
  
  constructor(private xmlService: XmlService) { }

  addTab(tab: TabItem): void {
    SessionStorageService.setItem('openingTabs', TabNavigationService.openingTabs);
    if (tab) {
      const alreadyPresent = this.tabStack.find(it => it.url === tab.url);
      if (!alreadyPresent) {
        this.tabStack.push(tab);
        this.addTabOnSessionStorage(tab);
      }
      SessionStorageService.setItem('openingTabs', TabNavigationService.openingTabs);
    }
  }

  addTabOnSessionStorage(tab: any): void{
    if (tab) {
      const alreadyPresent = TabNavigationService.openingTabs.find(it => it.url === tab.url);
      if (!alreadyPresent) {
        TabNavigationService.openingTabs.push(tab);
      }
    }
  }

  setCurrentTab(tab: TabItem): void {
    if (tab) {
      this.currentTab = tab;
    }
  }

  clearTerminalContent(tab: TabItem): void {
    this.tabStack.find(it => it.url === tab.url).terminalContents = [];
  }

  checkOfflineTabOpened(tab: TabItem): boolean {
    let result: boolean = false;

    if (tab) {
      const alreadyPresent = this.tabStack.find(it => it.name === tab.name);
      if (!alreadyPresent) {
        result = false;
      } else {
        result = true;
      }
    }

    return  result;
  }

  closeTab(tab: TabItem): void {
    const foundIndex = this.tabStack.findIndex(it => it.url === tab.url);
    if (foundIndex >= 0) {
      this.tabStack.splice(foundIndex, 1);
      this.xmlService.deleteProductEditedData(tab.id); 
      TabNavigationService.openingTabs = this.tabStack;
      SessionStorageService.setItem('openingTabs', TabNavigationService.openingTabs);
    }
  }
}
