<button type="button" id="langSelectionButton" class="btn btn-primary" data-toggle="modal" data-target="#langSelectionModal"></button>

<div class="modal fade" id="langSelectionModal" tabindex="-1" role="dialog" aria-labelledby="langSelectionLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="langSelectionLabel">{{'LANGUAGE-SELECTION.TITLE' | translate}}</h5>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <div class="language" *ngFor="let lang of languages" [ngClass]="{active: currentLanguage === lang.iso3166_a2}"
          (click)="changeLanguage(lang)">
          <img [src]="'assets/images/flags/' + lang.iso3166_a2 + '.svg'" alt="">
          {{lang.name}}
        </div>
      </div>
    </div>
  </div>
</div>
