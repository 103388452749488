<button type="button" id="loadingWindowButton" class="btn btn-primary" data-toggle="modal"  data-backdrop="static" data-keyboard="false" data-target="#loadingWindow"></button>

<div class="modal fade" id="loadingWindow" tabindex="-1" role="dialog" aria-labelledby="loadingWindowLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{title.toUpperCase()}}...</h5>
                <button id="loadingWindowClose" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table>
                    <tr>
                        <td>
                            <p>Please wait for the {{title}} of data from the {{mode}}.</p>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <div class="progress">
                                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary white-text-footer" data-dismiss="modal">Stop</button>
            </div>
        </div>
    </div>
</div>
