import { element } from 'protractor';
import { Component, ElementRef, OnInit, ViewChild, Inject, AfterContentInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TabNavigationService } from 'src/app/services/tab-navigation.service';
import { Helper, TabItem } from 'src/app/classes/utility';
import { BehaviorSubject, Subject } from 'rxjs';
import { XmlService } from 'src/app/services/xml.service';
import { LanguageSelectionComponent } from '../language-selection/language-selection.component';
import { SendFeedbackComponent } from '../send-feedback/send-feedback.component';
import { LanguageService } from 'src/app/services/language.service';
import { RemoteService } from 'src/app/services/remote.service';
import { SettingsService } from 'src/app/services/settings.service';
import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from '../../auth-config';
import { filter, takeUntil } from 'rxjs/operators';
import { createClaimsTable } from '../../claim-utils';
import { HttpClient } from '@angular/common/http';
import { enableCloudService } from '../../auth-config';
import { NotificationComponent } from '../notification/notification.component';
import { NotificationType } from 'src/app/classes/enums/notification-type.enum';
import { TranslateService } from '@ngx-translate/core';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterContentInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('languageSelection') languageSelection: LanguageSelectionComponent;
  @ViewChild('sendFeedback') sendFeedback: SendFeedbackComponent;
  @ViewChild('headerNotification') notification: NotificationComponent;
  isInHome = true;
  tabs = this.tabNavigationService.tabStack;
  standaloneModeBehavior: BehaviorSubject<boolean>;
  standaloneMode: boolean;
  acceptedFiles = '.xml';
  helpLink = '';
  allowRemoteConfigButtonText: string;
  startRemoteConfigButtonText: string;
  remoteAppId: string;
  isRemoted = false;
  loginDisplayB2c = false;
  private readonly _destroying$ = new Subject<void>();
  dataSource: any = [];
  nameUserB2c: string = "Undefined Name";
  azureMode: boolean = false;
  enableCloudService: boolean = false;
  standaloneEnableCloud: boolean = false;

  constructor(private xmlService: XmlService, private tabNavigationService: TabNavigationService, private http: HttpClient, private translate: TranslateService, 
              private router: Router, private languageService: LanguageService, private remoteService: RemoteService, private settingsService: SettingsService,   
              @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration, private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // subscribing to NavigationEnd which is about to happen
        this.isInHome = this.router.url === '/configuration/home';
      }
    });

    this.enableCloudService = enableCloudService.toLowerCase() === "true";

    Helper.standaloneMode.subscribe(value => {
      this.standaloneMode = value;
    });

    //This function will enable cloud mode from standalone.
    //The cloud mode can be disable by authConfig.js
    Helper.standaloneEnableCloud.subscribe((value) => {
      this.standaloneEnableCloud = value;
      if (this.standaloneEnableCloud) {
        this.enableCloudService = true;
      } else {
        this.enableCloudService = enableCloudService.toLowerCase() === "true";
      }
    });

    this.languageService.currentHelpLink.subscribe((value) => {
      this.helpLink = value;
    });

    this.remoteService.allowRemoteConfigButtonText.subscribe((value) => {
      this.allowRemoteConfigButtonText = value;
    });
    
    this.remoteService.startRemoteConfigButtonText.subscribe((value) => {
      this.startRemoteConfigButtonText = value;
    });
    
    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
    });

    this.remoteAppId = this.remoteService.remoteAppId;

  }
  ngAfterContentInit(): void {
    $('[data-toggle="tooltip"]').tooltip()
  }

  ngOnInit(): void {   
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window

    /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
        )
        .subscribe((result: EventMessage) => {
            if (this.authService.instance.getAllAccounts().length === 0) {
                window.location.pathname = "/";
            } else {
                this.setLoginDisplay();
            }
        });

    this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this._destroying$)
        )
        .subscribe(() => {
            this.setLoginDisplay();
            this.checkAndSetActiveAccount();
            this.getClaims(this.authService.instance.getActiveAccount()?.idTokenClaims);
        })

    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                || msg.eventType === EventType.SSO_SILENT_SUCCESS),
            takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {

            let payload = result.payload as AuthenticationResult;
            let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

            if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                this.authService.instance.setActiveAccount(payload.account);
            }

            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
             * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
             * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

                // retrieve the account from initial sing-in to the app
                const originalSignInAccount = this.authService.instance.getAllAccounts()
                    .find((account: AccountInfo) =>
                        account.idTokenClaims?.oid === idtoken.oid
                        && account.idTokenClaims?.sub === idtoken.sub
                        && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                            || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                    );

                let signUpSignInFlowRequest: SsoSilentRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    account: originalSignInAccount
                };

                // silently login again with the signUpSignIn policy
                this.authService.ssoSilent(signUpSignInFlowRequest);
            }

            /**
             * Below we are checking if the user is returning from the reset password flow.
             * If so, we will ask the user to reauthenticate with their new password.
             * If you do not want this behavior and prefer your users to stay signed in instead,
             * you can replace the code below with the same pattern used for handling the return from
             * profile edit flow (see above ln. 74-92).
             */
            if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
                let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
                    scopes: []
                };

                this.loginB2c(signUpSignInFlowRequest);
            }

            return result;
        });

    this.msalBroadcastService.msalSubject$
        .pipe(
            filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
            takeUntil(this._destroying$)
        )
        .subscribe((result: EventMessage) => {
            // Checking for the forgot password error. Learn more about B2C error codes at
            // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
            if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                    authority: b2cPolicies.authorities.resetPassword.authority,
                    scopes: [],
                };

                this.loginB2c(resetPasswordFlowRequest);
            };
        });
        $('[data-toggle="tooltip"]').hover(function(){
          $('[data-toggle="tooltip"]').tooltip()
        });
  }

  closeTab(tab: TabItem) {
    if (tab.url === this.router.url) {
      if (this.tabNavigationService.tabStack.length > 1) {
        let i: number = 0;
        while (i < this.tabNavigationService.tabStack.length) {
          if (this.tabNavigationService.tabStack[i].url === tab.url) {
            break;
          }
          i++;
        }
        if (i === this.tabNavigationService.tabStack.length - 1) {
          this.router.navigateByUrl(this.tabNavigationService.tabStack[i - 1].url);
        } else {
          this.router.navigateByUrl(this.tabNavigationService.tabStack[i + 1].url);
        }
      } else {
        this.router.navigateByUrl('/');
      }
    }

    if(this.isRemoted) {
      let channelName = tab.url;
      if (channelName.startsWith("/configuration/product/")) {
        channelName = channelName.substring("/configuration/product/".length);
      }
      if (channelName.endsWith("/remote")) {
        channelName = channelName.substring(0,channelName.indexOf("/remote"));
      }
      if (this.remoteService.mapDataChannels.has(channelName)) {
        this.remoteService.mapDataChannels.delete(channelName);

        //close tab on remote side
        this.remoteService.closeTabListener(channelName);
      }
    }

    this.tabNavigationService.closeTab(tab);
  }
  
  selectTab(tab: TabItem) {
    if(this.isRemoted) {
      if (tab.mode === ' [REMOTE]') {
        let tabUrl = tab.url;
        if (tabUrl.endsWith('/remote')) {
          tabUrl = tabUrl.substring(0, tabUrl.length - '/remote'.length)
        }
        this.remoteService.selectTabListener(tabUrl);
      }
    }
  }

  selectHomeTab() {
    if(this.isRemoted) {
      this.remoteService.selectTabListener("#/configuration/home");
    }
  }

  loadFile(): void {
    this.fileInput.nativeElement.click();
  }

  loadedFile(fileInput: any): void {
    if (fileInput.target.files && fileInput.target.files[0]) {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        if (this.xmlService.isValid(e.target.result)) {
          const genericItemsOpened = this.tabNavigationService.tabStack.filter(it => it.url.indexOf('-') > 0).length;
          this.xmlService.setXmlConfig(Helper.genericDeviceFolder + '_-' + (genericItemsOpened + 1), e.target.result)
          .subscribe(() => this.router.navigateByUrl('/configuration/product/' + '-' + (genericItemsOpened + 1)));
        } else {
          this.notification.showAlert(NotificationType.Error, this.translate.instant('NOTIFICATION.THE-PROVIDED-XML-IS-NOT-VALID'));
          
        }
      };

      reader.readAsText(fileInput.target.files[0]);
    }
  }

  openLanguageSelection(): void {
    this.languageSelection.show();
  }

  openSendFeedback(): void {
    this.sendFeedback.show();
  }

  btnAllowRemoteConfigurationListener() {
    try {
      this.remoteService.btnAllowRemoteConfigurationListener();
    } catch(err) {
      this.notification.showAlert(NotificationType.Error, err.message);
    }
  }

  btnCopyRemoteIdListener() {
    var copyText: string = document.getElementById("spanRemoteAppId").innerText;
    // Copy the text inside the text field
    navigator.clipboard.writeText(copyText)
    .then(() => {
      alert("successfully copied");
    })
    .catch((err) => {
      //alert(err);
      if (this.standaloneMode) {
        window.copyToClipboardByJava(copyText);
      }
    });
  }

  btnStartRemoteConfigurationListener(value: string) {
    try {
      this.remoteService.btnStartRemoteConfigurationListener(value);
    } catch(err) {
      this.notification.showAlert(NotificationType.Error, err.message);
    }
  }

  getShowParameterCode() {
    return this.settingsService.getShowParameterCode();
  }

  toggleShowParameterCode() {
    this.settingsService.setShowParameterCode(!this.settingsService.getShowParameterCode());
  }

  loginB2c(userFlowRequest?: RedirectRequest | PopupRequest) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          } else {
              this.authService.loginPopup(userFlowRequest)
                  .subscribe((response: AuthenticationResult) => {
                      this.authService.instance.setActiveAccount(response.account);
                  });
          }
      } else {
          if (this.msalGuardConfig.authRequest) {
              this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
          } else {
              this.authService.loginRedirect(userFlowRequest);
          }
      }
  }

  logoutB2c() {
      const activeAccount =
        this.authService.instance.getActiveAccount() ||
        this.authService.instance.getAllAccounts()[0];

      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.authService.logoutPopup({
          account: activeAccount,
        });
      } else {
        this.authService.logoutRedirect({
          account: activeAccount,
        });
      }
  }

  editProfileB2c() {
      let editProfileFlowRequest: RedirectRequest | PopupRequest = {
          authority: b2cPolicies.authorities.editProfile.authority,
          scopes: [],
      };

      this.loginB2c(editProfileFlowRequest);
  }

  setLoginDisplay() {
      this.loginDisplayB2c = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
      /**
       * If no active account set but there are accounts signed in, sets first account to active account
       * To use active account set here, subscribe to inProgress$ first in your component
       * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
       */
      let activeAccount = this.authService.instance.getActiveAccount();

      if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
          let accounts = this.authService.instance.getAllAccounts();
          // add your code for handling multiple accounts here
          this.authService.instance.setActiveAccount(accounts[0]);
      }
  }

  getClaims(claims: any) {
      if (claims) {
          const claimsTable = createClaimsTable(claims);
          this.dataSource = [...claimsTable];

          this.nameUserB2c = "Undefined Name";

          for (let i=0; i<this.dataSource.length; i++) {
            let tempList = this.dataSource[i];
            if (tempList.claim === "name" && tempList.value !== "" && tempList.value !== "unknown") {
              this.nameUserB2c = tempList.value;
              break;
            }
          }
      }
  }

  openCloseCloudMode() {
      Helper.changeStatusAzureMode(!this.azureMode);
      this.azureMode = !this.azureMode;
  }

  // unsubscribe to events when component is destroyed
  ngOnDestroy(): void {
      this._destroying$.next(undefined);
      this._destroying$.complete();
  }

  getAvatarLink(): string {
    if (this.nameUserB2c) return `https://ui-avatars.com/api/?name=${this.nameUserB2c.replace(" RD", "").trim().split(" ")
      .filter((it, index) => index < 2).join("+")}&size=28&rounded=true&background=1e90ff&color=fff`;
    return ""
  }
}
