import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jingle',
  templateUrl: './jingle.component.html',
  styleUrls: ['./jingle.component.css']
})
export class JingleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  show() {
    const element: HTMLElement = document.getElementById('jingleButton');
    element.click();
  }
}
