import { ConfigurationElementType } from './enums/configuration-element-type';
import { Parameter } from './parameters';

export class ConfigurationElement {
  public name: string;
  public text: string;
  public guiType: string;
  public order: number;
  public type: ConfigurationElementType;
  public parameter: Parameter;
  public code: string;
  public maxSize: string = "";
  public packageSize: string = "";
  public hideValue: boolean;


  constructor(name: string, text: string, guiType: string, order: number, code: string,
              type: ConfigurationElementType, parameter: Parameter, maxSize: string = "", packageSize: string = "", hideValue: boolean) {
    this.name = name;
    this.text = text  ;
    this.guiType = guiType;
    this.order = order;
    this.code = code;
    this.type = type;
    this.parameter = parameter;
    this.maxSize = maxSize;
    this.packageSize = packageSize;
    this.hideValue = hideValue;
  }
}
