import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart  } from '@angular/router';
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  newsCondition = false;

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      if(val instanceof NavigationStart) {
        if (val.url.includes("/configuration/category") || val.url.includes("/configuration/product")) {
          this.newsCondition = true;
        } else {
          this.newsCondition = false;
        }
      }
  });
  }

  ngOnInit(): void {
  }

}
