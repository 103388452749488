import { TerminalCustomButton } from './../../classes/utility/terminal-custom-btn';
import { Component, Input, Output, OnInit, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Helper } from 'src/app/classes/utility';
import { RemoteService } from 'src/app/services/remote.service';
import { TabNavigationService } from 'src/app/services/tab-navigation.service';

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css']
})
export class TerminalComponent implements OnInit {
  @Input() contents: string[] = [];
  @Input() isOnline: boolean = false;
  @Input() isRemoteTab: boolean = false;
  @Input() customButtons: TerminalCustomButton[] = [];
  @Input() family: string = "";
  @ViewChild('text') text: ElementRef;
  @Output() clickOnSendTerminalButton: EventEmitter<string> = new EventEmitter<string>();
  standaloneMode: boolean = false;
  isRemoted: boolean = false;
  showMore = "btn-default";

  constructor(private remoteService: RemoteService, private tabNavigationService: TabNavigationService) { 
    Helper.standaloneMode
    .subscribe(value => {
      this.standaloneMode = value;
    });
    
    this.remoteService.isRemoted.subscribe((value) => {
      this.isRemoted = value;
    });
  }

  ngOnInit(): void {
  }

  show() {
    const element: HTMLElement = document.getElementById('TerminalButton');
    element.click();
  }

  clickOnSendTerminalButtonEvent(): void {
    if (!this.standaloneMode && this.isRemoted && this.isOnline) {
      let sendCommand: string = (<HTMLInputElement>document.getElementById("terminalCommand")).value;
      this.clickOnSendTerminalButton.emit(sendCommand);
    }
  }

  clickOnClearTextButtonEvent(): void {
    if (!this.standaloneMode && this.isRemoted && this.isOnline) {
      this.tabNavigationService.clearTerminalContent(this.tabNavigationService.currentTab);
      this.contents = [];
      this.show();
    }
  }

  clickOnSaveTextButtonEvent(): void {
    if (!this.standaloneMode && this.isRemoted && this.isOnline) {
      let txtData = this.contents.join("\r\n");
      this.saveAsTextFile(txtData);
    }
  }

  saveAsTextFile(txtData): void { 
    var data = new Blob([txtData], {type: 'text/plain'});

    let url = window.URL.createObjectURL(data);

    let a = document.createElement('a');
    document.body.appendChild(a);

    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = 'terminalLog.txt';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  rotateArrow() {
    if(this.showMore == 'btn-change') {
      this.showMore = 'btn-default';
      
    } else {
      this.showMore = 'btn-change';
    }
  }
}
