import { Parameter } from '../../classes/parameters';
import { ProductComponent } from './product.component'; 

export class OfflineCCFImport {
    CI_INTERFACE_TYPE: string = "0001";

    constructor(private productComponent: ProductComponent){
    }

    setAllProductParameterToMasterDefaultValue(resetCentralized: boolean): void {
        var parameterEditors : Parameter[] = this.productComponent.data.parameters;
        this.setProductParameterToMasterDefaultValue(parameterEditors, resetCentralized);
    }
    
    setProductParameterToMasterDefaultValue(pEditors: Parameter[], resetCentralized: boolean): void {
        for (var pEditor of pEditors) {
          // Ignore interface parameter. Otherwise, this function always set the interface to the master default value
          // Also ignore child parameters. Their parents are already being set.
          // Also ignore centralized tags
          if (!this.productComponent.isInterfaceChangeParameter(pEditor.code) && pEditor.parent === undefined) {
            if (resetCentralized || !pEditor.isCentralized) {
              var value = pEditor.masterDefaultValue;
              this.productComponent.setCurrentProductParameter(pEditor.code,value,true,true)
            }
          }
        }
    }

    stripCommentForImport(string: string, comment: string): string {
        let ret = "";
        if (string === null) 
        {
            return ret;
        }
        let index: number = string.indexOf(comment);
        if (index === -1) 
        {
            ret = string.trim();
        } 
        else if (index > 0) 
        {
            ret = string.substring(0, index).trim();
        }
        return ret;
    }
    
    getCmdList(tex: string): string[] {
        let cmdList: string[] = [];
        if (tex !== undefined && tex !== null && tex !== '') {
          tex = tex.trim();
          //Split string with CR or LF or CRLF
          let linee: string[] = tex.split(/\r?\n/);
          for (let i = 0; i < linee.length; i++) {
              let string: string = linee[i];
              let cmd: string = this.stripCommentForImport(string, ";");
              cmd = this.stripCommentForImport(cmd, "#");
              if (cmd !== null && cmd !== "") 
              {
                  cmdList.push(cmd);
              }
          }
        }
        return cmdList;
    }
    
    hex2ascii(hexString: string) {
        let str: string = '';
        for (var i = 0; i < hexString.length; i += 2)
          str += String.fromCharCode(parseInt(hexString.substring(i, i + 2), 16));
        return str;
    }
    
    makeAFFromULE(ule: string): string[] {
        let parameterPrefix: string = "$C";
        let af: string[] = [];
        let lines: string[] = ule.split(/\r?\n/);
        for (let line of lines) {
          if (line.startsWith(parameterPrefix)) {
            af.push(line);
          }
        }                
        return af;
    }

    importCCF(): void {
        // Get Set of interface values (so we can later skip those that are not included)
        let interfaceOptions = this.productComponent.data.parameters.find(it => it.code === this.CI_INTERFACE_TYPE).options;
        let interfaceValues = new Set(interfaceOptions.map(op => op.value));
  
        let ccfString: string = this.productComponent.data.currentConfigFile;
        let cmdList: string[] = [];
        if (ccfString !== undefined && ccfString !== null && ccfString !== "") {
          cmdList = this.getCmdList(ccfString);
        }
  
        let ule: string = "";
        let enableModification: boolean = false;
        for (let cmdLine of cmdList) {
          if (enableModification) {
            if (cmdLine.startsWith("7FFD01") || cmdLine.startsWith("7FFE01") || cmdLine.startsWith("7FFF01")) {
              enableModification = false;
            } else {
              let tagPair: string = cmdLine.substring(0, cmdLine.length - 2);
              let tagCode: string = tagPair.substring(0, 4);
              let tagValue: string = tagPair.substring(4, tagPair.length);
              this.productComponent.setCurrentProductParameter(tagCode, tagValue, false, true);
            }
          } else {
            if (cmdLine !== undefined && cmdLine !== null && cmdLine !== "") {
              if (cmdLine.startsWith("7FFB") || cmdLine.startsWith("7FFC") || cmdLine.startsWith("0001")) {
                let interfaceValue = cmdLine.substring(4, 8);
                if (cmdLine.length == 8) { // Scanalyzer style line
                  interfaceValue = "00" + cmdLine.substring(4, 6);
                }
                if (interfaceValues.has(interfaceValue)) { // need to skip interfaces that are not available (probably developer or secure interfaces)
                  console.log("Switching to interface: " + interfaceValue);
                  enableModification = true;
                  this.productComponent.setCurrentProductParameter("0001", interfaceValue, false, true);
                  if (cmdLine.startsWith("7FFB") || cmdLine.startsWith("7FFC")) {
                    console.log("Setting interface to master defaults. Interface: " + interfaceValue);
                    // Reset current value to master default value
                    this.setAllProductParameterToMasterDefaultValue(interfaceValue == "0046");
                  }
                } else {
                  enableModification = false;
                  console.log("Skipping interface: " + interfaceValue);
                }
              } else if (cmdLine.startsWith("7FF8")) {
                if (!cmdLine.startsWith("7FF800000008554C4532")) {
                  let uleLine: string = cmdLine.substring(12, cmdLine.length - 2)
                  let uleCmdString: string = this.hex2ascii(uleLine);
                  ule = ule + uleCmdString;
                }
              }
            }
          }
        }
        this.productComponent.setUleScript(ule);
        let arrAFCmds: string[] = this.makeAFFromULE(ule);
        if (arrAFCmds.length > 0) {
          for(let cmd of arrAFCmds ) {
            cmd = cmd.replace("$C", "");
            // update the GUI by setCurrentProductParameter function
            let tagName: string = cmd.substring(0, 4);
            let value:string = cmd.substring(4);    
            //monitor.setCurrent(tagName + " are being read ...");
            this.productComponent.setCurrentProductParameter(tagName, value, false, true);
          }  
        }
    }
}