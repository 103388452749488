import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statistic-page',
  templateUrl: './statistic-page.component.html',
  styleUrls: ['./statistic-page.component.css']
})
export class StatisticPageComponent implements OnInit {
  @Input() title: any[] = [];
  @Input() values: any[] = [];
  @Input() code: any[] = [];

  constructor() { }

  ngOnInit(): void {
  }

}
