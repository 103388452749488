import { Parameter } from '../../classes/parameters';

export class CompareConfigurationImport {
    CI_INTERFACE_TYPE: string = "0001";
    currentInterface: string = "";

    constructor(private parameterData: Parameter[], private family: string){
    }

    setAllProductParameterToMasterDefaultValue(resetCentralized: boolean): void {
        this.setProductParameterToMasterDefaultValue(this.parameterData, resetCentralized);
    }

    interfaceChangeParameter() {
      if (this.family === 'FRS') {
        return '0001';
      } else {
        return '$hA';
      }
    }

    isInterfaceChangeParameter(code: string) {
        return code === this.interfaceChangeParameter();
    }

    setCurrentProductParameter(code: string, value: string, setDeviceValue: boolean = true, resetULEFollowingAF: boolean = true): void {
      // The parameter this.lastInterface should not be changed here.
      // The parameter this.lastInterface will be changed when the function onParamValueChanged() is executed
      //if (this.isInterfaceChangeParameter(code) && value != this.lastInterface) {
      //  this.lastInterface = value;
      //}
      var parameter : Parameter | undefined = this.parameterData.find(it => it.code === code);
      if (parameter && parameter.childParameters.length > 0 && value) 
      {
        let valueStartingIndex:number = 0;
        let valueEndIndex:number = 0;
        
        for (let index = 0; index < parameter.childParameters.length; index++) 
        {
          valueEndIndex = Number(valueStartingIndex) + Number(parameter.childParameters[index].size);
          const subvalue = value.substring(valueStartingIndex,  valueEndIndex);
          if (subvalue && parameter.childParameters[index])
          {
            parameter.childParameters[index].value = subvalue;
  
            if (setDeviceValue) {
              parameter.childParameters[index].deviceValue = subvalue;
            }
            this.onParamValueChanged(parameter);
          } 
          
          valueStartingIndex = Number(valueStartingIndex) + Number(parameter.childParameters[index].size);
        }
      }
      else if (parameter)
      {
        parameter.value = value;
        if (setDeviceValue) 
        {
          parameter.deviceValue = value;
        }
        this.onParamValueChanged(parameter);
      }
    }

    onParamValueChanged(parameter: Parameter) {
      if (this.isInterfaceChangeParameter(parameter.code) && (parameter.value !== this.currentInterface)) {
        this.currentInterface = parameter.value;
        // Update default value following the current interface
        if (parameter.options !== undefined && parameter.options.length > 0) {
          if (parameter.options.find(it => it.value === this.currentInterface)) {
            let currentInterfaceName = parameter.options.find(it => it.value === this.currentInterface).value;
            this.parameterData.forEach(param => param.setValueFollowingInterface(currentInterfaceName, false, this.isInterfaceChangeParameter(param.code)));
          }
        }
      }
    }
    
    setProductParameterToMasterDefaultValue(pEditors: Parameter[], resetCentralized: boolean): void {
        for (var pEditor of pEditors) {
          // Ignore interface parameter. Otherwise, this function always set the interface to the master default value
          // Also ignore child parameters. Their parents are already being set.
          // Also ignore centralized tags
          if (!this.isInterfaceChangeParameter(pEditor.code) && pEditor.parent === undefined) {
            if (resetCentralized || !pEditor.isCentralized) {
              var value = pEditor.masterDefaultValue;
              this.setCurrentProductParameter(pEditor.code,value,true,true)
            }
          }
        }
    }

    stripCommentForImport(string: string, comment: string): string {
        let ret = "";
        if (string === null) 
        {
            return ret;
        }
        let index: number = string.indexOf(comment);
        if (index === -1) 
        {
            ret = string.trim();
        } 
        else if (index > 0) 
        {
            ret = string.substring(0, index).trim();
        }
        return ret;
    }
    
    getCmdList(tex: string): string[] {
        let cmdList: string[] = [];
        if (tex !== undefined && tex !== null && tex !== '') {
          tex = tex.trim();
          //Split string with CR or LF or CRLF
          let linee: string[] = tex.split(/\r?\n/);
          for (let i = 0; i < linee.length; i++) {
              let string: string = linee[i];
              let cmd: string = this.stripCommentForImport(string, ";");
              cmd = this.stripCommentForImport(cmd, "#");
              if (cmd !== null && cmd !== "") 
              {
                  cmdList.push(cmd);
              }
          }
        }
        return cmdList;
    }
    
    hex2ascii(hexString: string) {
        let str: string = '';
        for (var i = 0; i < hexString.length; i += 2)
          str += String.fromCharCode(parseInt(hexString.substring(i, i + 2), 16));
        return str;
    }
    
    makeAFFromULE(ule: string): string[] {
        let parameterPrefix: string = "$C";
        let af: string[] = [];
        let lines: string[] = ule.split(/\r?\n/);
        for (let line of lines) {
          if (line.startsWith(parameterPrefix)) {
            af.push(line);
          }
        }                
        return af;
    }

    importCCF(ccfString: string): void {
        // Get Set of interface values (so we can later skip those that are not included)
        let interfaceParameter = this.parameterData.find(it => it.code === this.CI_INTERFACE_TYPE)
        let interfaceValues: Set<String> = new Set();
        if (interfaceParameter?.options) {
            let interfaceOptions = interfaceParameter.options;
            interfaceValues = new Set(interfaceOptions.map(op => op.value));
        }
  
        let cmdList: string[] = [];
        if (ccfString !== undefined && ccfString !== null && ccfString !== "") {
          cmdList = this.getCmdList(ccfString);
        }
  
        let ule: string = "";
        let enableModification: boolean = false;
        for (let cmdLine of cmdList) {
          if (enableModification) {
            if (cmdLine.startsWith("7FFD01") || cmdLine.startsWith("7FFE01") || cmdLine.startsWith("7FFF01")) {
              enableModification = false;
            } else {
              let tagPair: string = cmdLine.substring(0, cmdLine.length - 2);
              let tagCode: string = tagPair.substring(0, 4);
              let tagValue: string = tagPair.substring(4, tagPair.length);
              this.setCurrentProductParameter(tagCode, tagValue, false, true);
            }
          } else {
            if (cmdLine !== undefined && cmdLine !== null && cmdLine !== "") {
              if (cmdLine.startsWith("7FFB") || cmdLine.startsWith("7FFC") || cmdLine.startsWith("0001")) {
                let interfaceValue = cmdLine.substring(4, 8);
                if (cmdLine.length == 8) { // Scanalyzer style line
                  interfaceValue = "00" + cmdLine.substring(4, 6);
                }
                if (interfaceValues.has(interfaceValue)) { // need to skip interfaces that are not available (probably developer or secure interfaces)
                  console.log("Switching to interface: " + interfaceValue);
                  enableModification = true;
                  this.setCurrentProductParameter("0001", interfaceValue, false, true);
                  if (cmdLine.startsWith("7FFB") || cmdLine.startsWith("7FFC")) {
                    console.log("Setting interface to master defaults. Interface: " + interfaceValue);
                    // Reset current value to master default value
                    this.setAllProductParameterToMasterDefaultValue(interfaceValue == "0046");
                  }
                } else {
                  enableModification = false;
                  console.log("Skipping interface: " + interfaceValue);
                }
              } else if (cmdLine.startsWith("7FF8")) {
                if (!cmdLine.startsWith("7FF800000008554C4532")) {
                  let uleLine: string = cmdLine.substring(12, cmdLine.length - 2)
                  let uleCmdString: string = this.hex2ascii(uleLine);
                  ule = ule + uleCmdString;
                }
              }
            }
          }
        }
    }
}
