export class HelpItem {
  constructor(id: string, basePath: string, url: string, content: string = '') {
    this.id = id;
    this.url = url;
    this.basePath = basePath;
    this.content = content;
  }
  public id: string;
  public url: string;
  public basePath: string;
  public content: string;

  static fromXml(node, urlBasePath): HelpItem[] {
    const result = [];

    for (const map of node.mapID) {
      const obj = new this(
        map['@target'],
        urlBasePath,
        urlBasePath + map['@url']
      );
      result.push(obj);
    }

    return result;
  }
}
