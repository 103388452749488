<button type="button" class="btn btn-primary no-uppercase button-min-height-width" data-toggle="modal"
  [attr.data-target]="'#modalStringFilled-' + parameter.code"
  [ngClass]="{'border-success': checkCurrentValue}"
  [disabled]="readonly"
  (click)="onClick()" data-backdrop="static" data-keyboard="false">
  {{currentValueText}}
</button>
<!-- Modal -->
<div class="modal fade" [id]="'modalStringFilled-' + parameter.code" tabindex="-1" role="dialog">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLongTitle">{{parameter.label}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body clearfix">
        <span class="title">{{'STRING-FILLED.ELEMENTS' | translate}}</span>
        <span class="range">
          [{{'SHARED.MIN' | translate}} {{parameter.minLen}} - {{'SHARED.MAX' | translate}} {{parameter.maxLen}}]
        </span>
        <div class="possible-values-container">
          <div class="search">
            <input type="text" name="search" class="searchInput" [placeholder]="'HOME.SEARCH' | translate"
              [(ngModel)]="searchText" (ngModelChange)="refreshOptions()" />
          </div>
          <div class="possible-values">
            <div *ngFor="let option of options" (click)="selectOption(option)">
              {{option.name}}
            </div>
          </div>
        </div>
        <div class="selected-values-container">
          <div class="buttons clearfix">
            <div class="button" (click)="moveUp()" [ngClass]="{disabled: activeOptions.indexOf(0) >= 0 || activeOptions.length === 0}">
              <img src="assets/images/move-up.svg" />
            </div>
            <div class="button" (click)="moveDown()" [ngClass]="{disabled: activeOptions.indexOf(selectedOptions.length - 1) >= 0 || activeOptions.length === 0}">
              <img src="assets/images/move-down.svg" />
            </div>
            <div class="button delete" (click)="deleteActiveOptions()" [ngClass]="{disabled: activeOptions.length === 0}">
              <img src="assets/images/delete.svg" />
            </div>
          </div>
          <div class="selected-values">
            <div *ngFor="let option of selectedOptions; let i = index" [ngClass]="{active: activeOptions.indexOf(i) >= 0}"
              (click)="toggleActiveOption(i)">
              {{option.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-outline-warning" data-dismiss="modal" (click)="close()">{{'SHARED.CLOSE' | translate}}</button>
        <button type="button" class="btn btn-warning" data-dismiss="modal" (click)="saveChanges()">{{'SHARED.SAVE-CHANGES' | translate}}</button>
      </div>
    </div>
  </div>
</div>
