import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CategoryComponent } from './pages/category/category.component';
import { ProductComponent } from './pages/product/product.component';
import { MsalRedirectComponent } from '@azure/msal-angular';

const routes: Routes = [
  { path: '', redirectTo: '/configuration/home', pathMatch: 'full' },
  { path: 'configuration/home', component: HomeComponent },
  { path: 'configuration/category/:id', component: CategoryComponent },
  { path: 'configuration/product/:index', component: ProductComponent },
  { path: 'configuration/product/:index/:usbcom', component: ProductComponent },
  { path: 'configuration/product/:index/:usbcom/:mode', component: ProductComponent },
  { path: '**', redirectTo: '/configuration/home', pathMatch: 'full' }
];

let useHash = false;
if (window.useHash) {
  useHash = window.useHash;
}

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
