<button type="button" id="alternativeProductButton" class="btn btn-primary d-none" data-toggle="modal" data-target="#alternativeProductModal"></button>

<div class="modal fade" id="alternativeProductModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">{{ 'SHARED.SELECT-DEVICE-AND-RELEASE' | translate }}</h4>
            <button type="button" id="closeProductModal" class="close" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body">
            <div class="row d-flex align-items-center justify-content-sm-start justify-content-center">
                <div class="col-12 col-sm-5 col-form-label text-sm-left">
                  {{ 'SHARED.SELECT-ALTERNATIVE-DEVICE' | translate }}
                </div>
                <div class="col-8 col-sm-7 blockSelection">
                    <div class="dropdown show">
                      <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button">
                          <input type="text" placeholder="Choose one..." [formControl]="inputSearch" (focus)="this.clickOrEnterValue=true" on-focusout="focusOut()">
                      </a>
                      <div class="dropdown-menu show" *ngIf="clickOrEnterValue && deviceList.length > 0">
                        <a class="dropdown-item"
                          [ngClass]="{'active': device === currentDeviceValue}"
                          (click)="selectOptionValue(device)"
                          *ngFor="let device of deviceList">
                          {{device}}
                        </a>
                      </div>
                    </div>
                </div>

                <div class="col-12 col-sm-5 col-form-label text-sm-left">
                  {{ 'SHARED.SOFTWARE-RELEASE' | translate }}
                </div>
                <div class="col-8 col-sm-7 blockSelection">
                    <div class="dropdown show" [ngClass]="{'disabled': checkDeviceValue() }">
                        <a class="btn btn-secondary dropdown-toggle dropdownMenuLink" href="#" role="button" id="dropdownMenuLink2" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                           <span>{{this.currentReleaseSWValue}}</span>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <a class="dropdown-item"
                            [ngClass]="{'active': option === currentReleaseSWValue}"
                            (click)="selectOptionValue(option, true)"
                            *ngFor="let option of this.swRelease">
                                {{option}}
                          </a>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
                <div class="col-6 text-right">
                    <a role="button" class="btn btn-outline-warning" [ngClass]="{'close disabled': checkDeviceValue() || this.currentReleaseSWValue == this.defaultReleaseSWValue}"
                      (click)="gotoProductPage()">
                      {{'SHARED.OK' | translate}}
                    </a>
                </div>
                <div class="col-6 text-right">
                    <a role="button" class="btn btn-outline-warning" 
                    data-dismiss="modal">
                      {{'SHARED.CANCEL' | translate}}
                    </a>
                </div>
            </div>
          </div>
        </div>
        
      </div>
</div>
<app-notification #alternativeNotification [identifier]="'alternativeNotification'"></app-notification>